import React from 'react';

type ChevronDownSvgSvgProps = {
  className?: string;
  svgWidth?: number;
  svgHeight?: number;
  fillColor?: string;
};

const ChevronDownSvg = ({ className, svgWidth = 24, svgHeight = 16, fillColor = '#FEFEFE' }: ChevronDownSvgSvgProps) => (
  <svg
    width={svgWidth + 'px'}
    height={svgHeight + 'px'}
    viewBox={`0 0 ${svgWidth} ${svgHeight}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <path
      d="M5.7125,7.2925 L9.5925,11.1725 L13.4725,7.2925 C13.8625,6.9025 14.4925,6.9025 14.8825,7.2925 C15.2725,7.6825 15.2725,8.3125 14.8825,8.7025 L10.2925,13.2925 C9.9025,13.6825 9.2725,13.6825 8.8825,13.2925 L4.2925,8.7025 C3.9025,8.3125 3.9025,7.6825 4.2925,7.2925 C4.6825,6.9125 5.3225,6.9025 5.7125,7.2925 Z"
      fill={fillColor}
    />
  </svg>
);

export default ChevronDownSvg;
