import SearchCombobox, { SearchItem } from 'components/forms/SearchCombobox';
import { BearingDimensions } from 'domain/BearingDimensions';
import { BearingType, BearingTypeCollection } from 'domain/BearingType';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch } from 'react-redux';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { Bearing, bearingTypeExist, compareBearingTypes, getBearings } from 'services/Bearings/BearingService';
import { getDefaultBearingType, isBearingCollection } from 'services/LocalData/BearingTypeLoader';
import { useAppSelector } from 'store/hooks';
import { setBearingDesignation, setBearingTypeAndDimensions, setReplenishment } from 'store/InputSlice';
import useDebounce from 'utils/hooks/useDebounce';
import useLocalStorage from 'utils/hooks/useLocalStorage';

const historyLength = 5;
const resultLength = 10;

const BearingSearch = () => {
  const intl = useIntl();

  const [searchText, setSearchText] = useState<string | undefined>('');
  const [items, setItems] = useState<SearchItem<Bearing>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const debouncedSearchText = useDebounce(searchText, 300);

  const dispatch = useDispatch();
  const bearingTypes = useAppSelector((state) => state.data.bearingTypes);
  const supportedBearingTypes = useAppSelector((state) => state.data.bearingTypes.map((bt) => bt.id.toLocaleUpperCase()), shallowEqual);
  const manualBearingSelection = useAppSelector((state) => state.input.manualBearingSelection);
  const connected = useAppSelector((state) => state.setting.connected, shallowEqual);
  const currentBearingDesignation = useAppSelector((state) => state.input.bearingDesignation);
  const bearingType = useAppSelector((state) => state.input.bearingType);
  const currentDimensions = useAppSelector((state) => state.input.dimensions);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);
  const { logSearchHistorySelect, logSearchQuery, logSearchSelected } = useAnalyticsLog();

  const defaultReplenishmentValue = {
    value: 0.005,
    label: 'conditions.replenishment.side.label',
    description: 'conditions.replenishment.side.description'
  };

  useEffect(() => {
    if (currentBearingDesignation) {
      const currentItem = {
        item: {
          designation: currentBearingDesignation,
          innerDiameter: currentDimensions.innerDiameter,
          outerDiameter: currentDimensions.outerDiameter,
          width: currentDimensions.width,
          type: bearingType.id
        },
        label: currentBearingDesignation,
        value: currentBearingDesignation
      } as SearchItem<Bearing>;
      setSelectedItem(currentItem);
      dispatch(setReplenishment(defaultReplenishmentValue));
    } else {
      setSelectedItem(null);
    }
    // eslint-disable-next-line
  }, [currentBearingDesignation, bearingType.id, currentDimensions.innerDiameter, currentDimensions.outerDiameter, currentDimensions.width]);

  const [selectedItem, setSelectedItem] = useState<SearchItem<Bearing> | null>(null);

  const [history, setHistory] = useLocalStorage<Bearing[]>('bearingSearchHistory', []);

  const createSearchItem = useCallback(
    (bearing: Bearing) =>
      ({
        item: bearing,
        disabled: !bearingTypeExist(supportedBearingTypes, bearing.type),
        label: !bearingTypeExist(supportedBearingTypes, bearing.type)
          ? intl.formatMessage({ id: 'bearingSelection.search.notsupported' }, { designation: bearing.designation, type: bearing.type })
          : bearing.designation,
        value: bearing.designation
      } as SearchItem<Bearing>),
    [supportedBearingTypes, intl]
  );

  useEffect(() => {
    if (debouncedSearchText) {
      setIsLoading(true);

      const searchBearings = async (searchTerm: string) => {
        const result = await getBearings(searchTerm);
        setItems(result.bearings.slice(0, resultLength).map(createSearchItem));
        setIsLoading(false);
        setIsError(false);
        logSearchQuery(searchTerm);
      };

      searchBearings(debouncedSearchText).catch((error) => {
        console.log(error);
        setIsError(true);
        setIsLoading(false);
      });
    } else if (debouncedSearchText?.length === 0) {
      setItems(history.map(createSearchItem));
    }
  }, [debouncedSearchText, createSearchItem, supportedBearingTypes, history, logSearchQuery]);

  useEffect(() => {
    if (manualBearingSelection) {
      setSelectedItem(null);
      setItems(history.map(createSearchItem));
    }
  }, [manualBearingSelection, createSearchItem, history]);

  const search = (value?: string) => {
    setSearchText(value);
  };

  const reset = () => {
    setItems(history.map(createSearchItem));
    setSelectedItem(null);
  };

  const addToHistory = (item: Bearing) => {
    if (!history.some((b) => b.designation === item.designation)) {
      setHistory([item, ...history.slice(0, historyLength - 1)]);
    } else {
      logSearchHistorySelect(item.designation);
    }
  };

  const bearingSelected = (item: SearchItem<Bearing> | null | undefined) => {
    if (item) {
      const bearing = item.item;
      let bearingType = bearingTypes.find((bt) => compareBearingTypes(bt.id, bearing.type));
      if (bearingType) {
        if (isBearingCollection(bearingType)) {
          bearingType = getDefaultBearingType(bearingType as BearingTypeCollection) as BearingType;
        }
        dispatch(
          setBearingTypeAndDimensions({
            bearingType: bearingType as BearingType,
            dimensions: { innerDiameter: bearing.innerDiameter, outerDiameter: bearing.outerDiameter, width: bearing.width } as BearingDimensions
          })
        );
        dispatch(setBearingDesignation(item.item.designation));
        addToHistory(bearing);
        logSearchSelected(bearing.designation);
      }
      setSelectedItem(item);
    }
  };

  return (
    <>
      <SearchCombobox<Bearing>
        label={intl.formatMessage({ id: 'bearingSelection.search.label' })}
        isLoading={isLoading}
        onSearch={search}
        onReset={reset}
        disabled={(connected === false && connected !== undefined) || isDisabled}
        items={items}
        isError={isError}
        errorMessage={intl.formatMessage({ id: 'bearingSelection.search.errorMessage' })}
        hint={connected === false && connected !== undefined ? intl.formatMessage({ id: 'bearingSelection.search.offlineMessage' }) : undefined}
        onItemSelected={bearingSelected}
        noResultMessage={intl.formatMessage({ id: 'bearingSelection.search.noResults' })}
        currentItem={selectedItem}
        otherInputValue={selectedItem?.label}
      />
    </>
  );
};

export default BearingSearch;
