import { useEffect } from 'react';
import CookieService from 'services/Cookies/CookieSettingsService';
import { login } from 'store/AccountSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const useIsOfflineAuthenticated = (): { isOfflineAuthenticated?: boolean; isAuthenticated: boolean } => {
  const connected = useAppSelector((state) => state.setting.connected);
  const isAuthenticated = useAppSelector((state) => state.account.isAuthenticated);
  const dispatch = useAppDispatch();

  const isOfflineAuthenticated = (): boolean => {
    if (connected === false && isAuthenticated === true) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (connected !== undefined && isAuthenticated !== undefined) {
      if (connected === false && isAuthenticated === false) {
        const haveBeenAuthenticated = new CookieService().getHaveBeenAuthenticatedCookie();
        if (haveBeenAuthenticated) {
          dispatch(
            login({
              homeAccountId: 'tmp',
              environment: 'tmp',
              tenantId: 'tmp',
              username: 'tmp',
              localAccountId: 'tmp',
              idTokenClaims: { isFake: true }
            })
          );
        }
      }
    }
  }, [connected, isAuthenticated, dispatch]);

  return {
    isOfflineAuthenticated: connected !== undefined && isAuthenticated !== undefined ? isOfflineAuthenticated() : false,
    isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : false
  };
};

export default useIsOfflineAuthenticated;
