import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import distributorLocations from 'assets/distributorlocations.json';
import { useEffect, useState } from 'react';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';

const defaultShopUrl = 'https://www.skf.com/group/support/find-a-distributor';

const getDefaultShopUrl = (location: string): string => {
  if (location) {
    const distributor = distributorLocations.find((d) => d.code.toLocaleLowerCase() === location.toLocaleLowerCase());
    if (distributor) {
      return distributor.url;
    }
  }
  return defaultShopUrl;
};

const useBuyOnline = (lubricatorId?: string): [string | null, () => void] => {
  const location = useAppSelector((state) => state.setting.location, shallowEqual).toLocaleUpperCase();
  const lubricator = useAppSelector((state) =>
    state.data.lubricators.find(
      (l) => l.id.toLocaleLowerCase() === lubricatorId?.toLocaleLowerCase() || l.displayName.toLocaleLowerCase() === lubricatorId?.toLocaleLowerCase()
    )
  );
  const { logNavigationShopOnline } = useAnalyticsLog();

  const [shopUrl, setShopUrl] = useState<string | null>(null);

  useEffect(() => {
    if (lubricator && location && lubricator.shopUrls) {
      if (lubricator.shopUrls[location]) {
        setShopUrl(lubricator.shopUrls[location]);
      } else {
        setShopUrl(getDefaultShopUrl(location));
      }
    } else {
      setShopUrl(getDefaultShopUrl(location));
    }
  }, [location, lubricator]);

  return [
    shopUrl,
    () => {
      if (shopUrl) {
        logNavigationShopOnline();
        window.open(shopUrl, '_blank', 'noopener,noreferrer');
      }
    }
  ];
};

export default useBuyOnline;
