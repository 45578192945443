import { Select } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setReplenishment } from 'store/InputSlice';
import { useEffect, useState } from 'react';
import { ConditionChoice } from 'domain/Conditions';

const ReplenishmentOption = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const replenishments = useAppSelector((state) => state.data.conditions.replenishment);
  const replenishment = useAppSelector((state) => state.input.replenishment);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);

  const options = replenishments.map((c) => ({
    label: intl.formatMessage({ id: c.label }),
    value: (c.value as any).toString()
  }));
  const [currentValue, setCurrentValue] = useState<ConditionChoice<number>>(replenishment);

  useEffect(() => {
    if (currentValue.value !== replenishment.value) {
      setCurrentValue(replenishment);
    }
  }, [replenishment, currentValue]);

  const selectChanged = (value: any) => {
    const choice = replenishments.find((l) => (l.value as any).toString() === value);
    if (choice) {
      dispatch(setReplenishment(choice));
    }
  };

  return (
    <Select
      id="condition"
      feLabel={intl.formatMessage({ id: 'operating.replenishment' })}
      onChange={selectChanged}
      value={currentValue.value.toString()}
      feItems={options}
      multiple={false}
      feHint={intl.formatMessage({ id: replenishment.description })}
      disabled={isDisabled}
    />
  );
};

export default ReplenishmentOption;
