import { Grease } from './Grease';
import { Lubricator, LubricatorSetting } from './Lubricator';

export enum WarningType {
  selection = 'selection',
  withoutCage = 'withoutCage',
  highFeedRate = 'highFeedRate',
  lowFeedRate = 'lowFeedRate',
  lubeInterval = 'lubeInterval',
  validationError = 'validationError',
  none = 'none'
}

export enum ValueType {
  length = 'length',
  temperature = 'temperature',
  weight = 'weight'
}

export interface ConvertibleValue {
  metricValue: number;
  type: ValueType;
}

export interface ResultWarning {
  key: string;
  type: WarningType;
  formattingValues: Array<ConvertibleValue | number | string>;
  innerWarning?: ResultWarning;
}

export enum GreaseDisabledReason {
  lubricator = 'lubricator',
  temperature = 'temperature',
  speed = 'speed',
  load = 'load'
}
export interface GreaseAvalability {
  grease: Grease;
  isDisabled: boolean;
  disabledReason: Array<GreaseDisabledReason>;
}

export interface ValidationError {
  message: string;
  warningMessage: string;
  isValid: boolean;
}

export interface Validation {
  dimensions: ValidationError;
  isValid: boolean;
}

export interface Result {
  relubricationInterval: number;
  correctedRelubricationInterval: number;
  feedRate: number;
  greaseQuantity: number;
  warnings: Array<ResultWarning>;
  availableGreases: Array<GreaseAvalability>;
  availableLubricators: Array<Lubricator>;
  lubricatorSetting: LubricatorSetting;
  hideResult: boolean;
  validation: Validation;
}
