import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import { handleAccuracy, roundSignificantNumbers } from 'utils/data/round';
import { ResultWithUnits } from './ResultConversion';

interface ResultRowProps {
  header: string;
  value: number | string;
  unit: string;
}

const ResultNumbers = ({
  result,
  NumberComponent,
  langKey
}: {
  result: ResultWithUnits | undefined;
  NumberComponent: React.FC<ResultRowProps>;
  langKey: string;
}) => {
  const isValid = useAppSelector((state) => state.result.result?.validation.isValid);
  const intl = useIntl();

  const [correctedRelubricationInterval, setCorrectedRelubricationInterval] = useState<string>('-');
  const [greaseQuantity, setGreaseQuantity] = useState<string>('-');
  const [feedRate, setFeedRate] = useState<string>('-');

  useEffect(() => {
    if (isValid) {
      setCorrectedRelubricationInterval(roundSignificantNumbers(result?.correctedRelubricationInterval, 1).toString());
      setGreaseQuantity(handleAccuracy(roundSignificantNumbers(result?.greaseQuantity, 2), 2));
      setFeedRate(handleAccuracy(roundSignificantNumbers(result?.feedRate, 2), 2));
    } else {
      setCorrectedRelubricationInterval('-');
      setGreaseQuantity('-');
      setFeedRate('-');
    }
  }, [isValid, result]);

  return (
    <>
      <NumberComponent
        header={intl.formatMessage({
          id: `result.${langKey}.correctedInterval`
        })}
        unit={intl.formatMessage({
          id: `result.${langKey}.correctedInterval.unit`
        })}
        value={correctedRelubricationInterval}
      />
      <NumberComponent
        header={intl.formatMessage({
          id: `result.${langKey}.greaseQuantity`
        })}
        unit={result?.greaseQuantityUnit ?? ''}
        value={greaseQuantity}
      />
      <NumberComponent header={intl.formatMessage({ id: `result.${langKey}.feedRate` })} unit={result?.feedRateUnit ?? ''} value={feedRate} />
    </>
  );
};

export default ResultNumbers;
