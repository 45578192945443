import { Button, Flexbox, Heading, Loader, Pagination, Table, TableCell, TableRow, Text } from '@skf-internal/ui-components-react-legacy';
import InfoBox from 'components/layouts/InfoBox';
import { Notification } from 'domain/Notification';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { Direction, sortAnyPredicate } from 'utils/data/sorter';
import { getTotalDaysRemaining } from './TimeLeft';

export enum SortColumn {
  name = 'name',
  installationDate = 'installationDate',
  replenishmentDate = 'replenishmentDate',
  notificationDate = 'notificationDate',
  timeLeft = 'timeLeft'
}
export type TableBaseProps = {
  getTableRow: (notification: Notification) => TableRow;
  displayHeaders: { name: string; sortColumn: SortColumn; className?: string }[];
  usePagination?: boolean;
  header?: string;
  description?: string;
  showActions: boolean;
  showInfoBox: boolean;
};

const TableBase = ({ getTableRow, displayHeaders, usePagination, header, description, showActions, showInfoBox }: TableBaseProps) => {
  const pageSize = 20;

  const intl = useIntl();
  const history = useHistory();

  const notifications = useAppSelector((state) => state.notifications.notifications);
  const isLoading = useAppSelector((state) => state.notifications.isLoading);
  const isAdding = useAppSelector((state) => state.notifications.isAdding);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState<SortColumn>(SortColumn.timeLeft);
  const [sortDirection, setSortDirection] = useState<Direction>(Direction.asc);

  const getSorter = (sortColumn: SortColumn) => {
    switch (sortColumn) {
      case SortColumn.name:
        return sortAnyPredicate<Notification>((n) => n.name, sortDirection);
      case SortColumn.installationDate:
        return sortAnyPredicate<Notification>((n) => (n.reminder?.installationDate ? n.reminder?.installationDate : ''), sortDirection);
      case SortColumn.replenishmentDate:
        return sortAnyPredicate<Notification>((n) => (n.reminder?.replenishmentDate ? n.reminder?.replenishmentDate : ''), sortDirection);
      case SortColumn.notificationDate:
        return sortAnyPredicate<Notification>((n) => (n.reminder?.notificationDate ? n.reminder?.notificationDate : ''), sortDirection);
      case SortColumn.timeLeft:
        return sortAnyPredicate<Notification>(
          (n) => getTotalDaysRemaining(n.reminder.createdAt, n.reminder.installationDate, n.reminder.replenishmentDate, n.reminder.installationDate).remaining,
          sortDirection
        );
      default:
        break;
    }
  };

  const getFilteredAndSortedTableRows = (notifications: Notification[]): TableRow[] => {
    let sortedNotifications = [...notifications];
    if (sortColumn && sortDirection) {
      sortedNotifications = sortedNotifications.sort(getSorter(sortColumn));
    }
    if (usePagination) {
      return sortedNotifications.slice((currentPage - 1) * pageSize, currentPage * pageSize).map(getTableRow);
    } else {
      return sortedNotifications.map(getTableRow);
    }
  };

  const getSortable = (sortColumn: SortColumn) => ({
    onClick: () => {
      setSortColumn(sortColumn);
      switch (sortDirection) {
        case Direction.asc:
          setSortDirection(Direction.desc);
          break;
        case 'desc':
          setSortDirection(Direction.asc);
          break;
        default:
          setSortDirection(Direction.asc);
      }
      setCurrentPage(1);
    }
  });

  const getSortableHead = (): TableRow[] => {
    const cells = displayHeaders.map((h) => ({ children: h.name, scope: 'col', sortable: getSortable(h.sortColumn), className: h.className })) as TableCell[];
    if (showActions) {
      cells.push({ children: '', scope: 'col' });
    }
    return [{ cells }];
  };

  const renderTable = () => {
    return (
      <>
        {showInfoBox ? (
          <InfoBox header={header} className="w-full flex flex-col relative" noPadding>
            <div className="p-2 sm:p-4">
              {description && <Text className="mb-4">{description}</Text>}
              <Table feHead={getSortableHead()} feBody={getFilteredAndSortedTableRows(notifications)} feCompact />
            </div>
            {isAdding && (
              <div className="w-full h-full absolute bg-white opacity-80">
                <Loader className="absolute m-auto left-0 top-0 right-0 bottom-0" />
              </div>
            )}
          </InfoBox>
        ) : (
          <div className="w-full flex flex-col">
            {(header || description) && (
              <div className="flex flex-col px-4 pb-4">
                <Heading as="h4" className="mb-1">
                  {header}
                </Heading>
                <Text className="text-xs">{description}</Text>
              </div>
            )}
            <Table feHead={getSortableHead()} feBody={getFilteredAndSortedTableRows(notifications)} feCompact />
          </div>
        )}
        {usePagination && (
          <Flexbox feJustifyContent="flex-end">
            <Pagination
              feControls={{
                next: {
                  'aria-label': intl.formatMessage({ id: 'usercalculations.table.nextPage' })
                },
                prev: {
                  'aria-label': intl.formatMessage({ id: 'usercalculations.table.previousPage' })
                }
              }}
              feDelimiter={intl.formatMessage({ id: 'usercalculations.table.of' })}
              feOnPageChange={(page: number) => setCurrentPage(page)}
              fePage={currentPage}
              feItemsPerPage={pageSize}
              feTotal={notifications.length}
            />
          </Flexbox>
        )}
      </>
    );
  };

  const renderNoCalculations = () => {
    const onClick = () => {
      history.push('/dialsetting');
    };
    return (
      <Flexbox feJustifyContent="center" feFlexDirection="column" className="w-full">
        <div className="mx-auto my-4">{intl.formatMessage({ id: 'usercalculations.table.noCalculation' })}</div>
        <Button onClick={onClick} className="mx-auto cursor-pointer">
          {intl.formatMessage({ id: 'usercalculations.table.addDevices' })}
        </Button>
      </Flexbox>
    );
  };

  return (
    <div className="w-full mb-12">{isLoading ? <Loader className="m-4 mx-auto" /> : notifications.length === 0 ? renderNoCalculations() : renderTable()}</div>
  );
};

export default TableBase;
