import { ReactNode } from 'react';

interface InfoBoxIndicatedProps {
  children: ReactNode;
  header: string;
  indicationText: string;
  className?: string;
}

const Chevron = ({ className }: { className?: string }) => {
  return <div className={'chevron ' + (className ? className : '')}></div>;
};

const InfoBoxIndicated = ({ children, header, indicationText, className }: InfoBoxIndicatedProps) => {
  return (
    <div className="borderbox m-4 sm:m-2 border border-t-0 rounded sm:pb-4 sm:h-full bg-white">
      <div className="bg-good-600 p-2 text-white font-chevinmedium">{header}</div>
      <div className="border-2 border-good-600 py-4 text-good-600 font-bold text-center">{indicationText}</div>
      <div className="flex align-center mb-4">
        <Chevron className="mx-auto" />
      </div>

      <div className={'p-2 sm:p-4 ' + (className ? className : '')}>{children}</div>
    </div>
  );
};

export default InfoBoxIndicated;
