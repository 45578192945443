import { useIsAuthenticated } from '@azure/msal-react';
import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { silentLoadNotifications } from 'store/NotificationSlice';

const useLoadNotificationsSilent = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(silentLoadNotifications(''));
    }
  }, [dispatch, isAuthenticated]);

  return null;
};

export default useLoadNotificationsSilent;
