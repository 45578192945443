import localforage from 'localforage';
import { Input } from 'domain/Input';
import { NotificationInput, ConfirmInput } from 'domain/Notification';
import { Result } from 'domain/Result';
import { TrackingEvent, TrackingRepository, TrackingType } from '../NotificationRepository';
import { v4 } from 'uuid';

const trackingKey = 'offlinetracking';

class OfflineTrackingRepository implements TrackingRepository {
  getOfflineEvents(): Promise<TrackingEvent[]> {
    return this.getTrackingEventsOrDefault();
  }

  async addNotification(
    offlineId: string,
    notification: NotificationInput,
    input: Input,
    result: Result,
    language: string,
    geoLocation: string | undefined
  ): Promise<void> {
    await this.addTrackingEvent({
      trackingId: v4(),
      notificationId: offlineId,
      type: TrackingType.addNotification,
      data: { notification, input, result, language, geoLocation },
      timestamp: new Date()
    });
  }

  async updatNotification(notificationId: string, notification: NotificationInput): Promise<void> {
    await this.addTrackingEvent({
      trackingId: v4(),
      notificationId: notificationId,
      type: TrackingType.updatNotification,
      data: { notificationId, notification },
      timestamp: new Date()
    });
  }

  async deleteNotification(notificationId: string): Promise<void> {
    await this.addTrackingEvent({
      trackingId: v4(),
      notificationId: notificationId,
      type: TrackingType.deleteNotification,
      data: { notificationId },
      timestamp: new Date()
    });
  }

  async confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<void> {
    await this.addTrackingEvent({
      trackingId: v4(),
      notificationId: notificationId,
      type: TrackingType.confirmReminder,
      data: { notificationId, confirmReminder },
      timestamp: new Date()
    });
  }

  async addTrackingEvent(event: TrackingEvent): Promise<void> {
    await this.storeTrackingEvents([...(await this.getTrackingEventsOrDefault()), event]);
  }

  async getTrackingEventsOrDefault(): Promise<TrackingEvent[]> {
    let values = await localforage.getItem<TrackingEvent[]>(trackingKey);
    if (!values) {
      values = [];
    }
    return values;
  }

  async storeTrackingEvents(events: TrackingEvent[]): Promise<void> {
    await localforage.setItem(trackingKey, events);
  }

  async removeTrackingEvent(event: TrackingEvent): Promise<void> {
    await localforage.setItem(
      trackingKey,
      (await this.getTrackingEventsOrDefault()).filter((t) => t.trackingId !== event.trackingId)
    );
  }
}

export class OfflineTrackingRepositoryStub implements TrackingRepository {
  addNotification(
    offlineId: string,
    notification: NotificationInput,
    input: Input,
    result: Result,
    language: string,
    geoLocation: string | undefined
  ): Promise<void> {
    return Promise.resolve();
  }
  updatNotification(notificationId: string, notification: NotificationInput): Promise<void> {
    return Promise.resolve();
  }
  deleteNotification(notificationId: string): Promise<void> {
    return Promise.resolve();
  }
  confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<void> {
    return Promise.resolve();
  }
  getOfflineEvents(): Promise<TrackingEvent[]> {
    return Promise.resolve([]);
  }
  removeTrackingEvent(event: TrackingEvent): Promise<void> {
    return Promise.resolve();
  }
}

export default OfflineTrackingRepository;
