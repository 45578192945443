import { Notification, NotificationInput } from 'domain/Notification';
import AuthenticationDialog from 'features/Authentication/AuthenticationDialog';
import { ComponentType, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDisabled } from 'store/InputSlice';
import { addNotification } from 'store/NotificationSlice';
import useGeoLocation from 'utils/hooks/useGeoLocation';
import OnClickProps from 'utils/ui/OnClickProps';
import NotificationDialog, { DialogData } from './NotificationDialog';

const SaveNotificationAction = ({ Icon }: { Icon: ComponentType<OnClickProps> }) => {
  const isAuthenticated = useAppSelector((state) => state.account.isAuthenticated);
  const isCurrentNotification = useAppSelector((state) => (state.notifications.current ? true : false));
  const isValidResult = useAppSelector((state) => !state.result.result?.hideResult);
  const duplicateFrom = useAppSelector((state) => state.notifications.duplicateFrom);

  const currentInterval = useAppSelector((state) => state.result.result?.lubricatorSetting.intervalDuration);
  const isTLMP = useAppSelector((state) => state.input.lubricator.id === 'TLMP');
  const lubricator = useAppSelector((state) => state.input.lubricator.id);

  const { logDeviceAdded } = useAnalyticsLog();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const geoLocation = useGeoLocation();

  const intl = useIntl();
  const [saveOpen, setSaveOpen] = useState(false);
  const [loginDialog, setLoginDialog] = useState(false);
  const [notificationCopy, setNotificationCopy] = useState<Notification>();

  useEffect(() => {
    if (duplicateFrom) {
      setNotificationCopy({ ...duplicateFrom, name: `COPY - ${duplicateFrom.name}` });
    } else {
      setNotificationCopy(undefined);
    }
  }, [duplicateFrom]);

  const onSaveClick = () => {
    if (isAuthenticated) {
      setSaveOpen(true);
    } else {
      setLoginDialog(true);
    }
  };

  const onSave = (input: DialogData) => {
    dispatch(
      addNotification({
        input: {
          ...input
        } as NotificationInput,
        callback: (notification) => {
          history.replace('/calculation/' + notification.id);
          logDeviceAdded(duplicateFrom !== null, lubricator);
        },
        geoLocation
      })
    );
    dispatch(setDisabled(true));
    setSaveOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return !isCurrentNotification && currentInterval ? (
    <>
      <Icon onClick={() => (isValidResult ? onSaveClick() : null)} />
      <NotificationDialog
        isOpen={saveOpen}
        onClose={() => setSaveOpen(false)}
        onSave={onSave}
        existingNotification={notificationCopy}
        currentInterval={currentInterval}
        isTLMP={isTLMP}
        header={intl.formatMessage({ id: 'usercalculations.add.header' })}
        saveButtonText={intl.formatMessage({ id: 'usercalculations.add.savebutton' })}
      />
      <AuthenticationDialog header={intl.formatMessage({ id: 'usercalculations.add.header' })} isOpen={loginDialog} onClose={() => setLoginDialog(false)} />
    </>
  ) : (
    <></>
  );
};

export default SaveNotificationAction;
