import { Button, Dialog, Flexbox, Spacer, Spacings } from '@skf-internal/ui-components-react-legacy';
import { ReactNode } from 'react';

type InteruptiveDialogProps = {
  children: ReactNode;
  open: boolean;
  title: string;
  OnClose?: () => void;
  buttons?: {
    primary: { value: string; onClick: () => void };
    secondary?: { value: string; onClick: () => void };
  };
  cssHeight?: string;
  cssWidth?: string;
};

const InteruptiveDialog = ({ children, open, title, OnClose, buttons, cssHeight, cssWidth }: InteruptiveDialogProps) => {
  return (
    <Dialog
      cssHeight={cssHeight}
      cssWidth={cssWidth}
      feInterruptive
      // feButtons={{
      //   primary: { children: intl.formatMessage({ id: 'usercalculations.delete.delete' }), onClick: onDelete },
      //   secondary: { children: intl.formatMessage({ id: 'usercalculations.cancelbutton' }), onClick: () => setIsDialogOpen(false) }
      // }}
      open={open}
      feTitle={title}
      feOnClose={OnClose}
    >
      {children}
      {buttons && (
        <>
          <Spacer />
          <Flexbox feGap={Spacings.Md} feJustifyContent="flex-end">
            {buttons.secondary && (
              <Button feType="secondary" onClick={buttons.secondary.onClick}>
                {buttons.secondary.value}
              </Button>
            )}

            <Button onClick={buttons.primary.onClick}>{buttons.primary.value}</Button>
          </Flexbox>
        </>
      )}
    </Dialog>
  );
};

export default InteruptiveDialog;
