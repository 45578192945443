import { Colors, FontSizes, Icons, Link } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { appendClassCondition } from 'utils/ui/appendClass';

const NavigationButton = ({
  headerid,
  onClick,
  icon,
  disabled,
  indicator
}: {
  headerid: string;
  onClick: () => void;
  icon: Icons;
  disabled?: boolean;
  indicator?: string;
}) => {
  const intl = useIntl();
  return (
    <>
      {indicator ? (
        <div
          className="absolute left-4 top-2 rounded-full w-5 h-5 text-white font-bold flex items-center justify-center"
          style={{ backgroundColor: Colors.OrangeDarker, fontSize: FontSizes.Xs }}
        >
          {indicator}
        </div>
      ) : null}
      <Link
        disabled={disabled}
        as="button"
        feIcon={{ feIcon: icon, position: 'left' }}
        onClick={onClick}
        className={appendClassCondition('', 'cursor-not-allowed', 'cursor-pointer', disabled)}
        type="button"
        feColor="secondary"
      >
        {intl.formatMessage({ id: headerid })}
      </Link>
    </>
  );
};

export default NavigationButton;
