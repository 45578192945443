import { Icons, Link } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { useAppSelector } from 'store/hooks';

const PrintButton = () => {
  const intl = useIntl();
  const manualBearingSelection = useAppSelector((state) => state.input.manualBearingSelection);
  const { logPrintResult, logPrintManualBearingEntry } = useAnalyticsLog();
  const clickPrint = () => {
    logPrintResult();
    if (manualBearingSelection) {
      logPrintManualBearingEntry();
    }
    window.print();
  };

  return (
    <Link onClick={clickPrint} className="cursor-pointer mt-4" as="button" feIcon={{ feIcon: Icons.Printer, position: 'left' }} type="button">
      {intl.formatMessage({ id: 'result.desktop.print' })}
    </Link>
  );
};

export default PrintButton;
