import { NotificationRepository } from './NotificationRepository';
import NotificationSyncService from './NotificationSyncService';
import LocalOfflineRepository from './Repositories/LocalOfflineRepository';
import LocalOnlineRepository from './Repositories/LocalOnlineRepository';
import OfflineTrackingRepository, { OfflineTrackingRepositoryStub } from './Repositories/OfflineTrackingRepository';
import OnlineRepository from './Repositories/OnlineRepository';

const getNotificationRepository = (connection?: boolean): NotificationRepository => {
  if (connection) {
    console.log('REPOSITORY:got for online');
    return new LocalOnlineRepository(new OnlineRepository(), new LocalOfflineRepository(new OfflineTrackingRepository()));
  } else {
    console.log('REPOSITORY:got for offline');
    return new LocalOfflineRepository(new OfflineTrackingRepository());
  }
};

export const getOnlineFirstNotificationRepository = (): NotificationRepository => {
  console.log('REPOSITORY:got for online first');
  return new LocalOnlineRepository(new OnlineRepository(), new LocalOfflineRepository(new OfflineTrackingRepositoryStub()), true);
};

export const getSyncService = (): NotificationSyncService => {
  return new NotificationSyncService(new OnlineRepository(), new OfflineTrackingRepository());
};

export default getNotificationRepository;
