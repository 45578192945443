import { Analytics } from '@firebase/analytics';
import FirebaseRepository from '../FirebaseRepository';

import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent as logEventRoot } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAOxv0mK5o3O_IWkZAIBs6YV16UMk4xHis',
  authDomain: 'mcoe-dialset-prod.firebaseapp.com',
  projectId: 'mcoe-dialset-prod',
  storageBucket: 'mcoe-dialset-prod.appspot.com',
  messagingSenderId: '111901094587',
  appId: process.env.REACT_APP_FIREBASE_APP_ID, //"1:111901094587:web:b04af21d66374199b5af9e",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID // "G-E03VF96L77"
};

class FirebaseOnlineRepository implements FirebaseRepository {
  static analytics: Analytics;
  logEvent(eventName: string, eventParams?: { [key: string]: any } | undefined): void {
    logEventRoot(FirebaseOnlineRepository.analytics, eventName, eventParams);
  }
}

const app = initializeApp(firebaseConfig);
FirebaseOnlineRepository.analytics = getAnalytics(app);

export default FirebaseOnlineRepository;
