import { ConditionLevel, Conditions } from 'domain/Conditions';
import { Grease } from 'domain/Grease';
import { Input } from 'domain/Input';
import { Lubricator } from 'domain/Lubricator';
import { Result, ResultWarning, WarningType } from 'domain/Result';
import calculateCorrectedRelubricationInterval from './CorrectedRelubricationCalculator';
import calculateFeedRate from './FeedRateCalculator';
import filterGreases from './GreaseFilter';
import calculateGreaseQuantity from './GreaseQuantityCalculator';
import filterLubricators from './LubricationFilter';
import getLubricatorSetting from './LubricatorSettingsService';
import calculateRelubricationInterval from './RelubricationIntervalCalculator';
import validate from './Validation';
import getWarnings, { getWarningsForValidation } from './WarningService';

export default async function calculate(input: Input, conditions: Conditions, greases: Array<Grease>, lubricators: Array<Lubricator>): Promise<Result> {
  const inputWithDefaults = setDefaults(input, conditions);

  return new Promise((resolve) => {
    const validation = validate(inputWithDefaults, conditions);
    const relubricationInterval = calculateRelubricationInterval(inputWithDefaults);
    const correctedRelubricationInterval = calculateCorrectedRelubricationInterval(relubricationInterval, inputWithDefaults);
    const greaseQuantity = calculateGreaseQuantity(inputWithDefaults);
    const feedRate = calculateFeedRate(correctedRelubricationInterval, greaseQuantity);

    const availableGreases = filterGreases(inputWithDefaults, greases, input.lubricator.compatibleGreases);
    const availableLubricators = filterLubricators(inputWithDefaults, lubricators);
    const lubricatorSetting = getLubricatorSetting(
      inputWithDefaults.lubricator,
      greaseQuantity,
      correctedRelubricationInterval,
      feedRate,
      input.ambientTemperature.value
    );

    let warnings: ResultWarning[];
    if (validation.isValid) {
      warnings = getWarnings(relubricationInterval, correctedRelubricationInterval, lubricatorSetting, input, conditions.warnings);
    } else {
      warnings = getWarningsForValidation(validation);
    }

    const result = {
      relubricationInterval: relubricationInterval,
      correctedRelubricationInterval: correctedRelubricationInterval,
      feedRate: feedRate,
      greaseQuantity: greaseQuantity,
      warnings: warnings,
      availableGreases: availableGreases,
      availableLubricators: availableLubricators,
      lubricatorSetting: lubricatorSetting,
      hideResult: warnings.some((w) => w.type !== WarningType.none),
      validation
    } as Result;

    resolve(result);
  });
}

export function shouldHideResult(warnings: Array<ResultWarning>) {
  return warnings.some((w) => w.type !== WarningType.none);
}

export function getDefaults(conditions: Conditions): Input {
  return setDefaults({} as Input, conditions);
}

export function setDefaults(input: Input, conditions: Conditions): Input {
  return {
    bearingType: input.bearingType !== undefined ? input.bearingType : conditions.defaultBearing,
    dimensions:
      input.dimensions !== undefined
        ? input.dimensions
        : {
            innerDiameter: conditions.bearingInnerDiameter.default,
            outerDiameter: conditions.bearingOuterDiameter.default,
            width: conditions.bearingWidthDiameter.default
          },
    speed: input.speed !== undefined ? input.speed : conditions.speed.default,
    hours: input.hours !== undefined ? input.hours : 24,
    temperature: input.temperature !== undefined ? input.temperature : conditions.temperatures.find((t) => t.value === ConditionLevel.moderate),
    contamination: input.contamination !== undefined ? input.contamination : conditions.contamination.find((t) => t.value === 0.5),
    load: input.load !== undefined ? input.load : conditions.load.find((l) => l.value === ConditionLevel.low),
    shockLoad: input.shockLoad !== undefined ? input.shockLoad : conditions.shockLoad.find((sl) => sl.value === false),
    ambientTemperature: input.ambientTemperature !== undefined ? input.ambientTemperature : conditions.ambientTemperature.find((at) => at.value === 25),
    shaftOrientation: input.shaftOrientation !== undefined ? input.shaftOrientation : conditions.shaftOrientation.find((sl) => sl.value === 1),
    replenishment: input.replenishment !== undefined ? input.replenishment : conditions.replenishment.find((r) => r.value === 0.005),
    rotatingOuterRing: input.rotatingOuterRing !== undefined ? input.rotatingOuterRing : conditions.rotatingOuterRing.find((r) => r.value === false),
    grease: input.grease !== undefined ? input.grease : conditions.defaultGrease,
    lubricator: input.lubricator !== undefined ? input.lubricator : conditions.defaultLubricator,
    manualBearingSelection: false,
    bearingDesignation: null
  } as Input;
}
