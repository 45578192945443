import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';

enum ConnectionStatus {
  NOT_SET = 0,
  ONLINE = 1,
  OFFLINE = 2
}

type Detection = {
  previousCheckWasOnline: boolean;
  connectionStatus: ConnectionStatus; // used to update state only when needed
};

let currentDetection = {
  previousCheckWasOnline: true,
  connectionStatus: ConnectionStatus.NOT_SET
} as Detection;

var intervalID: NodeJS.Timeout | null = null;

const useOfflineDetection = (setIsConnected: (connection: boolean) => void) => {
  const { logGoingOnlineAfterOffline } = useAnalyticsLog();

  if (intervalID) {
    clearInterval(intervalID);
  }

  intervalID = setInterval(() => {
    fetch('https://www.skf.com/se', {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-cache'
    })
      .then(() => {
        if (currentDetection.previousCheckWasOnline && currentDetection.connectionStatus !== ConnectionStatus.ONLINE) {
          currentDetection.connectionStatus = ConnectionStatus.ONLINE;
          setIsConnected(true);
        } else if (!currentDetection.previousCheckWasOnline) {
          logGoingOnlineAfterOffline();
        }
        currentDetection.previousCheckWasOnline = true;
      })
      .catch(() => {
        if (!currentDetection.previousCheckWasOnline && currentDetection.connectionStatus !== ConnectionStatus.OFFLINE) {
          currentDetection.connectionStatus = ConnectionStatus.OFFLINE;
          setIsConnected(false);
        }
        currentDetection.previousCheckWasOnline = false;
      });
  }, 2000);
};

export default useOfflineDetection;
