export const round = (value: number | undefined, decimals: number = 0): number => {
  if (!value) return NaN;

  if (decimals === 0) {
    return Math.round(value);
  } else {
    const roundFactor = Math.pow(10, decimals);
    return Math.round(value * roundFactor) / roundFactor;
  }
};

export const bankersRound = (n: number, d: number = 0): number => {
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  let br = 0;
  const x1 = x > 0 ? x : -x;
  if (x1 % 1 === 0.5) {
    if (0 === r % 2) {
      br = r;
    } else {
      br = r - 1;
    }
  } else {
    br = r;
  }
  return br / Math.pow(10, d);
};

export const roundSignificantNumbers = (value: number | undefined, significantNumbers: number): number => {
  if (!value) return NaN;

  value = Number(value);

  if (value > 1) {
    significantNumbers++;
  }
  if (value < 0.01) {
    significantNumbers--;
    if (significantNumbers === 0) {
      significantNumbers = 1;
    }
  }

  return Number.parseFloat(value.toPrecision(significantNumbers));
};

export const handleAccuracy = (value: number, significantNumbers: number): string => {
  return value.toFixed(significantNumbers);
};
