import { AccountInfo } from '@azure/msal-browser';
import { AnyAction, createListenerMiddleware, PayloadAction } from '@reduxjs/toolkit';
import { Locale } from 'services/Localization/LocalizationService';
import { login } from 'store/AccountSlice';
import { RootState } from 'store/store';
import { availableLanguages, setLocale } from '.';

const accountLanguageMiddleware = createListenerMiddleware();

accountLanguageMiddleware.startListening({
  predicate: (action: AnyAction, currentState, previousState) => {
    return action.type === login.type && (currentState as RootState).setting.intl.locale === (currentState as RootState).setting.intl.defaultLocale;
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    if (action.payload) {
      const accountAction = action as PayloadAction<AccountInfo | null>;
      if (accountAction.payload) {
        if (accountAction.payload.idTokenClaims) {
          if (accountAction.payload.idTokenClaims.language) {
            listenerApi.dispatch(setLocale(translateLanguage(accountAction.payload.idTokenClaims.language as string)));
          }
        }
      }
    }
  }
});

export default accountLanguageMiddleware;

const translateLanguage = (language: string): Locale => {
  const found = availableLanguages.find((l) => l.azureB2C === language);
  if (found) {
    return found.locale;
  } else {
    return Locale.english;
  }
};
