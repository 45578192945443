import { Input } from 'domain/Input';
import { Result } from 'domain/Result';
import { StoredCalculation } from 'domain/StoredCalculation';
import createStoredCalculation from 'services/StoredCalculation/createStored';

export const addCalculationStateToStorage = (input: Input, result: Result) => {
  const calculation = createStoredCalculation(input, result);
  localStorage.setItem('calculation', JSON.stringify(calculation));
};

export const getCalculationFromStorage = (): StoredCalculation | null => {
  const data = localStorage.getItem('calculation');
  if (data) {
    return JSON.parse(data) as StoredCalculation;
  }
  return null;
};

export const removeCalculationFromStorage = () => {
  localStorage.removeItem('calculation');
};
