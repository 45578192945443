import { useEffect, useRef } from 'react';

export default function useOuterClick(callback: any) {
  const callbackRef = useRef<HTMLDivElement | null>(null); // initialize mutable callback ref
  const innerRef = useRef<HTMLDivElement | null>(null); // returned to client, who sets the "border" element

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => {
    callbackRef.current = callback;
  });
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e: any) {
      if (innerRef.current) {
        if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
          callbackRef.current = e;
          callback(e);
        }
      }
    }
  }); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
