interface BurgerMenuButtonProps {
  isOpen?: boolean;
  onClick?: () => void;
}

const BurgerMenuButton = ({ isOpen, onClick }: BurgerMenuButtonProps) => {
  return (
    <div onClick={onClick} className={'cursor-pointer h-14 flex flex-col w-14 justify-center items-center ' + (isOpen ? 'bg-primary-700' : '')}>
      <div className={'w-burger h-burger bg-white transition-all origin-burgerTop ' + (isOpen ? 'transform rotate-45 ' : '')}></div>
      <div className={'w-burger h-burger bg-white mt-1.5 transition-all ' + (isOpen ? 'opacity-0' : '')}></div>
      <div className={'w-burger h-burger bg-white mt-1.5 transition-all origin-burgerBottom ' + (isOpen ? 'transform -rotate-45 ' : '')}></div>
    </div>
  );
};

export default BurgerMenuButton;
