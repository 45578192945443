import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { addCalculationStateToStorage } from 'services/LocalStorage/LocalStorageRepository';
import { useAppSelector } from 'store/hooks';
import useAuthenticationRequest from './useAuthenticationRequest';

const useLoginRedirect = () => {
  const input = useAppSelector((state) => state.input);
  const result = useAppSelector((state) => state.result);
  const request = useAuthenticationRequest();
  const { instance } = useMsal();

  const redirect = useCallback(() => {
    const login = async () => {
      //Store state before logging in
      addCalculationStateToStorage(input, result.result!);
      await instance.loginRedirect(request);
    };
    login().catch((err) => console.log(err));
  }, [input, result, request, instance]);

  return { redirect };
};

export default useLoginRedirect;
