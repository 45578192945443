import { msalInstance } from 'index';

export const getToken = async (scopes: string[]): Promise<{ Authorization: string }> => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: scopes
  });

  const bearer = `Bearer ${response.accessToken}`;

  return { Authorization: bearer };
};
