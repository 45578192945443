import { getTime } from 'date-fns';

export const createDateStringFromEpoch = (secondsFrom1970?: number): string | undefined => {
  if (secondsFrom1970) {
    return createDateFromMilliseconds(secondsFrom1970 * 1000);
  }
};

export const createDateFromMilliseconds = (milliSeconds?: number): string | undefined => {
  if (milliSeconds) {
    return new Date(milliSeconds).toISOString();
  }
};

export const getNumberFromDate = (date?: Date): number | undefined => {
  if (date) {
    return getTime(date);
  }
};
