import { useEffect, useState } from 'react';
import getLocation from 'services/Location/LocationService';

const useGeoLocation = () => {
  const [location, setLocation] = useState<string>();

  useEffect(() => {
    getLocation().then((loc) => setLocation(loc));
  }, []);

  return location;
};

export default useGeoLocation;
