import { Result } from 'domain/Result';
import { IntlShape } from 'react-intl';
import { gramToOunces } from 'services/UnitConversion/UnitConversionService';

export interface ResultWithUnits extends Result {
  feedRateUnit: string;
  greaseQuantityUnit: string;
}

export default function convertResult(result: Result | undefined, isImperial: boolean, intl: IntlShape): ResultWithUnits | undefined {
  if (result) {
    if (isImperial) {
      return {
        ...result,
        feedRate: gramToOunces(result.feedRate),
        greaseQuantity: gramToOunces(result.greaseQuantity),
        feedRateUnit: intl.formatMessage({ id: 'result.desktop.feedRate.unit.imperial' }),
        greaseQuantityUnit: intl.formatMessage({ id: 'unit.weight.imperial' })
      };
    } else {
      return {
        ...result,
        feedRateUnit: intl.formatMessage({ id: 'result.desktop.feedRate.unit.metric' }),
        greaseQuantityUnit: intl.formatMessage({ id: 'unit.weight.metric' })
      };
    }
  }
}
