import { differenceInDays } from 'date-fns';
import { Notification, ConfirmInput } from 'domain/Notification';
import { ComponentType, useState } from 'react';
import { useIntl } from 'react-intl';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { getCurrentNotificationDate, getDateFromString, getExpirationDate } from 'services/Notifications/dateCalculations';
import { useAppDispatch } from 'store/hooks';
import { confirmReminder } from 'store/NotificationSlice';
import useDialogCloser from 'utils/hooks/useDialogCloser';
import OnClickProps from 'utils/ui/OnClickProps';
import NotificationDialog, { DialogData } from './NotificationDialog';

const ReplenishmentAction = ({ notification, Child }: { notification: Notification; Child: ComponentType<OnClickProps> }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const dialogCloser = useDialogCloser();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { logDeviceReplenished } = useAnalyticsLog();

  const onSave = (reminder: DialogData) => {
    dialogCloser(setIsDialogOpen);
    dispatch(confirmReminder({ notificationId: notification.id, confirmReminder: { ...reminder } as ConfirmInput }));
    logDeviceReplenished(differenceInDays(getDateFromString(notification.reminder.replenishmentDate), new Date()));
  };

  const getNewNotification = (notification: Notification) => {
    return {
      ...notification,
      reminder: {
        ...notification.reminder,
        installationDate: new Date().toISOString(),
        replenishmentDate: getExpirationDate(notification.settingInterval, new Date()).toISOString(),
        notificationDate: getCurrentNotificationDate(notification.settingInterval, new Date()).toISOString()
      }
    };
  };

  const isDisabled = () => {
    return getDateFromString(notification.reminder.installationDate) > new Date();
  };

  return (
    <>
      <Child onClick={() => setIsDialogOpen(true)} disabled={isDisabled()} />
      <NotificationDialog
        lastInstallationDate={notification.reminder.installationDate}
        hideName
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={onSave}
        existingNotification={getNewNotification(notification)}
        currentInterval={notification.settingInterval}
        isTLMP={notification.isTLMP}
        header={intl.formatMessage({ id: 'usercalculations.replenishment.dialog.header' })}
        saveButtonText={intl.formatMessage({ id: 'usercalculations.replenishment.dialog.confirm' })}
        text={intl.formatMessage({ id: 'usercalculations.replenishment.dialog.text' })}
      />
    </>
  );
};

export default ReplenishmentAction;
