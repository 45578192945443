import { Grease } from 'domain/Grease';
import greases from 'assets/data/greases.json';

export function getGreases(): Array<Grease> {
  return getGreasesFromJson(greases);
}

export function getGreasesFromJson(greases: Array<any>): Array<Grease> {
  return greases.map(getGreaseFromJson);
}

export function getGreaseFromJson(grease: any): Grease {
  return grease as unknown as Grease;
}
