import SvgSkfLogo from 'components/svgs/SkfLogo';
import ReleaseInfo from 'features/ReleaseInfo/ReleaseInfo';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useIsAppLocation, { AppLocations } from 'utils/hooks/useIsAppLocation';
import LanguageDialog from './LanguageDialog';

const HrefLink = ({ href, text, className }: { href: string; text: string; className: string }) => {
  return (
    <a href={href} className={className} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
};

const Footer = () => {
  const intl = useIntl();
  const [showLanguages, setShowLanguages] = useState(false);
  const [showReleases, setShowReleases] = useState(false);

  const isDialsetting = useIsAppLocation([AppLocations.calculation, AppLocations.dialsetting]);

  const footerElementClasses = 'cursor-pointer text-sm pr-6 leading-loose';

  return (
    <div className={'bg-primary-600 mt-auto ' + (isDialsetting ? 'mb-16 sm:mb-0 ' : '')}>
      <div
        className={
          'flex text-white w-full mx-auto flex-col  sm:items-center sm:h-14 sm:max-w-7xl sm:px-4  sm:flex-row ' + (isDialsetting ? 'mb-4 sm:mb-0' : '')
        }
      >
        <SvgSkfLogo className="mr-6 m-6" />
        <div className="px-4 mb-6 sm:mb-0 flex flex-row flex-wrap items-center">
          <span className="text-sm pr-6 whitespace-nowrap">© Copyright</span>
          <HrefLink
            href={intl.formatMessage({ id: 'navigation.terms.href' })}
            text={intl.formatMessage({ id: 'navigation.terms' })}
            className={footerElementClasses}
          />
          <HrefLink
            href={intl.formatMessage({ id: 'navigation.privacy.href' })}
            text={intl.formatMessage({ id: 'navigation.privacy' })}
            className={footerElementClasses}
          />
          <HrefLink
            href={intl.formatMessage({ id: 'navigation.cookies.href' })}
            text={intl.formatMessage({ id: 'navigation.cookies' })}
            className={footerElementClasses}
          />
          <span onClick={() => setShowLanguages(true)} className={footerElementClasses}>
            {intl.formatMessage({ id: 'navigation.language' })}
          </span>
          <LanguageDialog show={showLanguages} onClose={() => setShowLanguages(false)} />
          <span onClick={() => setShowReleases(true)} className={footerElementClasses}>
            {intl.formatMessage({ id: 'navigation.whatsNew' })}
          </span>
          <ReleaseInfo show={showReleases} onClose={() => setShowReleases(false)} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
