import React from 'react';

type ManualSvgProps = {
  className?: string;
};

const ManualOpenNewSvg = (props: ManualSvgProps) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <g id="Icons/icon_openinnew" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M16,17.0319017 L4,17.0319017 C3.45,17.0319017 3,16.5819017 3,16.0319017 L3,4.03190168 C3,3.48190168 3.45,3.03190168 4,3.03190168 L9,3.03190168 C9.55,3.03190168 10,2.58190168 10,2.03190168 C10,1.48190168 9.55,1.03190168 9,1.03190168 L3,1.03190168 C1.89,1.03190168 1,1.93190168 1,3.03190168 L1,17.0319017 C1,18.1319017 1.9,19.0319017 3,19.0319017 L17,19.0319017 C18.1,19.0319017 19,18.1319017 19,17.0319017 L19,11.0319017 C19,10.4819017 18.55,10.0319017 18,10.0319017 C17.45,10.0319017 17,10.4819017 17,11.0319017 L17,16.0319017 C17,16.5819017 16.55,17.0319017 16,17.0319017 Z M12,2.03190168 C12,2.58190168 12.45,3.03190168 13,3.03190168 L15.59,3.03190168 L6.46,12.1619017 C6.07,12.5519017 6.07,13.1819017 6.46,13.5719017 C6.85,13.9619017 7.48,13.9619017 7.87,13.5719017 L17,4.44190168 L17,7.03190168 C17,7.58190168 17.45,8.03190168 18,8.03190168 C18.55,8.03190168 19,7.58190168 19,7.03190168 L19,2.03190168 C19,1.48190168 18.55,1.03190168 18,1.03190168 L13,1.03190168 C12.45,1.03190168 12,1.48190168 12,2.03190168 Z"
        id="Shape"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

export default ManualOpenNewSvg;
