import { BearingBase, BearingType, BearingTypeCollection } from 'domain/BearingType';
import { Select } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { formatOptionalMessage } from 'utils/strings/intlFormatting';
import { useEffect, useState } from 'react';
import { getDefaultBearingType, isBearingCollection } from 'services/LocalData/BearingTypeLoader';
import treeSearch, { getTreeAsArray } from 'utils/data/treeSearch';

type BearingCollectionProps = {
  collection: BearingTypeCollection;
  currentBearingType: BearingType;
  setInput: (bearingType: BearingBase) => void;
  disabled?: boolean;
};

const BearingCollection = ({ collection, setInput, currentBearingType, disabled }: BearingCollectionProps) => {
  const intl = useIntl();
  const [selectedBearingType, setSelectedBearingType] = useState<BearingBase>(collection.subTypes[0]);

  useEffect(() => {
    if (currentBearingType) {
      const result = treeSearch<BearingBase>(
        collection.subTypes,
        (b) => b.id === currentBearingType.id,
        (b) => (isBearingCollection(b) ? (b as BearingTypeCollection).subTypes : [])
      );
      if (result !== null) {
        const items = getTreeAsArray<BearingBase>(result);
        let first = items[0];
        setSelectedBearingType(first);
        return;
      }
    }
    setSelectedBearingType(collection.subTypes[0]);
  }, [currentBearingType, collection]);

  const onChanged = (value: any) => {
    const selectedType = collection.subTypes.find((bt) => bt.id === value);
    if (selectedType) {
      setSelectedBearingType(selectedType);
      setInternalInput(selectedType);
    }
  };

  const setInternalInput = (bearingType: BearingBase) => {
    if (!isBearingCollection(bearingType)) {
      setInput(bearingType);
    } else {
      const defaultBearingType = getDefaultBearingType(bearingType as BearingTypeCollection);
      if (defaultBearingType) {
        setInput(defaultBearingType);
      }
    }
  };

  const getSelectOptions = (types: BearingBase[]) => {
    return types.map((bt) => ({
      label: intl.formatMessage({ id: bt.name }),
      value: bt.id
    }));
  };

  return (
    <>
      <div className="mt-4">
        <Select
          feLabel={intl.formatMessage({ id: collection.subTypeHeader })}
          onChange={onChanged}
          value={selectedBearingType?.id ?? ''}
          feItems={getSelectOptions(collection.subTypes)}
          multiple={false}
          feHint={formatOptionalMessage({ id: collection.subTypeDescription }, intl)}
          disabled={disabled}
        />
      </div>
      {isBearingCollection(selectedBearingType) ? (
        <BearingCollection
          disabled={disabled}
          currentBearingType={currentBearingType}
          collection={selectedBearingType as BearingTypeCollection}
          setInput={setInternalInput}
        />
      ) : null}
    </>
  );
};

export default BearingCollection;
