import { Icons } from '@skf-internal/ui-components-react-legacy';
import SizeableIcon from 'components/layouts/SizeableIcon';
import { Notification } from 'domain/Notification';
import { useIntl } from 'react-intl';
import OnClickProps from 'utils/ui/OnClickProps';
import DeleteAction from '../Actions/DeleteAction';

const DeleteButton = ({ notification }: { notification: Notification }) => {
  const intl = useIntl();
  return (
    <DeleteAction
      notification={notification}
      Icon={({ onClick }: OnClickProps) => (
        <SizeableIcon onClick={onClick} title={intl.formatMessage({ id: 'usercalculations.delete.tooltip' })} icon={Icons.Trash} />
      )}
    />
  );
};

export default DeleteButton;
