import { useAppSelector } from 'store/hooks';
import DeleteButton from './DeleteButton';
import DuplicateButton from './DuplicateButton';
import EditButton from './EditButton';

const Actions = () => {
  const currentNotification = useAppSelector((state) => state.notifications.current);

  return (
    <div className="hidden sm:flex absolute right-0 top-0 w-fill justify-end items-center">
      <div className="w-5 sm:w-8 mr-2 sm:mr-3 cursor-pointer">
        <EditButton notification={currentNotification!} />
      </div>
      <div className="w-5 sm:w-8 mr-2 sm:mr-3 cursor-pointer">
        <DuplicateButton notification={currentNotification!} />
      </div>
      <div className="w-5 sm:w-8 cursor-pointer">
        <DeleteButton notification={currentNotification!} />
      </div>
    </div>
  );
};

export default Actions;
