import { AnyAction } from '@reduxjs/toolkit';
import { Select } from '@skf-internal/ui-components-react-legacy';
import { ConditionChoice, Conditions } from 'domain/Conditions';
import { Input } from 'domain/Input';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type ConditionChoiceSelectProps<T> = {
  conditionsSelector: (conditions: Conditions) => Array<ConditionChoice<T>>;
  valueSelector: (input: Input) => ConditionChoice<T>;
  action: (selected: ConditionChoice<T>) => AnyAction;
  header: string;
  disabled?: boolean;
};

const ConditionChoiceSelect = <T,>({ conditionsSelector, valueSelector, action, header, disabled }: ConditionChoiceSelectProps<T>) => {
  const currentValues = useAppSelector((state) => state.input);
  const conditions = useAppSelector((state) => state.data.conditions);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const [options, setOptions] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    setOptions(
      conditionsSelector(conditions).map((c) => ({
        label: intl.formatMessage({ id: c.label }),
        value: (c.value as any).toString()
      }))
    );
  }, [conditions, conditionsSelector, intl]);

  const selectChanged = (value: any) => {
    const choice = conditionsSelector(conditions).find((l) => (l.value as any).toString() === value);
    if (choice) {
      dispatch(action(choice));
    }
  };

  return (
    <>
      <Select
        id="condition"
        feLabel={header}
        onChange={selectChanged}
        value={(valueSelector(currentValues).value as any).toString()}
        feItems={options}
        multiple={false}
        disabled={disabled}
        feHint={intl.formatMessage({ id: valueSelector(currentValues).description })}
      />
    </>
  );
};

export default ConditionChoiceSelect;
