import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Header from 'components/layouts/Header';
import DialSetting from 'features/DialSetting/DialSetting';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import 'services/Analytics/firebase';
import PrintForm from 'features/Print/PrintForm';
import 'services/Analytics/hotjar';
import Footer from 'components/layouts/Footer';
import useOfflineDetection from 'utils/hooks/useOfflineDetection';
import { setConnection, setLocation } from 'store/SettingSlice';
import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import useApplyFromStorage from 'services/StoredCalculation/useApplyFromStorage';
import Notifications from 'features/Notifications/Notifications';
import useGeoLocation from 'utils/hooks/useGeoLocation';
import AuthenticatedContainer from 'features/Authentication/AuthenticatedContainer';
import Shortcuts from 'components/layouts/Shortcuts';
import AutoReleaseInfo from 'features/ReleaseInfo/AutoReleaseInfo';
import useSWUpdate from 'utils/hooks/useSWUpdate';
import { addCalculationStateToStorage } from 'services/LocalStorage/LocalStorageRepository';
import { ToastProvider } from '@skf-internal/ui-components-react-legacy';
import SyncStatusToasts from 'features/Notifications/SyncStatusToasts';
import AutoOfflineSync from 'features/OfflineSync/AutoOfflineSync';
import useIsAppInstalled from 'utils/hooks/useIsAppInstalled';
import InstallInfo from 'components/layouts/InstallInfo';

function App({ instance }: { instance: IPublicClientApplication }) {
  const intl = useAppSelector((state) => state.setting.intl);

  const dispatch = useAppDispatch();
  useApplyFromStorage();

  useOfflineDetection((connection) => {
    dispatch(setConnection(connection));
  });
  useIsAppInstalled();

  const location = useGeoLocation();

  useEffect(() => {
    if (location) {
      dispatch(setLocation(location));
    }
  }, [location, dispatch]);

  useEffect(() => {
    let title = 'SKF DialSet';
    if (process.env.REACT_APP_ENVIRONMENT_NAME) {
      if (process.env.REACT_APP_ENVIRONMENT_NAME !== 'prod') {
        title += ` ${process.env.REACT_APP_ENVIRONMENT_NAME} ${process.env.REACT_APP_BUILD_VERSION ? process.env.REACT_APP_BUILD_VERSION : ''}`;
      }
    }
    document.title = title;
  });

  const localeErrorHandling = (error: Error) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }
  };

  return (
    <MsalProvider instance={instance}>
      <IntlProvider locale={intl.locale} defaultLocale={intl.defaultLocale} fallbackOnEmptyString={true} messages={intl.messages} onError={localeErrorHandling}>
        <Router>
          <ToastProvider>
            <InstallInfo />
            <AutoUpdater />
            <AutoOfflineSync />
            <SyncStatusToasts />
            <AutoReleaseInfo />
            <div className="print:hidden min-h-screen flex flex-col bg-gray-25">
              <Header />
              <Switch>
                <Route exact path="/" render={() => <Redirect to={`/dialsetting${window.location.hash}`} />} />
                <Route path="/notifications">
                  <Notifications />
                </Route>
                <Route path={['/dialsetting']}>
                  <DialSetting />
                </Route>
                <Route path="/calculation/:id">
                  <AuthenticatedContainer>
                    <DialSetting />
                  </AuthenticatedContainer>
                </Route>
              </Switch>
              <Footer />
              <Shortcuts />
            </div>
            <div className="hidden print:block">
              <PrintForm />
            </div>
          </ToastProvider>
        </Router>
      </IntlProvider>
    </MsalProvider>
  );
}

const AutoUpdater = () => {
  const input = useAppSelector((state) => state.input);
  const result = useAppSelector((state) => state.result);
  const { updateWaiting, handleUpdate } = useSWUpdate();

  useEffect(() => {
    if (updateWaiting) {
      addCalculationStateToStorage(input, result.result!);
      handleUpdate();
    }
  }, [updateWaiting, handleUpdate, input, result.result]);

  return <></>;
};

export default App;
