import { Grid, GridItem } from '@skf-internal/ui-components-react-legacy';
import { LubricatorSetting, LubricatorSettingFormatter, LubricatorSettingImage } from 'domain/Lubricator';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import SettingsImage from './SettingsImage';
import Warnings from './Warnings';
import { ResultWithUnits } from './ResultConversion';
import formatString from 'utils/strings/formatString';
import InfoBoxIndicated from 'components/layouts/InfoBoxIndicated';
import ResultNumbers from './ResultNumbers';
import PrintButton from './PrintButton';
import SaveNotificationButton from 'features/Notifications/Actions/SaveNotificationButton';

const ResultNumber = ({ value, header, unit }: { value: number | string; header: string; unit: string }) => {
  return (
    <div className="flex flex-row uppercase text-lg md:text-base justify-between items-end">
      <div className="font-bold grow">{header}</div>
      <div className="text-right flex-none">
        {value} <span className="normal-case text-base">{unit}</span>
      </div>
    </div>
  );
};

const ResultTable = ({ result }: { result: ResultWithUnits | undefined }) => {
  const intl = useIntl();

  const formatSettingMessage = (setting: LubricatorSetting | undefined): string => {
    if (setting && (setting as LubricatorSettingFormatter).formattingValues) {
      return formatString(
        intl.formatMessage({ id: setting.text }),
        (setting as LubricatorSettingFormatter).formattingValues.map((n) => n.toString())
      );
    } else if (setting && setting.text) {
      return intl.formatMessage({ id: setting.text });
    } else {
      return '-';
    }
  };

  return (
    <>
      <ResultNumbers result={result} NumberComponent={ResultNumber} langKey="desktop" />
      <div className="bg-primary-600 p-3 rounded-lg text-white text-center text-lg mt-2">{formatSettingMessage(result?.lubricatorSetting)}</div>
    </>
  );
};

const ResultDesktop = ({ result }: { result: ResultWithUnits | undefined }) => {
  const [hideResult, setHideResult] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (result) {
      setHideResult(result.hideResult ? true : false);
      if ((result.lubricatorSetting as LubricatorSettingImage).imageRef) {
        setHideImage(false);
      } else {
        setHideImage(true);
      }
    }
  }, [result]);

  return (
    <div className="hidden sm:block h-full">
      <InfoBoxIndicated
        className="flex flex-col h-result"
        header={intl.formatMessage({ id: 'result.header' })}
        indicationText={intl.formatMessage({ id: 'result.header.indication' })}
      >
        {hideResult ? (
          <div className="bg-warning-500 p-4 rounded shadow max-h-96">
            <Warnings textClassName="font-bold" />
          </div>
        ) : null}
        {!hideImage ? (
          <Grid className="items-center grow-0 shrink">
            <GridItem feColspan={{ desktop: 7, tablet: 8 }}>
              <ResultTable result={result} />
            </GridItem>
            <GridItem feColspan={{ desktop: 5, tablet: 4 }}>
              <SettingsImage className="max-w-full h-56 mx-auto my-auto" />
            </GridItem>
          </Grid>
        ) : (
          <div className="flex justify-center grow-0 shrink">
            <div className="w-full my-8 lg:px-32">
              <ResultTable result={result} />
            </div>
          </div>
        )}
        {!hideResult ? (
          <div className="mt-4 text-sm grow shrink overflow-y-auto">
            <Warnings />
          </div>
        ) : null}
        <PrintButton />
        <SaveNotificationButton />
      </InfoBoxIndicated>
    </div>
  );
};

export default ResultDesktop;
