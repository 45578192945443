import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import PrintHeader from './layout/PrintHeader';
import PrintKeyValue from './layout/PrintKeyValue';
import PrintRow from './layout/PrintRow';

const PrintLubricantAndGreaseSelection = () => {
  const intl = useIntl();

  const currentGrease = useAppSelector((state) => state.input.grease);
  const currentLubricator = useAppSelector((state) => state.input.lubricator);

  const [greaseName, setGreaseName] = useState('');

  useEffect(() => {
    if (currentGrease) {
      if (currentGrease.name) {
        setGreaseName(intl.formatMessage({ id: currentGrease.name }));
      } else {
        setGreaseName(currentGrease.id);
      }
    }
  }, [currentGrease, intl]);

  return (
    <div className="">
      <PrintHeader header="lubrication.header" />
      <PrintRow>
        <div>
          <PrintKeyValue header="lubrication.lubricatorSelect.label" value={currentLubricator.displayName} />
          <PrintKeyValue header="lubrication.greaseSelect.label" value={greaseName} />
        </div>
        <div></div>
      </PrintRow>
    </div>
  );
};

export default PrintLubricantAndGreaseSelection;
