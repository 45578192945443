import { BearingDimensions } from 'domain/BearingDimensions';
import { BearingBase, BearingType, BearingTypeCollection } from 'domain/BearingType';
import { ConditionChoice, Conditions } from 'domain/Conditions';
import { Input } from 'domain/Input';
import { StoredInput } from 'domain/StoredCalculation';
import { isBearingCollection } from 'services/LocalData/BearingTypeLoader';
import treeSearch from 'utils/data/treeSearch';
import { getDefaults } from 'services/Calculation/CalculationService';
import { Grease } from 'domain/Grease';
import { Lubricator } from 'domain/Lubricator';

const createInputFromStored = (
  input: StoredInput,
  bearings: Array<BearingBase>,
  conditions: Conditions,
  greases: Grease[],
  lubricators: Lubricator[]
): Input => {
  const defaultInput = getDefaults(conditions);
  return {
    bearingType: getBearingType(input.bearingTypeId, bearings),
    dimensions: input.dimensions as BearingDimensions,
    speed: input.speed,
    hours: input.hours,
    temperature: getCondition(input.temperature, conditions.temperatures, defaultInput.temperature),
    contamination: getCondition(input.contamination, conditions.contamination, defaultInput.contamination),
    load: getCondition(input.load, conditions.load, defaultInput.load),
    shockLoad: getCondition(input.shockLoad, conditions.shockLoad, defaultInput.shockLoad),
    ambientTemperature: getCondition(input.ambientTemperature, conditions.ambientTemperature, defaultInput.ambientTemperature),
    shaftOrientation: getCondition(input.shaftOrientation, conditions.shaftOrientation, defaultInput.shaftOrientation),
    replenishment: getCondition(input.replenishment, conditions.replenishment, defaultInput.replenishment),
    rotatingOuterRing: getCondition(input.rotatingOuterRing, conditions.rotatingOuterRing, defaultInput.rotatingOuterRing),
    grease: getGrease(input.greaseId, greases, defaultInput.grease),
    lubricator: getLubricator(input.lubricatorId, lubricators, defaultInput.lubricator),
    manualBearingSelection: input.manualBearingSelection,
    bearingDesignation: input.bearingDesignation
  } as Input;
};

export const getCondition = <TValue>(value: TValue, conditions: ConditionChoice<TValue>[], defaultValue: ConditionChoice<TValue>): ConditionChoice<TValue> => {
  const found = conditions.find((c) => c.value === value);
  return found ? found : defaultValue;
};

export const getGrease = (greaseId: string, greases: Grease[], defaultValue: Grease): Grease => {
  const found = greases.find((g) => g.id === greaseId);
  return found ? found : defaultValue;
};

export const getLubricator = (lubricatorId: string, lubricators: Lubricator[], defaultValue: Lubricator): Lubricator => {
  const found = lubricators.find((l) => l.id === lubricatorId);
  return found ? found : defaultValue;
};

export const getBearingType = (bearingTypeId: string, bearings: BearingBase[]): BearingType => {
  const result = treeSearch<BearingBase>(
    bearings,
    (b) => b.id === bearingTypeId,
    (b) => (isBearingCollection(b) ? (b as BearingTypeCollection).subTypes : [])
  );
  return result?.item as BearingType;
};

export default createInputFromStored;
