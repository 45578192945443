import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Lubricator } from 'domain/Lubricator';
import { Result } from 'domain/Result';
import { InputState } from 'store/InputSlice';
import calculate from 'services/Calculation/CalculationService';
import { DataState } from 'store/DataSlice';

type ResultState = {
  lubricator?: Lubricator;
  result?: Result;
};

type CalculateInput = {
  input: InputState;
  data: DataState;
};

const initialState: ResultState = {};

export const calculateResult = createAsyncThunk(
  'result/calculate',
  async ({ input, data }: CalculateInput, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      return await calculate(input, data.conditions, data.greases, data.lubricators);
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const resultSlice = createSlice({
  name: 'result',
  initialState: initialState,
  reducers: {
    setLubricator: (state: ResultState, action: PayloadAction<Lubricator>) => {
      state.lubricator = action.payload;
    },
    setResult: (state: ResultState, action: PayloadAction<Result>) => {
      state.result = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(calculateResult.fulfilled, (state, action: PayloadAction<Result>) => {
      state.result = action.payload;
    });
    builder.addCase(calculateResult.rejected, (state, action) => {
      console.log(action.error);
      state.result = { hideResult: true } as Result;
    });
  }
});

export const { setLubricator, setResult } = resultSlice.actions;
export default resultSlice.reducer;
