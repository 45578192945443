import DialSetLogo from 'components/svgs/DialSetLogo';
import SvgSkfLogo from 'components/svgs/SkfLogo';
import { Link } from 'react-router-dom';
import Navigation from 'components/layouts/Navigation';
import UserNavigationDesktop from 'features/Authentication/UserNavigationDesktop';

const Header = () => {
  return (
    <div className="fixed header top-0 sm:bottom-auto sm:top-0 w-screen bg-primary-600 z-10">
      <div className="flex items-center h-14 w-full mx-auto sm:max-w-7xl sm:px-4 justify-between">
        <Link className="flex pl-4 sm:pl-0" to="/">
          <SvgSkfLogo className="mr-4 " />
          <DialSetLogo className="border-l pl-4 my-auto" />
        </Link>
        <UserNavigationDesktop />
        <div className="h-full pr-0">
          <Navigation />
        </div>
      </div>
    </div>
  );
};

export default Header;
