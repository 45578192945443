export interface BearingTypeScale {
  low: number;
  moderate: number;
  high: number;
}

export interface BearingTypeExtendedScale extends BearingTypeScale {
  veryLow: number;
  max: number;
}

export enum AdditionalOption {
  replenishment = 'replenishment'
}
export interface BearingBase {
  id: string;
  name: string;
  description: string;
  additionalOptions?: AdditionalOption[];
}

export interface BearingType extends BearingBase {
  image: string;
  bearingFactor: number;
  loadLimit: BearingTypeScale;
  fullCompNoCage: boolean;
  oilLubeWarning: boolean;
  crbHalfFactor: boolean;
  thrustFullCompliment: boolean;
  speedLimit: BearingTypeExtendedScale;
}

export interface BearingTypeCollection extends BearingBase {
  subTypes: Array<BearingBase>;
  subTypeHeader: string;
  subTypeDescription: string;
}
