import { useIntl } from 'react-intl';
import ShortDate, { ShortDateProps } from './ShortDate';

export type ShortDateWithTextProps = {
  titleid: string;
  className?: string;
} & ShortDateProps;

const ShortDateWithText = ({ titleid, className, date, fallBack }: ShortDateWithTextProps) => {
  const intl = useIntl();
  return date ? (
    <p className={className}>
      {intl.formatMessage({ id: titleid })} <ShortDate date={date} fallBack={fallBack} />
    </p>
  ) : (
    <></>
  );
};

export default ShortDateWithText;
