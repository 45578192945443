import { Icons } from '@skf-internal/ui-components-react-legacy';
import ShortDateWithText from 'components/layouts/ShortDateWithText';
import { Notification } from 'domain/Notification';
import Panel from './Panel';

const Info = ({ notification }: { notification: Notification }) => {
  return (
    <Panel header={notification.name} icon={Icons.Drop} truncate largeText>
      <ShortDateWithText className="text-s sm:text-normal" date={notification.reminder.installationDate} titleid="usercalculations.view.installationDate" />
      <ShortDateWithText className="text-s sm:text-normal" date={notification.reminder.replenishmentDate} titleid="usercalculations.view.replenishmentDate" />
      {notification.reminder.hasReminder && (
        <ShortDateWithText className="text-s sm:text-normal" date={notification.reminder.notificationDate} titleid="usercalculations.view.notificationDate" />
      )}
    </Panel>
  );
};

export default Info;
