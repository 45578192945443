import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

export enum AppLocations {
  calculation = '/calculation/:id',
  dialsetting = '/dialsetting'
}

const useIsAppLocation = (locations: AppLocations[]) => {
  const [isAppLocation, setIsAppLocation] = useState(false);
  const match = useRouteMatch(locations.map((l) => l));

  useEffect(() => {
    if (match !== null) {
      setIsAppLocation(true);
    } else {
      setIsAppLocation(false);
    }
  }, [match]);

  return isAppLocation;
};

export default useIsAppLocation;
