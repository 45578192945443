export interface Intl {
  defaultLocale: string;
  locale: string;
  messages: {};
  fallbackMessages: {} | null;
}

export enum Locale {
  english = 'en',
  french = 'fr',
  czech = 'cs',
  romania = 'ro',
  japanese = 'ja',
  hungarian = 'hu',
  polish = 'pl',
  german = 'de',
  spanish = 'es',
  italian = 'it',
  portuguese = 'pt',
  swedish = 'sv',
  thai = 'th',
  chineseSimplified = 'zh-CN'
}

export enum LanguageLabels {
  english = 'English',
  french = 'Français',
  czech = 'Čeština',
  romania = 'Română',
  japanese = '日本語',
  hungarian = 'Magyar',
  polish = 'Polska',
  german = 'Deutsch',
  spanish = 'Español',
  italian = 'Italiano',
  portuguese = 'Português',
  swedish = 'Svenska',
  thai = 'แบบไทย',
  chineseSimplified = '中國'
}

export const getDefaultIntl = (): Intl => {
  return {
    defaultLocale: Locale.english,
    locale: Locale.english,
    messages: getMessages(Locale.english),
    fallbackMessages: null
  };
};

export const getIntl = (locale: Locale): Intl => {
  return {
    defaultLocale: Locale.english,
    locale: locale,
    messages: getMessages(locale),
    fallbackMessages: locale === Locale.english ? null : getMessages(Locale.english)
  };
};

export const getMessages = (locale: Locale) => {
  return mergeMessages(getTranslationForLocale(Locale.english), getTranslationForLocale(locale));
};

export const mergeMessages = (messages1: Record<string, string>, messages2: Record<string, string>): Record<string, string> => {
  return { ...messages1, ...messages2 };
};

export const getTranslationForLocale = (locale: Locale): Record<string, string> => {
  const key = locale.replace('-', '_');

  let translations = {};

  translations = { ...translations, ...filterMessages(require(`assets/frontend/${key}.json`)) };
  translations = { ...translations, ...filterMessages(require(`assets/data/translations/${key}.json`)) };

  return translations;
};

export const filterMessages = (messages: Record<string, string>): Record<string, string> => {
  return Object.keys(messages).reduce((acc, key) => {
    if (messages[key]) {
      acc[key] = messages[key];
    }
    return acc;
  }, {} as Record<string, string>);
};
