import axios from 'axios';

export interface BearingResult {
  count: number;
  bearings: Bearing[];
}

export interface Bearing {
  designation: string;
  innerDiameter: number;
  outerDiameter: number;
  width: number;
  type: string;
}

type ApiConfig = {
  baseUrl: string;
  xApiKey: string;
};

const config = {
  baseUrl: process.env.REACT_APP_BEARING_BASE_URL,
  xApiKey: process.env.REACT_APP_BEARING_API_KEY
} as ApiConfig;

export const getBearings = async (designation: string): Promise<BearingResult> => {
  const response = await axios.get(config.baseUrl + encodeURIComponent(designation), { headers: { 'x-api-key': config.xApiKey } });
  return response.data;
};

export const compareBearingTypes = (type1: string, type2: string): boolean => {
  if (
    (type1.toLocaleLowerCase() === 'carb' && type2.toLocaleLowerCase() === 'ctrb') ||
    (type2.toLocaleLowerCase() === 'carb' && type1.toLocaleLowerCase() === 'ctrb')
  ) {
    return true;
  } else {
    return type1.toLocaleLowerCase() === type2.toLocaleLowerCase();
  }
};

export const bearingTypeExist = (types: string[], type: string): boolean => {
  return types.some((t) => compareBearingTypes(t, type));
};
