import { BearingType } from './BearingType';
import { Grease } from './Grease';
import { Lubricator } from './Lubricator';

export interface ConditionLimit {
  minimum: number;
  default: number;
  maximum: number;
}

export enum ConditionLevel {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
  veryhigh = 'veryhigh'
}

export enum Unit {
  metric = 'metric',
  imperial = 'imperial'
}

export interface ConditionChoice<TChoiceValue> {
  value: TChoiceValue;
  label: string;
  description: string;
}

export interface Warnings {
  cageBearing: string;
  contamination: string;
  highAmbientTemperature: string;
  lowAmbientTemperature: string;
  lowSpeed: string;
  lubeInterval: string;
  oilLube: string;
  outerRingRotation: string;
  vertShaft: string;
  badRelubricationInterval: string;
  centralizedLubrication: string;
  highFeedRateMessage: string;
  highFeedRateRecommendation: string;
  highFeedRateOtherRecommendation: string;
  lowFeedRateMessage: string;
  lowFeedRateRecommendation: string;
  lowFeedRateOtherRecommendation: string;
  tooLowSpeed: string;
  ambientTemperature: string;
  machineSteering: string;
  highFeedrateTLMP: string;
  lowFeedrateTLMP: string;
  lgfp2DicontinuedWarning: string;
  tlsdLgwm2DicontinuedWarning: string;
  greaseUnspecifiedWarning: string;
}

export interface ValidationMessage {
  message: string;
  warningMessage: string;
}
export interface Validations {
  dimensions: ValidationMessage;
}

export interface Conditions {
  speed: ConditionLimit;
  bearingInnerDiameter: ConditionLimit;
  bearingOuterDiameter: ConditionLimit;
  bearingWidthDiameter: ConditionLimit;
  temperatures: Array<ConditionChoice<ConditionLevel>>;
  contamination: Array<ConditionChoice<number>>;
  load: Array<ConditionChoice<ConditionLevel>>;
  shockLoad: Array<ConditionChoice<boolean>>;
  ambientTemperature: Array<ConditionChoice<number>>;
  shaftOrientation: Array<ConditionChoice<number>>;
  replenishment: Array<ConditionChoice<number>>;
  rotatingOuterRing: Array<ConditionChoice<boolean>>;
  defaultBearing: BearingType;
  defaultGrease: Grease;
  defaultLubricator: Lubricator;
  warnings: Warnings;
  unit: Unit;
  validations: Validations;
}
