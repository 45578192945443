import { Grid, GridItem } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import GreaseWarning from './GreaseWarning';
import LubricatorImage from './LubricatorImage';
import LubricatorShop from './LubricatorShop';

const LubricatorDescription = () => {
  const selectedLubricator = useAppSelector((state) => state.input.lubricator);
  const connected = useAppSelector((state) => state.setting.connected);
  const intl = useIntl();

  return selectedLubricator ? (
    <div className="mt-4 flex flex-col w-full content-center">
      <Grid className="mb-4">
        <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
          <GreaseWarning />
        </GridItem>
        <GridItem feColspan={{ desktop: 6, tablet: 6, mobile: 12 }}>
          <div className="w-full flex flex-col justify-center">
            {connected ? (
              <>
                <a href={selectedLubricator.url} className="mx-auto" rel="noreferrer" target="_blank">
                  <LubricatorImage className="w-60" />
                </a>
                <LubricatorShop />
              </>
            ) : (
              <LubricatorImage className="mx-auto w-60" />
            )}
          </div>
        </GridItem>
      </Grid>
      <span>
        {intl
          .formatMessage({ id: selectedLubricator.description })
          .split('#')
          .map((p, i) => (
            <p key={i} className="text-sm text-black pb-2">
              {p}
            </p>
          ))}
      </span>
    </div>
  ) : (
    <></>
  );
};

export default LubricatorDescription;
