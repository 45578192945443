import { addDays, differenceInDays, subMilliseconds } from 'date-fns';
import { Notification } from 'domain/Notification';
import { daysBefore, getCurrentNotificationDate, getDateFromString, getExpirationDate } from 'services/Notifications/dateCalculations';

export interface NotificationState {
  name: string;
  installationDate: Date;
  replenishmentDate: Date;
  notificationDate: Date;
  notificationDateOptions: { daysBefore: number; disabled: boolean }[];
  selectedNotificationDays: number;
  hasReminder: boolean;
  settingInterval: number;
  notifications: Notification[];
  id?: string;
  isTLMP: boolean;
  validation: {
    isValid: boolean;
    errors: {
      name: boolean;
      notificationDate: boolean;
      nameUnique: boolean;
    };
  };
  validated: boolean;
  installationDateWarning: boolean;
  installationDateMinDate: { minDate: Date } | {};
  notificationDateWarning: boolean;
  notificationDateDaysBefore: number;
  notificationDateDaysRange: { minDate: Date; maxDate: Date } | {};
  lastInstallationDate?: Date;
}

export const getDefaultState = (currentInterval: number, notifications: Notification[]): NotificationState => {
  return {
    name: '',
    validation: {
      isValid: false,
      errors: {
        name: false,
        notificationDate: false
      }
    },
    validated: false,
    installationDate: new Date(),
    selectedNotificationDays: 7,
    notificationDateOptions: [] as { daysBefore: number; disabled: boolean }[],
    replenishmentDate: getExpirationDate(currentInterval, new Date()),
    notificationDate: getCurrentNotificationDate(currentInterval, new Date()),
    notifications: notifications,
    hasReminder: false
  } as NotificationState;
};

export enum NotificationActionKind {
  SET_NAME = 'SET_NAME',
  SET_HASREMINDER = 'SET_HASREMINDER',
  SET_INSTALLATIONDATE = 'SET_INSTALLATIONDATE',
  SET_NOTIFICATIONDATE = 'SET_NOTIFICATIONDATE',
  SET_NOTIFICATIONDAYS = 'SET_NOTIFICATIONDAYS',
  SET_INITIAL = 'SET_INITIAL',
  RESET = 'RESET',
  SET_CURRENTINTERVALANDTLMP = 'SET_CURRENTINTERVALANDTLMP',
  VALIDATE = 'VALIDATE',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
}

export interface NotificationAction {
  type: NotificationActionKind;
  payload: any;
}

export const notificationReducer = (state: NotificationState, action: NotificationAction): NotificationState => {
  const { type, payload } = action;
  switch (type) {
    case NotificationActionKind.SET_INITIAL:
      return applyEffects({
        ...state,
        name: action.payload.name,
        installationDate: getDateFromString(action.payload.reminder.installationDate),
        replenishmentDate: getDateFromString(action.payload.reminder.replenishmentDate),
        notificationDate: getDateFromString(action.payload.reminder.notificationDate),
        selectedNotificationDays: action.payload.reminder.notificationDate
          ? differenceInDays(getDateFromString(action.payload.reminder.replenishmentDate), getDateFromString(action.payload.reminder.notificationDate))
          : 7,
        hasReminder: action.payload.reminder.hasReminder,
        settingInterval: action.payload.settingInterval,
        isTLMP: action.payload.isTLMP,
        id: action.payload.id,
        validated: true,
        lastInstallationDate: action.payload.lastInstallationDate ? getDateFromString(action.payload.lastInstallationDate) : undefined
      });
    case NotificationActionKind.SET_CURRENTINTERVALANDTLMP:
      return applyEffects({
        ...state,
        ...payload,
        replenishmentDate: getExpirationDate(payload.settingInterval, new Date()),
        notificationDate: getCurrentNotificationDate(payload.settingInterval, new Date())
      });
    case NotificationActionKind.RESET:
      return applyEffects({
        ...state,
        name: '',
        installationDate: new Date(),
        replenishmentDate: getExpirationDate(state.settingInterval, new Date()),
        notificationDate: addDays(getCurrentNotificationDate(state.settingInterval, payload), -7),
        selectedNotificationDays: 7,
        hasReminder: false
      });
    case NotificationActionKind.SET_NAME:
      return applyEffects({
        ...state,
        name: payload
      });
    case NotificationActionKind.SET_HASREMINDER:
      if (payload) {
        if (state.installationDate < subMilliseconds(new Date(), state.settingInterval)) {
          return applyEffects({
            ...state,
            installationDate: new Date(),
            replenishmentDate: getExpirationDate(state.settingInterval, new Date()),
            notificationDate: getCurrentNotificationDate(state.settingInterval, new Date()),
            hasReminder: payload
          });
        }
      }
      return applyEffects({
        ...state,
        notificationDate: getCurrentNotificationDate(state.settingInterval, state.installationDate),
        hasReminder: payload
      });
    case NotificationActionKind.SET_NOTIFICATIONDATE:
      return applyEffects({
        ...state,
        notificationDate: payload
      });
    case NotificationActionKind.SET_NOTIFICATIONDAYS:
      return applyEffects({
        ...state,
        notificationDate: addDays(state.replenishmentDate, -payload),
        selectedNotificationDays: payload
      });
    case NotificationActionKind.SET_INSTALLATIONDATE:
      return applyEffects({
        ...state,
        installationDate: payload,
        replenishmentDate: getExpirationDate(state.settingInterval, payload),
        notificationDate: addDays(getExpirationDate(state.settingInterval, payload), -state.selectedNotificationDays)
      });
    case NotificationActionKind.VALIDATE:
      return applyValidations({
        ...state,
        validated: true
      });
    case NotificationActionKind.SET_NOTIFICATIONS:
      return applyValidations({
        ...state,
        notifications: payload
      });
    default:
      return state;
  }
};

const applyEffects = (state: NotificationState) => {
  return applyValidations(applyDateWarnings(applyNotificationOptions(state)));
};

const applyNotificationOptions = (state: NotificationState) => {
  const options = [1, 7, 14, 28, 42, 56].map((numberOfDaysBefore: number) => ({
    daysBefore: numberOfDaysBefore,
    disabled: addDays(state.replenishmentDate, -numberOfDaysBefore) < new Date()
  }));
  const bestOption = [...options]
    .reverse()
    .find((o) => !o.disabled && (o.daysBefore === state.selectedNotificationDays || o.daysBefore < state.selectedNotificationDays));

  return {
    ...state,
    notificationDateOptions: options,
    selectedNotificationDays: bestOption ? bestOption.daysBefore : state.selectedNotificationDays,
    notificationDate: addDays(getExpirationDate(state.settingInterval, state.installationDate), -state.selectedNotificationDays)
  };
};

const applyDateWarnings = (state: NotificationState) => {
  return {
    ...state,
    installationDateWarning: state.installationDate < subMilliseconds(new Date(), state.settingInterval),
    installationDateMinDate: state.hasReminder
      ? state.lastInstallationDate
        ? { minDate: addDays(state.lastInstallationDate, 1) }
        : { minDate: addDays(subMilliseconds(new Date(), state.settingInterval), 1) }
      : state.lastInstallationDate
      ? { minDate: addDays(state.lastInstallationDate, 1) }
      : {},
    notificationDateWarning: daysBefore(state.replenishmentDate, state.notificationDate) < 4,
    notificationDateDaysBefore: daysBefore(state.replenishmentDate, state.notificationDate),
    notificationDateDaysRange: { minDate: addDays(new Date(), 1), maxDate: state.replenishmentDate }
  };
};

const applyValidations = (state: NotificationState) => {
  const validationErrors = {
    name: state.name ? false : true,
    nameUnique:
      state.notifications && state.name
        ? state.notifications.some((n) => n.name.toLocaleLowerCase() === state.name.toLocaleLowerCase() && state.id !== n.id)
        : false,
    notificationDate: state.hasReminder ? state.notificationDate < addDays(new Date(), -1) : false
  };
  return {
    ...state,
    validation: {
      ...state.validation,
      isValid: !(validationErrors.name || validationErrors.notificationDate || validationErrors.nameUnique),
      errors: validationErrors
    }
  };
};
