import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setGrease } from 'store/InputSlice';
import GreaseRadioGroup from './GreaseRadioGroup';

const GreaseSelection = () => {
  const availableGreases = useAppSelector((state) => state.result.result?.availableGreases);
  const allGreases = useAppSelector((state) => state.data.greases);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);
  const currentGrease = useAppSelector((state) => state.input.grease);

  const [selectedGrease, setSelectedGrease] = useState('');
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    setSelectedGrease(currentGrease.id);
  }, [currentGrease]);

  useEffect(() => {
    if (availableGreases) {
      if (!availableGreases.some((ag) => ag.grease.id === selectedGrease && !ag.isDisabled)) {
        setSelectedGrease('Unspecified');
        const found = allGreases.find((g) => g.id === 'Unspecified');
        if (found) {
          dispatch(setGrease(found));
        }
      }
    }
  }, [availableGreases, selectedGrease, allGreases, dispatch]);

  const onChangeGrease = (checked: boolean, value: string | number) => {
    const found = allGreases.find((g) => g.id === value);
    if (found) {
      dispatch(setGrease(found));
    }
  };

  return availableGreases ? (
    <>
      <div className="mt-4 mb-4 flex flex-wrap">
        <label className="w-full">{intl.formatMessage({ id: 'lubrication.greaseSelect.label' })}</label>
        <GreaseRadioGroup disabled={isDisabled} onChangeGrease={onChangeGrease} greases={availableGreases} selectedGrease={selectedGrease} />
      </div>
    </>
  ) : (
    <></>
  );
};

export default GreaseSelection;
