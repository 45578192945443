import InteruptiveDialog from 'components/layouts/InteruptiveDialog';
import { useIntl } from 'react-intl';
import useLoginRedirect from './useLoginRedirect';

const AuthenticationDialog = ({ isOpen, onClose, header }: { isOpen: boolean; onClose: () => void; header: string }) => {
  const intl = useIntl();

  const { redirect } = useLoginRedirect();

  const onLogin = () => {
    redirect();
  };

  return (
    <InteruptiveDialog
      buttons={{
        primary: { value: intl.formatMessage({ id: 'authentication.login' }), onClick: onLogin },
        secondary: { value: intl.formatMessage({ id: 'usercalculations.cancelbutton' }), onClick: onClose }
      }}
      open={isOpen}
      title={header}
      OnClose={onClose}
    >
      <p>{intl.formatMessage({ id: 'authentication.dialog.text' })}</p>
    </InteruptiveDialog>
  );
};

export default AuthenticationDialog;
