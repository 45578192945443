import { AccountInfo } from '@azure/msal-browser';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AccountState = {
  isAuthenticated?: boolean;
  account: AccountInfo | null;
};

const initialState: AccountState = {
  account: null
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: initialState,
  reducers: {
    login: (state: AccountState, action: PayloadAction<AccountInfo | null>) => {
      if (action.payload) {
        state.isAuthenticated = true;
        state.account = action.payload;
      } else {
        state.isAuthenticated = false;
        state.account = null;
      }
    },
    logout: (state: AccountState, action: PayloadAction) => {
      state.isAuthenticated = false;
      state.account = null;
    }
  }
});

export const { login, logout } = accountSlice.actions;
export default accountSlice.reducer;
