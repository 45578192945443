import { DatePicker, Locales, MediaQueries, Select, Switch, Text, TextField, useMediaQuery } from '@skf-internal/ui-components-react-legacy';
import { useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { Col, FormRow } from 'components/layouts/FormGrid';
import { Notification } from 'domain/Notification';
import { getLocalDateString } from 'services/Notifications/dateCalculations';
import { useAppSelector } from 'store/hooks';
import useGeoLocation from 'utils/hooks/useGeoLocation';
import { getDefaultState, NotificationActionKind, notificationReducer, NotificationState } from './NotificationState';
import InteruptiveDialog from 'components/layouts/InteruptiveDialog';

export interface DialogData {
  name: string;
  installationDate: Date;
  replenishmentDate: Date;
  notificationDate?: Date;
  hasReminder: boolean;
  settingInterval: number;
  isTLMP: boolean;
}

type NotificationDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (input: DialogData) => void;
  existingNotification?: Notification;
  isTLMP: boolean;
  currentInterval: number;
  hideName?: boolean;
  lastInstallationDate?: string;
  header: string;
  saveButtonText: string;
  text?: string;
  footerText?: string;
};

const NotificationDialog = ({
  isOpen,
  onClose,
  onSave,
  existingNotification,
  isTLMP,
  currentInterval,
  hideName,
  lastInstallationDate,
  header,
  saveButtonText,
  text,
  footerText
}: NotificationDialogProps) => {
  const intl = useIntl();
  const isMobile = useMediaQuery(MediaQueries.MobileMax);
  const locale = useAppSelector((state) => state.setting.intl.locale);
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const geoLocation = useGeoLocation();

  const [state, dispatch] = useReducer(notificationReducer, getDefaultState(currentInterval, notifications));

  useEffect(() => {
    dispatch({ type: NotificationActionKind.SET_NOTIFICATIONS, payload: notifications });
  }, [notifications]);

  useEffect(() => {
    if (existingNotification) {
      dispatch({ type: NotificationActionKind.SET_INITIAL, payload: { ...existingNotification, lastInstallationDate } });
    }
  }, [existingNotification, lastInstallationDate]);

  useEffect(() => {
    if (currentInterval) {
      dispatch({ type: NotificationActionKind.SET_CURRENTINTERVALANDTLMP, payload: { settingInterval: currentInterval, isTLMP } });
    }
  }, [currentInterval, isTLMP]);

  const onSaveButtonSave = () => {
    if (state.validation.isValid) {
      onSave({
        name: state.name,
        installationDate: state.installationDate,
        replenishmentDate: state.replenishmentDate,
        notificationDate: state.hasReminder ? state.notificationDate : undefined,
        hasReminder: state.hasReminder,
        settingInterval: state.settingInterval,
        isTLMP: state.isTLMP
      });
      onClose();
    } else {
      dispatch({ type: NotificationActionKind.VALIDATE, payload: {} });
    }
  };

  const onDialogClose = () => {
    onClose();
    if (existingNotification) {
      dispatch({ type: NotificationActionKind.SET_INITIAL, payload: existingNotification });
    } else {
      dispatch({ type: NotificationActionKind.RESET, payload: {} });
    }
  };

  const getDialogHeight = () => {
    return 'auto';
  };

  const getDialogWidth = () => {
    if (!isMobile) {
      return '540px';
    }
  };

  const isNameInputInValid = (state: NotificationState) => {
    return state.validated && (state.validation.errors.name || state.validation.errors.nameUnique);
  };

  const getNameInputValidationMessage = (state: NotificationState) => {
    if (state.validated) {
      if (state.validation.errors.name) {
        return intl.formatMessage({ id: 'usercalculations.add.name.emptyError' });
      } else if (state.validation.errors.nameUnique) {
        return intl.formatMessage({ id: 'usercalculations.add.name.uniqueError' });
      }
    }
    return '';
  };

  return (
    <InteruptiveDialog
      cssHeight={getDialogHeight()}
      cssWidth={getDialogWidth()}
      buttons={{
        primary: { value: saveButtonText, onClick: onSaveButtonSave },
        secondary: { value: intl.formatMessage({ id: 'usercalculations.cancelbutton' }), onClick: onDialogClose }
      }}
      open={isOpen}
      title={header}
      OnClose={onDialogClose}
    >
      {text && <Text>{text}</Text>}
      {!hideName && (
        <FormRow>
          <Col>
            <TextField
              feLabel={intl.formatMessage({ id: 'usercalculations.add.name' })}
              feHint={getNameInputValidationMessage(state)}
              feSeverity={isNameInputInValid(state) ? 'error' : 'info'}
              value={state.name}
              onChange={(_, value) => dispatch({ type: NotificationActionKind.SET_NAME, payload: value })}
            />
          </Col>
        </FormRow>
      )}
      <FormRow>
        <Col>
          <DatePicker
            locale={Locales[locale]}
            feLabel={intl.formatMessage({ id: 'usercalculations.add.installationDate' })}
            feRange={false}
            value={state.installationDate}
            onChange={(date: Date) => dispatch({ type: NotificationActionKind.SET_INSTALLATIONDATE, payload: date })}
            feSeverity={state.installationDateWarning ? 'warning' : 'info'}
            feReactDatePickerProps={state.installationDateMinDate}
            feHint={intl.formatMessage(
              { id: 'usercalculations.add.replenishmentDate' },
              { date: getLocalDateString(state.replenishmentDate.toISOString(), locale, geoLocation) }
            )}
          />
        </Col>
        <Col>
          <div className="flex flex-col justify-end sm:h-14">
            <Switch
              checked={state.hasReminder}
              onChange={(_event, checked) => dispatch({ type: NotificationActionKind.SET_HASREMINDER, payload: checked })}
              feLabel={intl.formatMessage({ id: 'usercalculations.add.notifications' })}
            />
          </div>
        </Col>
      </FormRow>
      {state.hasReminder ? (
        <FormRow>
          <Col>
            <Select
              id="notificationSelection"
              feLabel={intl.formatMessage({ id: 'usercalculations.add.notifyAt' })}
              value={state.selectedNotificationDays.toString()}
              feItems={state.notificationDateOptions.map((item) => ({
                label: intl.formatMessage({ id: `usercalculations.add.notificationDate.${item.daysBefore}` }),
                value: item.daysBefore.toString(),
                disabled: item.disabled
              }))}
              feHint={intl.formatMessage(
                { id: 'usercalculations.add.notifyReplenishmentDate' },
                { date: getLocalDateString(state.notificationDate.toISOString(), locale, geoLocation) }
              )}
              feSeverity={state.validation.errors.notificationDate ? 'error' : 'info'}
              multiple={false}
              onChange={(value: string) => dispatch({ type: NotificationActionKind.SET_NOTIFICATIONDAYS, payload: parseInt(value) })}
            />
          </Col>
          <Col className="flex flex-col justify-end sm:h-full sm:pb-4">
            {state.isTLMP ? <p>{intl.formatMessage({ id: 'usercalculations.add.tlmpWarning' })}</p> : <></>}
          </Col>
        </FormRow>
      ) : (
        <></>
      )}
      {footerText && (
        <Text as="div" className="mt-4">
          {footerText}
        </Text>
      )}
    </InteruptiveDialog>
  );
};

export default NotificationDialog;
