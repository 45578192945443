import { Unit } from 'domain/Conditions';
import { Cookies } from 'react-cookie';
import { Locale } from 'services/Localization/LocalizationService';

export class CookieService {
  private cookies: Cookies;

  constructor(cookies = new Cookies()) {
    this.cookies = cookies;
  }

  setInstallInfoDismiss() {
    this.setLongTermCookie('installDismissed', true);
  }

  isInstallInfoDismissed() {
    return this.getCookie('installDismissed', false);
  }

  setLanguageCookie(locale: Locale) {
    this.setLongTermCookie('locale', locale);
  }

  getLanguageCookie(): Locale {
    return this.getCookie('locale', Locale.english);
  }

  setUnitCookie(unit: Unit) {
    this.setLongTermCookie('unit', unit);
  }

  getUnitCookie(): Unit {
    return this.getCookie('unit', Unit.metric);
  }

  setIsResultExpandedCookie(isExpanded: boolean) {
    this.setLongTermCookie('isResultExpanded', isExpanded);
  }

  getIsResultExpandedCookie(): boolean {
    const result = this.getCookie('isResultExpanded', false);
    if (result) {
      return result === 'true' ? true : false;
    } else {
      return false;
    }
  }

  dismissReleaseVersion(id: string) {
    this.setLongTermCookie('dismissedReleaseVersion', id);
  }

  isReleaseDismissedCookieSet(id: string): boolean {
    return this.getCookie('dismissedReleaseVersion', '') === id;
  }

  setHaveBeenAuthenticatedCookie() {
    this.setLongTermCookie('hasBeenAuthenticated', true);
  }

  getHaveBeenAuthenticatedCookie(): boolean {
    return this.getCookie('hasBeenAuthenticated', false);
  }

  setLongTermCookie<T>(name: string, value: T) {
    this.cookies.set(name, value, { path: '/', maxAge: 60 * 60 * 24 * 365 });
  }

  getCookie<T>(name: string, defaultValue: T): T {
    const value = this.cookies.get(name);
    if (!value) {
      return defaultValue;
    } else {
      return value as T;
    }
  }
}

export default CookieService;
