import { Flexbox, generateId, RadioProps, Spacings, styled } from '@skf-internal/ui-components-react-legacy';
import InputRadio from '@skf-internal/ui-components-react-legacy/dist/esm/common/components/input-radio/InputRadio';
import Label from '@skf-internal/ui-components-react-legacy/dist/esm/common/components/label/Label';

export interface RadioFieldWhiteProps extends RadioProps {
  labelClassNames?: string;
  containerClassNames?: string;
}

const RadioFieldWhite = ({ disabled, feLabel, feSize, id = generateId(), required, labelClassNames, containerClassNames, ...rest }: RadioFieldWhiteProps) => {
  return (
    <StyledRadioField className={containerClassNames} feAlignItems="center" data-molecule="radio-field">
      <StyledRadio {...rest} disabled={disabled} feSize={feSize} id={id} required={required} />
      <StyledLabel className={labelClassNames} feDisabled={disabled} feRequired={required} feSize={feSize} htmlFor={id}>
        {feLabel}
      </StyledLabel>
    </StyledRadioField>
  );
};

const StyledRadioField = styled(Flexbox)({});
const StyledRadio = styled(InputRadio)`
  flex-shrink: 0;
`;

const StyledLabel = styled(Label)`
  margin-inline-start: ${Spacings.Xs};
  color: #fff;
`;

export default RadioFieldWhite;
