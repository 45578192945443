import { ReactNode } from 'react';

interface InfoBoxProps {
  children: ReactNode;
  header?: string;
  className?: string;
  noPadding?: boolean;
}

const InfoBox = ({ children, header, className, noPadding }: InfoBoxProps) => {
  return (
    <div className="borderbox shadow m-4 sm:m-2 rounded sm:mb-4">
      {header ? <div className="bg-primary-600 rounded-t p-2 text-white font-chevinmedium">{header}</div> : null}
      <div className={(!noPadding ? ' p-2 sm:p-4 ' : '') + (className ? className : '')}>{children}</div>
    </div>
  );
};

export default InfoBox;
