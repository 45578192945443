import { ConfirmInput } from 'domain/Notification';
import { getNumberFromDate } from 'utils/data/dateConversion';

export const createConfirmReminder = (confirmReminder: ConfirmInput) => {
  return {
    installationDate: getNumberFromDate(confirmReminder.installationDate),
    replenishmentDate: getNumberFromDate(confirmReminder.replenishmentDate),
    notificationDate: getNumberFromDate(confirmReminder.notificationDate),
    hasReminder: confirmReminder.hasReminder
  };
};
