import { useIntl } from 'react-intl';
import { appendClassCondition } from 'utils/ui/appendClass';

const MenuButton = ({
  textid,
  disabled,
  onClick,
  href,
  icon
}: {
  textid: string;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  icon: JSX.Element;
}) => {
  const intl = useIntl();

  const applyCursor = (classes: string): string => {
    return appendClassCondition(classes, 'cursor-not-allowed text-gray-400', 'cursor-pointer text-white', disabled);
  };

  const applyTextColor = (classes: string): string => {
    return appendClassCondition(classes, 'text-gray-400', 'text-white', disabled);
  };

  const onLocalClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  if (href && !disabled) {
    return (
      <a onClick={onLocalClick} className={applyCursor('ml-4 mt-2 flex flex-row items-center')} href={href} target="_blank" rel="noreferrer">
        {icon}
        <span className={applyTextColor('uppercase')}>{intl.formatMessage({ id: textid })}</span>
      </a>
    );
  } else {
    return (
      <span className={applyCursor('ml-4 mt-2 flex flex-row items-center')} onClick={onLocalClick}>
        {icon}
        <span className={applyTextColor('uppercase')}>{intl.formatMessage({ id: textid })}</span>
      </span>
    );
  }
};

export default MenuButton;
