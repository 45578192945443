import { createListenerMiddleware } from '@reduxjs/toolkit';
import getNotificationRepository, { getOnlineFirstNotificationRepository } from 'services/Notifications/RepositoryFactory';
import { RootState } from 'store/store';
import { getNotifications, reloadNotifications, silentLoadNotifications } from '.';

const offlineMiddleware = createListenerMiddleware();

offlineMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return (
      (action.type === silentLoadNotifications.fulfilled.type || action.type === getNotifications.fulfilled.type) &&
      ((currentState as RootState).setting.connected ? true : false)
    );
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    for (const notification of action.payload) {
      await getNotificationRepository(true).getCalculation(notification.id);
    }
  }
});

offlineMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    return action.type === reloadNotifications.fulfilled.type;
  },
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();
    for (const notification of action.payload) {
      await getOnlineFirstNotificationRepository().getCalculation(notification.id);
    }
  }
});

export default offlineMiddleware;
