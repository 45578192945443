import { useCallback } from 'react';
import { useAppSelector } from 'store/hooks';
import useIsAppInstalled from 'utils/hooks/useIsAppInstalled';
import { getFirebaseRepositoryFactory } from './FirebaseRepositoryFactory';

const useAnalyticsLog = () => {
  const connected = useAppSelector((state) => state.setting.connected);
  const isAuthenticated = useAppSelector((state) => state.account.isAuthenticated);
  const isAppInstalled = useIsAppInstalled();
  const locale = useAppSelector((state) => state.setting.intl.locale);

  const getEventParams = useCallback(
    (eventParams?: { [key: string]: any }): { [key: string]: any } => {
      if (eventParams) {
        return { ...eventParams, connected, isAuthenticated, isAppInstalled, locale };
      } else {
        return { connected, isAuthenticated, isAppInstalled, locale };
      }
    },
    [connected, isAuthenticated, isAppInstalled, locale]
  );

  const logAppEvent = useCallback(
    (eventName: string, eventParams?: { [key: string]: any }) => {
      const repository = getFirebaseRepositoryFactory(connected);
      repository.logEvent(eventName, getEventParams(eventParams));
    },
    [getEventParams, connected]
  );

  const logPrintResult = useCallback((): void => logAppEvent('result_print'), [logAppEvent]);
  const logSearchSelected = useCallback((designation: string): void => logAppEvent('bearingsearch_select', { designation }), [logAppEvent]);
  const logSearchQuery = useCallback((query: string): void => logAppEvent('bearingsearch_query', { query }), [logAppEvent]);
  const logSearchHistorySelect = useCallback((designation: string): void => logAppEvent('bearingsearch_history_select', { designation }), [logAppEvent]);
  const logPrintManualBearingEntry = useCallback((): void => logAppEvent('result_print_manualbearing'), [logAppEvent]);
  const logChangeUnit = useCallback((unit: string): void => logAppEvent('change_unit', { unit }), [logAppEvent]);
  const logGoingOnlineAfterOffline = useCallback((): void => logAppEvent('going_online_afteroffline'), [logAppEvent]);
  const logGoingOfflineAfterOnline = useCallback((): void => logAppEvent('going_offline_afteronline'), [logAppEvent]);
  const logNavigationCalculation = useCallback((): void => logAppEvent('navigation_calculations'), [logAppEvent]);
  const logNavigationSelectionChart = useCallback((): void => logAppEvent('navigation_selectionChart'), [logAppEvent]);
  const logNavigationSupport = useCallback((): void => logAppEvent('navigation_support'), [logAppEvent]);
  const logNavigationIncidents = useCallback((): void => logAppEvent('navigation_incidents'), [logAppEvent]);
  const logNavigationShopOnline = useCallback((): void => logAppEvent('navigation_shoponline'), [logAppEvent]);
  const logDeviceAdded = useCallback(
    (isDuplicate: boolean, lubricator: string): void => logAppEvent('device_added', { isDuplicate, lubricator }),
    [logAppEvent]
  );
  const logDeviceDuplicate = useCallback((): void => logAppEvent('device_duplicate'), [logAppEvent]);
  const logDeviceUpdated = useCallback((): void => logAppEvent('device_updated'), [logAppEvent]);
  const logDeviceDeleted = useCallback((): void => logAppEvent('device_deleted'), [logAppEvent]);
  const logDeviceReplenished = useCallback(
    (daysUntilExpiration: number): void => logAppEvent('device_replenished', { daysUntilExpiration, isExpired: daysUntilExpiration < 0 }),
    [logAppEvent]
  );
  const logNavigationLogin = useCallback((): void => logAppEvent('navigation_login'), [logAppEvent]);
  const logNavigationLogout = useCallback((): void => logAppEvent('navigation_logout'), [logAppEvent]);
  const logNavigationProfile = useCallback((): void => logAppEvent('navigation_profile'), [logAppEvent]);
  const logSelectLanguage = useCallback((selectedLanguage) => logAppEvent('select_language', { selectedLanguage }), [logAppEvent]);

  return {
    logPrintResult,
    logSearchSelected,
    logSearchQuery,
    logSearchHistorySelect,
    logPrintManualBearingEntry,
    logChangeUnit,
    logGoingOnlineAfterOffline,
    logGoingOfflineAfterOnline,
    logNavigationCalculation,
    logNavigationSelectionChart,
    logNavigationSupport,
    logNavigationIncidents,
    logNavigationShopOnline,
    logDeviceAdded,
    logDeviceDuplicate,
    logDeviceUpdated,
    logDeviceDeleted,
    logDeviceReplenished,
    logNavigationLogin,
    logNavigationLogout,
    logNavigationProfile,
    logSelectLanguage
  };
};

export default useAnalyticsLog;
