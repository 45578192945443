import { createDateStringFromEpoch } from 'utils/data/dateConversion';
import { createReminder } from './createReminder';
import { Notification } from 'domain/Notification';

export const createNotification = (notificationData: any): Notification => {
  return {
    ...notificationData,
    reminder: createReminder(notificationData.reminder),
    createdAt: createDateStringFromEpoch(notificationData.createdAt)
  } as Notification;
};
