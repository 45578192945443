import OneColumnContainer from './OneColumnContainer';

type TwoColumnContainerProps = {
  children: JSX.Element[];
  stickyRight?: boolean;
};

const TwoColumnContainer = ({ children, stickyRight }: TwoColumnContainerProps) => {
  if (children.length === 2) {
    if (stickyRight) {
      return (
        <div>
          <div className="flex w-full justify-center mx-auto sm:max-w-7xl pt-2 relative">
            <div className="w-full">{children[0]}</div>
            <div className="w-full sticky self-start top-20 h-belowScreen">{children[1]}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mb-16 sm:mb-4">
          <div className="flex w-full justify-center mx-auto sm:max-w-7xl pt-2">
            <div className="w-full">{children[0]}</div>
            <div className="w-full fixed bottom-0 mb-11 bg-primary-600 min-h-8 text-gray-25 sm:relative sm:min-h-auto sm:bg-transparent sm:text-black">
              {children[1]}
            </div>
          </div>
        </div>
      );
    }
  } else {
    return <OneColumnContainer children={children} />;
  }
};

export default TwoColumnContainer;
