import { Divider, Icon, IconColors, Icons, IconSizes, MediaQueries, useMediaQuery } from '@skf-internal/ui-components-react-legacy';
import RadioFieldWhite from 'components/forms/RadioFieldWhite';
import LanguageIcon from 'components/svgs/LanguageIcon';
import ManualOpenNewSvg from 'components/svgs/ManualOpenNewSvg';
import { Unit } from 'domain/Conditions';
import Authenticator, { AuthenticatedProps } from 'features/Authentication/Authenticator';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { setUnit } from 'store/DataSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useOuterClick from 'utils/hooks/useOuterClick';
import BurgerMenuButton from './BurgerMenuButton';
import LanguageDialog from './LanguageDialog';
import MenuButton from './MenuButton';
import MenuIcon from './MenuIcon';

const UnitSelector = () => {
  const unit = useAppSelector((state) => state.data.conditions.unit);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { logChangeUnit } = useAnalyticsLog();
  const setMetric = (event: any, checked: boolean) => {
    if (checked) {
      dispatch(setUnit(Unit.metric));
      logChangeUnit(Unit.metric);
    }
  };

  const setImperial = (event: any, checked: boolean) => {
    if (checked) {
      dispatch(setUnit(Unit.imperial));
      logChangeUnit(Unit.imperial);
    }
  };

  return (
    <div className="ml-4 mt-2 flex flex-row">
      <div className="text-white mr-6">{intl.formatMessage({ id: 'units' })}</div>
      <RadioFieldWhite
        id="metricField"
        checked={unit === Unit.metric}
        onChange={setMetric}
        containerClassNames="mr-6"
        name="unit"
        feLabel={intl.formatMessage({ id: 'unit.metric' })}
        feSize="sm"
      />
      <RadioFieldWhite
        id="imperialField"
        checked={unit === Unit.imperial}
        onChange={setImperial}
        name="unit"
        feLabel={intl.formatMessage({ id: 'unit.imperial' })}
        feSize="sm"
      />
    </div>
  );
};

const Navigation = () => {
  const connected = useAppSelector((state) => state.setting.connected);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const isMobile = useMediaQuery(MediaQueries.MobileMax);
  const intl = useIntl();
  const history = useHistory();

  const { logNavigationCalculation, logNavigationIncidents, logNavigationSelectionChart, logNavigationSupport } = useAnalyticsLog();

  const ref = useOuterClick(() => {
    setIsMenuOpen(false);
  });

  const openLanguages = () => {
    setShowLanguages(true);
    setIsMenuOpen(false);
  };
  const closeLanguages = () => {
    setShowLanguages(false);
  };

  return (
    <div className="ml-4 relative flex justify-center" ref={ref}>
      <BurgerMenuButton isOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      {isMenuOpen ? (
        <div className="absolute bg-primary-700 min-w-screen sm:min-w-navigation bottom-auto top-full right-0 flex flex-col p-4 max-h-menu-mobile max-h-menu overflow-y-scroll">
          {isMobile && (
            <Authenticator
              onNavigate={() => setIsMenuOpen(false)}
              Authenticated={({ onProfile, onNavigate }: AuthenticatedProps) => (
                <>
                  <MenuButton
                    textid="authentication.calculations"
                    onClick={() => {
                      history.push('/notifications');
                      if (onNavigate) onNavigate();
                    }}
                    icon={<MenuIcon icon={Icons.Drop} />}
                  />
                  <MenuButton
                    disabled={!connected}
                    textid="authentication.profile"
                    onClick={() => onProfile()}
                    icon={<MenuIcon disabled={!connected} icon={Icons.Settings} />}
                  />
                </>
              )}
            />
          )}
          <MenuButton textid="navigation.language" onClick={openLanguages} icon={<LanguageIcon className="mr-6" />} />
          <MenuButton
            disabled={!connected}
            textid="navigation.calculations"
            href={intl.formatMessage({ id: 'navigation.calculations.href' })}
            onClick={() => logNavigationCalculation()}
            icon={<ManualOpenNewSvg className="mr-6" />}
          />
          <MenuButton
            disabled={!connected}
            textid="navigation.selectionchart"
            href={intl.formatMessage({ id: 'navigation.selectionchart.href' })}
            onClick={() => logNavigationSelectionChart()}
            icon={<ManualOpenNewSvg className="mr-6" />}
          />
          <MenuButton
            disabled={!connected}
            textid="navigation.accessories"
            href={intl.formatMessage({ id: 'navigation.accessories.href' })}
            onClick={() => logNavigationSupport()}
            icon={<ManualOpenNewSvg className="mr-6" />}
          />
          <MenuButton
            disabled={!connected}
            textid="navigation.support"
            href={intl.formatMessage({ id: 'navigation.support.href' })}
            onClick={() => logNavigationIncidents()}
            icon={<Icon className="mr-6" feIcon={Icons.Comment} feColor={IconColors.White} feSize={IconSizes.Lg} />}
          />
          {isMobile && (
            <Authenticator
              onNavigate={() => setIsMenuOpen(false)}
              Authenticated={({ onLogout }: AuthenticatedProps) => (
                <MenuButton
                  disabled={!connected}
                  onClick={onLogout}
                  textid="authentication.logout"
                  icon={<MenuIcon disabled={!connected} icon={Icons.LogOut} />}
                />
              )}
            />
          )}
          <Divider />
          <UnitSelector />
        </div>
      ) : null}
      <LanguageDialog show={showLanguages} onClose={closeLanguages} />
    </div>
  );
};

export default Navigation;
