import { StoredCalculation } from 'domain/StoredCalculation';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setInput } from 'store/InputSlice';
import { getCalculationFromStorage, removeCalculationFromStorage } from '../LocalStorage/LocalStorageRepository';
import createInputFromStored from './createInput';

const useApplyFromStorage = () => {
  const dispatch = useAppDispatch();
  const bearings = useAppSelector((state) => state.data.bearingTypes);
  const conditions = useAppSelector((state) => state.data.conditions);
  const greases = useAppSelector((state) => state.data.greases);
  const lubricators = useAppSelector((state) => state.data.lubricators);

  const setCurrentCalculation = useCallback(
    (currentCalculation: StoredCalculation) => {
      const input = createInputFromStored(currentCalculation.input, bearings, conditions, greases, lubricators);
      dispatch(setInput(input));
      removeCalculationFromStorage();
    },
    [dispatch, bearings, conditions, greases, lubricators]
  );

  useEffect(() => {
    const currentCalculation = getCalculationFromStorage();
    if (currentCalculation) {
      setCurrentCalculation(currentCalculation);
    }
  }, [setCurrentCalculation]);

  return null;
};

export default useApplyFromStorage;
