import { Icon, IconColors, Icons } from '@skf-internal/ui-components-react-legacy';
type PanelProps = {
  header: string;
  children: React.ReactNode | React.ReactNode[];
  icon: Icons;
  truncate?: boolean;
  largeText?: boolean;
  widen?: boolean;
};

const Panel = ({ header, children, icon, truncate, largeText, widen }: PanelProps) => {
  const getTextClassName = (base: string) => {
    const result = [base];
    if (largeText) {
      result.push('text-xl');
    } else {
      result.push('text-l');
    }
    if (truncate) {
      result.push('whitespace-nowrap truncate');
    }
    if (widen) {
      result.push('lg:min-w-96');
    }
    return result.join(' ');
  };

  return (
    <div className="flex items-start min-h-full my-3 sm:my-0 ">
      <Icon className="w-6 mr-1 mt-1 sm:mt-0 sm:mr-2 text-primary-600" feIcon={icon} feColor={IconColors.Brand} />
      <div className="flex flex-col sm:pr-8 sm:mr-8 sm:border-r-2 min-h-full min-w-xxs lg:min-w-xs">
        <div className={getTextClassName('font-bold sm:leading-4 sm:pb-2 max-w-xxs lg:max-w-xs')}>{header}</div>
        {children}
      </div>
    </div>
  );
};

export default Panel;
