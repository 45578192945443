import { useEffect, useState } from 'react';

enum SwEvents {
  waiting = 'waiting',
  installing = 'installing',
  ready = 'ready'
}

class ServiceWorkerUpdateListener extends EventTarget {
  private _registrations: ServiceWorkerRegistration[] = [];
  private _eventListeners: {
    registration: ServiceWorkerRegistration;
    target: ServiceWorker | ServiceWorkerContainer | ServiceWorkerRegistration;
    type: string;
    listener: EventListenerOrEventListenerObject;
  }[] = [];

  onupdateready: null | ((event: CustomEvent) => void) = null;
  onupdatewaiting: null | ((event: CustomEvent) => void) = null;
  onupdateinstalling: null | ((event: CustomEvent) => void) = null;

  /**
   * Add a registration to start listening for update events
   * @param {ServiceWorkerRegistration} registration
   */
  addRegistration(registration: ServiceWorkerRegistration) {
    // Make sure we have an array to hold the registrations
    if (!this._registrations) this._registrations = [];

    // Abort if we are already listening for this registration
    if (this._registrations.includes(registration)) return;

    // Add the registration to the array of registrations
    this._registrations.push(registration);

    // Fire the `onupdatewaiting` event if there is already a Service Worker waiting
    if (registration.waiting) this.dispatchUpdateStateChange(SwEvents.waiting, registration.waiting, registration);

    // Listen for a new service worker at ServiceWorkerRegistration.installing
    this.addEventListenerForRegistration(registration, registration, 'updatefound', (updatefoundevent: Event) => {
      // Abort if we have no active service worker already, that would mean that this is a new service worker and not an update
      // There should be a service worker installing else this event would not have fired, but double check to be sure
      if (!registration.active || !registration.installing) return;

      // Listen for state changes on the installing service worker
      this.addEventListenerForRegistration(registration, registration.installing, 'statechange', (statechangeevent) => {
        // The state should be installed, but double check to make sure
        if ((statechangeevent.target as ServiceWorker).state !== 'installed') return;

        // Fire the `onupdatewaiting` event as we have moved from installing to the installed state
        this.dispatchUpdateStateChange(SwEvents.waiting, registration.waiting, registration);
      });

      // Fire the `onupdateinstalling` event
      this.dispatchUpdateStateChange(SwEvents.installing, registration.installing, registration);
    });

    // Listen for the document's associated ServiceWorkerRegistration to acquire a new active worker
    this.addEventListenerForRegistration(registration, window.navigator.serviceWorker, 'controllerchange', (controllerchangeevent) => {
      // Postpone the `onupdateready` event until the new active service worker is fully activated
      (controllerchangeevent.target as ServiceWorkerContainer).ready.then((registration) => {
        // Fire the `onupdateready` event
        this.dispatchUpdateStateChange(SwEvents.ready, registration.active, registration);
      });
    });
  }

  /**
   * Remove a registration to stop listening for update events
   * @param {ServiceWorkerRegistration} registration
   */
  removeRegistration(registration: ServiceWorkerRegistration) {
    // Abort if we don't have any registrations
    if (!this._registrations || this._registrations.length <= 0) return;

    // Remove all event listeners attached to a certain registration
    var removeEventListenersForRegistration = (registration: ServiceWorkerRegistration) => {
      if (!this._eventListeners) this._eventListeners = [];
      this._eventListeners = this._eventListeners.filter((eventListener) => {
        if (eventListener.registration === registration) {
          eventListener.target.removeEventListener(eventListener.type, eventListener.listener);
          return false;
        } else {
          return true;
        }
      });
    };

    // Remove the registration from the array
    this._registrations = this._registrations.filter((current) => {
      if (current === registration) {
        removeEventListenersForRegistration(registration);
        return false;
      } else {
        return true;
      }
    });
  }

  /**
   * Force the service worker to move from waited to activating state.
   *
   * Note: This requires the service worker script file to listen for this message, for example:
   * self.addEventListener('message', event => { if (event.data === 'skipWaiting') return skipWaiting() });
   * @param {ServiceWorker} serviceWorker
   */
  skipWaiting(serviceWorker: ServiceWorker | null) {
    if (serviceWorker) {
      serviceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }

  addEventListenerForRegistration(
    registration: ServiceWorkerRegistration,
    target: ServiceWorker | ServiceWorkerContainer | ServiceWorkerRegistration,
    type: string,
    listener: EventListenerOrEventListenerObject
  ) {
    if (!this._eventListeners) this._eventListeners = [];
    this._eventListeners.push({ registration, target, type, listener });
    target.addEventListener(type, listener);
  }

  dispatchUpdateStateChange(state: SwEvents, serviceWorker: ServiceWorker | null, registration: ServiceWorkerRegistration) {
    var type = 'update' + state;
    var event = new CustomEvent(type, { detail: { serviceWorker: serviceWorker, registration: registration } });

    this.dispatchEvent(event);

    switch (state) {
      case SwEvents.installing:
        if (this.onupdateinstalling) {
          this.onupdateinstalling(event);
        }
        break;
      case SwEvents.ready:
        if (this.onupdateready) {
          this.onupdateready(event);
        }
        break;
      case SwEvents.waiting:
        if (this.onupdatewaiting) {
          this.onupdatewaiting(event);
        }
        break;
      default:
        break;
    }
  }
}

const useSWUpdate = () => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState<ServiceWorkerRegistration>();
  const [swListener, setSwListener] = useState<ServiceWorkerUpdateListener>();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && 'serviceWorker' in navigator) {
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);

      listener.onupdatewaiting = (waitingEvent) => {
        setUpdateWaiting(true);
      };
      listener.onupdateready = (event) => {
        window.location.reload();
      };

      navigator.serviceWorker.getRegistration().then((reg: ServiceWorkerRegistration | undefined) => {
        if (reg) {
          listener.addRegistration(reg);
          setRegistration(reg);
        }
      });

      return () => listener.removeEventListener('', null);
    }
  }, []);

  const handleUpdate = () => {
    if ('serviceWorker' in navigator) {
      if (swListener && registration) {
        swListener.skipWaiting(registration.waiting);
      }
    }
  };

  return { updateWaiting, handleUpdate };
};

export default useSWUpdate;
