import { Logo, Sizes } from '@skf-internal/ui-components-react-legacy';

const PrintSKFLogo = () => {
  return (
    <div className="mb-8">
      <Logo className="ml-auto" feHeight={Sizes.S28} />
    </div>
  );
};

export default PrintSKFLogo;
