import ChevronDownSvg from 'components/svgs/ChevronDownSvg';
import ChevronUpSvg from 'components/svgs/ChevronUpSvg';
import { LubricatorSetting, LubricatorSettingFormatter, LubricatorSettingImage } from 'domain/Lubricator';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useOuterClick from 'utils/hooks/useOuterClick';
import { ResultWithUnits } from './ResultConversion';
import SettingsImage from './SettingsImage';
import Warnings from './Warnings';
import formatString from 'utils/strings/formatString';
import ResultNumbers from './ResultNumbers';
import { Icon, IconColors, Icons, IconSizes } from '@skf-internal/ui-components-react-legacy';
import CookieService from 'services/Cookies/CookieSettingsService';

const ResultRow = ({ header, value, unit }: { header: string; value: number | string; unit: string }) => {
  return (
    <div className="flex flex-row uppercase text-white text-sm justify-between items-end">
      <div className="grow">{header}</div>
      <div className="text-right flex-none">
        {value} <span className="normal-case text-xs">{unit}</span>
      </div>
    </div>
  );
};

const ResultMobile = ({ result }: { result: ResultWithUnits | undefined }) => {
  const cookieService = new CookieService();
  const [isExpanded, setIsExpanded] = useState(cookieService.getIsResultExpandedCookie());
  const [isWarningExpanded, setIsWarningExpanded] = useState(false);
  const [hideResult, setHideResult] = useState(false);
  const [hideImage, setHideImage] = useState(false);

  const intl = useIntl();

  const myRef = useOuterClick(() => (isWarningExpanded ? setIsWarningExpanded(false) : null));

  useEffect(() => {
    if (result) {
      setHideResult(result.hideResult ? true : false);
      if ((result.lubricatorSetting as LubricatorSettingImage).imageRef) {
        setHideImage(false);
      } else {
        setHideImage(true);
      }
    }
  }, [result]);

  const formatSettingMessage = (setting: LubricatorSetting | undefined): string => {
    if (setting && (setting.highWarning || setting.lowWarning)) {
      return intl.formatMessage({ id: 'result.mobile.noresult' });
    }
    if (setting && (setting as LubricatorSettingFormatter).formattingValues) {
      return formatString(
        intl.formatMessage({ id: setting.text }),
        (setting as LubricatorSettingFormatter).formattingValues.map((n) => n.toString())
      );
    } else if (setting && setting.text) {
      return intl.formatMessage({ id: setting.text });
    } else {
      return intl.formatMessage({ id: 'result.mobile.noresult' });
    }
  };

  const getWarningClasses = () => {
    let baseClasses = 'mx-2 max-h-64 text-sm text-white ';
    if (hideResult) {
      baseClasses += 'bg-warning-500 p-1 rounded shadow ';
    }
    if (!isExpanded) {
      if (hideResult) {
        baseClasses += 'rounded-b-none ';
      }
      return baseClasses + 'h-6 overflow-hidden';
    } else {
      if (hideResult) {
        baseClasses += 'mb-2 ';
      }
      return baseClasses + 'overflow-y-auto h-auto';
    }
  };

  const toggleExpand = () => {
    if (isWarningExpanded) {
      setIsWarningExpanded(false);
    } else {
      cookieService.setIsResultExpandedCookie(!isExpanded);
      setIsExpanded(!isExpanded);
      if (hideResult && !isExpanded) {
        setIsWarningExpanded(true);
      }
    }
  };
  const toggleWarningsExpanded = () => {
    setIsWarningExpanded(!isWarningExpanded);
  };

  return (
    <div ref={myRef} className="sm:hidden">
      <div className="flex flex-row w-full border-t-2 border-gray-25">
        <div onClick={toggleExpand} className="text-black bg-primary-600 absolute pb-0.5 -top-2.5 left-1/2 pl-1 rounded-full cursor-pointer">
          {isExpanded ? <ChevronDownSvg /> : <ChevronUpSvg />}
        </div>
        <div className={hideImage || !isExpanded ? 'w-full' : 'w-3/5'}>
          {isExpanded && (
            <div className="rounded m-2 mb-1 p-2 bg-primary-500">
              <ResultNumbers result={result} NumberComponent={ResultRow} langKey="mobile" />
            </div>
          )}
          <div className="flex w-full">
            <div className={'rounded m-2 p-1 bg-primary-500 text-white text-sm text-center flex-grow ' + (hideResult ? 'bg-warning-500' : '')}>
              {formatSettingMessage(result?.lubricatorSetting)}
            </div>
            {isExpanded && (
              <div className="m-2 ml-0 rounded bg-white flex cursor-pointer" onClick={toggleWarningsExpanded}>
                <Icon feIcon={Icons.InfoCircleOutlined} feColor={IconColors.Brand} feSize={IconSizes.Lg} className="mx-2 my-auto" />
              </div>
            )}
          </div>
        </div>
        {!hideImage && isExpanded ? (
          <div className="w-2/5 h-32 flex justify-center">
            <SettingsImage className="max-w-full h-auto mx-auto" />
          </div>
        ) : (
          <></>
        )}
      </div>
      {isWarningExpanded && (
        <div>
          <Warnings className={getWarningClasses()} />
          <div className="absolute w-full h-8 bottom-0"></div>
        </div>
      )}
    </div>
  );
};

export default ResultMobile;
