import { Collapse, Table, TableRow } from '@skf-internal/ui-components-react-legacy';
import { Unit } from 'domain/Conditions';
import { DispenseRate } from 'domain/Lubricator';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import { handleAccuracy } from 'utils/data/round';

const DispenseRatesTable = ({ dispenseRates, settingsHeader }: { dispenseRates: DispenseRate[]; settingsHeader: string }) => {
  const unit = useAppSelector((state) => state.data.conditions.unit);
  const intl = useIntl();

  const getDispenseRateValue = (dispenseRate: DispenseRate): { perWeek: string; perMonth: string } => {
    if (unit === Unit.imperial) {
      return {
        perWeek: handleAccuracy(dispenseRate.imperial.perWeek, 2).toString(),
        perMonth: handleAccuracy(dispenseRate.imperial.perMonth, 2).toString()
      };
    } else {
      return {
        perWeek: handleAccuracy(dispenseRate.metric.perWeek, 1).toString(),
        perMonth: handleAccuracy(dispenseRate.metric.perMonth, 1).toString()
      };
    }
  };

  const createRow = (dispenseRate: DispenseRate, index: number): TableRow => {
    return {
      cells: [
        {
          children: dispenseRate.setting
        },
        {
          children: getDispenseRateValue(dispenseRate).perWeek
        },
        {
          children: getDispenseRateValue(dispenseRate).perMonth
        }
      ]
    };
  };

  const createHeaders = (): TableRow[] => {
    return [
      {
        cells: [
          {
            children: settingsHeader,
            scope: 'col'
          },
          {
            children: intl.formatMessage({ id: `result.desktop.dispenseRateWeekly.unit.${unit}` }),
            scope: 'col'
          },
          {
            children: intl.formatMessage({ id: `result.desktop.dispenseRateMonthly.unit.${unit}` }),
            scope: 'col'
          }
        ]
      }
    ];
  };

  return <Table feBody={dispenseRates.map(createRow)} feCompact={true} feHead={createHeaders()} />;
};

const DispenseRates = () => {
  const currentLubricator = useAppSelector((state) => state.input.lubricator);
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(false);
  }, [currentLubricator]);

  return currentLubricator.dispenseRate ? (
    <Collapse
      feAnimate={true}
      className="my-4"
      onClick={() => setIsExpanded(!isExpanded)}
      feExpanded={isExpanded}
      feTitle={intl.formatMessage({ id: 'result.desktop.dispenseRateHeader' }, { lubricator: currentLubricator.displayName })}
    >
      <DispenseRatesTable settingsHeader={intl.formatMessage({ id: currentLubricator.dispenseRateHeader })} dispenseRates={currentLubricator.dispenseRate} />
    </Collapse>
  ) : null;
};

export default DispenseRates;
