import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BearingDimensions } from 'domain/BearingDimensions';
import { BearingType } from 'domain/BearingType';
import { ConditionChoice, ConditionLevel } from 'domain/Conditions';
import { Grease } from 'domain/Grease';
import { Input } from 'domain/Input';
import { Lubricator } from 'domain/Lubricator';
import { convertConditionChoiceToImperial, convertConditionChoiceToMetric } from 'services/UnitConversion/UnitConversionService';
import { setUnit } from 'store/DataSlice/index';

export interface InputState extends Input {
  isDisabled: boolean;
}

const initialState: InputState = {
  isDisabled: false
} as InputState;

export const inputSlice = createSlice({
  name: 'input',
  initialState: initialState,
  reducers: {
    setManualBearingSelection: (state: InputState, action: PayloadAction<boolean>) => {
      state.manualBearingSelection = action.payload;
      if (state.manualBearingSelection) {
        state.bearingDesignation = null;
      }
    },
    setBearingDesignation: (state: InputState, action: PayloadAction<string>) => {
      state.bearingDesignation = action.payload;
    },
    setInput: (state: InputState, action: PayloadAction<Input>) => {
      state.ambientTemperature = action.payload.ambientTemperature;
      state.bearingType = action.payload.bearingType;
      state.contamination = action.payload.contamination;
      state.dimensions = action.payload.dimensions;
      state.grease = action.payload.grease;
      state.hours = action.payload.hours;
      state.load = action.payload.load;
      state.lubricator = action.payload.lubricator;
      state.replenishment = action.payload.replenishment;
      state.rotatingOuterRing = action.payload.rotatingOuterRing;
      state.shaftOrientation = action.payload.shaftOrientation;
      state.shockLoad = action.payload.shockLoad;
      state.speed = action.payload.speed;
      state.temperature = action.payload.temperature;
      state.manualBearingSelection = action.payload.manualBearingSelection;
      state.bearingDesignation = action.payload.bearingDesignation;
    },
    setBearingTypeAndDimensions: {
      reducer: (state: InputState, action: PayloadAction<{ bearingType: BearingType; dimensions: BearingDimensions }>) => {
        state.bearingType = action.payload.bearingType;
        state.dimensions = action.payload.dimensions;
        state.manualBearingSelection = false;
      },
      prepare: (value: { bearingType: BearingType; dimensions: BearingDimensions }) => ({
        payload: {
          bearingType: value.bearingType,
          dimensions: prepareDimensions(value.dimensions).payload
        }
      })
    },
    setBearingType: (state: InputState, action: PayloadAction<BearingType>) => {
      state.bearingType = action.payload;
    },
    setDimension: {
      reducer: (state: InputState, action: PayloadAction<BearingDimensions>) => {
        state.dimensions = action.payload;
        state.manualBearingSelection = true;
        state.bearingDesignation = null;
      },
      prepare: prepareDimensions
    },
    setSpeed: {
      reducer: (state: InputState, action: PayloadAction<number | null>) => {
        state.speed = action.payload;
      },
      prepare: (value: number) => ({ payload: handleNan(value) })
    },
    setHours: {
      reducer: (state: InputState, action: PayloadAction<number | null>) => {
        state.hours = action.payload;
      },
      prepare: (value: number) => ({
        payload: handleNumberRange(value, 1, 24)
      })
    },
    setTemperature: (state: InputState, action: PayloadAction<ConditionChoice<ConditionLevel>>) => {
      state.temperature = action.payload;
    },
    setContamination: (state: InputState, action: PayloadAction<ConditionChoice<number>>) => {
      state.contamination = action.payload;
    },
    setLoad: (state: InputState, action: PayloadAction<ConditionChoice<ConditionLevel>>) => {
      state.load = action.payload;
    },
    setShockLoad: (state: InputState, action: PayloadAction<ConditionChoice<boolean>>) => {
      state.shockLoad = action.payload;
    },
    setAmbientTemperature: (state: InputState, action: PayloadAction<ConditionChoice<number>>) => {
      state.ambientTemperature = action.payload;
    },
    setShaftOrientation: (state: InputState, action: PayloadAction<ConditionChoice<number>>) => {
      state.shaftOrientation = action.payload;
    },
    setReplenishment: (state: InputState, action: PayloadAction<ConditionChoice<number>>) => {
      state.replenishment = action.payload;
    },
    setRotatingOuterRing: (state: InputState, action: PayloadAction<ConditionChoice<boolean>>) => {
      state.rotatingOuterRing = action.payload;
    },
    setGrease: (state: InputState, action: PayloadAction<Grease>) => {
      state.grease = action.payload;
    },
    setLubricator: (state: InputState, action: PayloadAction<Lubricator>) => {
      state.lubricator = action.payload;
    },
    setDisabled: (state: InputState, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setUnit.fulfilled, (state, action: PayloadAction<string>) => {
      if (action.payload === 'imperial') {
        state.temperature = convertConditionChoiceToImperial(state.temperature);
      } else {
        state.temperature = convertConditionChoiceToMetric(state.temperature);
      }
    });
  }
});

function prepareDimensions(value: BearingDimensions) {
  return {
    payload: {
      innerDiameter: handleNan(value.innerDiameter),
      outerDiameter: handleNan(value.outerDiameter),
      width: handleNan(value.width)
    }
  };
}

function handleNumberRange(value: number, min: number, max: number): number | null {
  if (isNaN(value)) {
    return null;
  }
  if (value < min || value > max) {
    if (value < min) {
      return min;
    } else {
      return max;
    }
  } else {
    return value;
  }
}

function handleNan(value: number | null): number | null {
  if (value === null) {
    return null;
  }
  if (isNaN(value)) {
    return null;
  } else {
    return value;
  }
}

export const {
  setManualBearingSelection,
  setBearingDesignation,
  setInput,
  setBearingTypeAndDimensions,
  setBearingType,
  setDimension,
  setSpeed,
  setHours,
  setTemperature,
  setContamination,
  setLoad,
  setShockLoad,
  setAmbientTemperature,
  setShaftOrientation,
  setReplenishment,
  setRotatingOuterRing,
  setGrease,
  setLubricator,
  setDisabled
} = inputSlice.actions;
export default inputSlice.reducer;
