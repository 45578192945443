import { ConditionLevel } from 'domain/Conditions';
import { Grease } from 'domain/Grease';
import { Input } from 'domain/Input';
import { GreaseAvalability, GreaseDisabledReason } from 'domain/Result';
import { calculateSpeedFactor } from './WarningService';

export default function filterGreases(input: Input, allGreases: Array<Grease>, lubricatorGreases: Array<Grease>): Array<GreaseAvalability> {
  const speedFactor = calculateSpeedFactor(
    input.rotatingOuterRing.value,
    input.speed ?? 1800,
    input.dimensions.outerDiameter ?? 260,
    input.dimensions.innerDiameter ?? 120
  );

  return allGreases.map((g) => getGreaseAvailability(g, input, speedFactor, lubricatorGreases));
}

export function getGreaseAvailability(grease: Grease, input: Input, speedFactor: number, lubricatorGreases: Grease[]): GreaseAvalability {
  const lubricatorAvailable = lubricatorGreases.some((g) => g.id === grease.id);
  const lowSpeedAvailable = checkLowSpeed(grease, input, speedFactor);
  const loadRatioAvailable = checkLoadRatio(grease, input);
  const temperatureAvailable = checkTemperature(grease, input);

  return {
    grease: grease,
    isDisabled: !lubricatorAvailable, // && lowSpeedAvailable && loadRatioAvailable && temperatureAvailable),
    disabledReason: getDisabledReason(lubricatorAvailable, lowSpeedAvailable, loadRatioAvailable, temperatureAvailable)
  } as GreaseAvalability;
}

export function getDisabledReason(
  lubricatorAvailable: boolean,
  lowSpeedAvailable: boolean,
  loadRatioAvailable: boolean,
  temperatureAvailable: boolean
): Array<GreaseDisabledReason> {
  const reasons: Array<GreaseDisabledReason> = [];
  if (!lubricatorAvailable) reasons.push(GreaseDisabledReason.lubricator);
  if (!lowSpeedAvailable) reasons.push(GreaseDisabledReason.speed);
  if (!loadRatioAvailable) reasons.push(GreaseDisabledReason.load);
  if (!temperatureAvailable) reasons.push(GreaseDisabledReason.temperature);
  return reasons;
}

export function checkLowSpeed(grease: Grease, input: Input, speedFactor: number): boolean {
  let greaseSpeed = grease.speed.veryLow;

  if (speedFactor > input.bearingType.speedLimit.veryLow) {
    greaseSpeed = grease.speed.low;
  }
  if (speedFactor > input.bearingType.speedLimit.low) {
    greaseSpeed = grease.speed.moderate;
  }
  if (speedFactor > input.bearingType.speedLimit.moderate) {
    greaseSpeed = grease.speed.high;
  }
  if (speedFactor > input.bearingType.speedLimit.high) {
    greaseSpeed = grease.speed.max;
  }

  return greaseSpeed;
}

export function checkLoadRatio(grease: Grease, input: Input): boolean {
  switch (input.load.value) {
    case ConditionLevel.low:
      return grease.load.low;
    case ConditionLevel.moderate:
      return grease.load.moderate;
    case ConditionLevel.high:
      return grease.load.high;
    case ConditionLevel.veryhigh:
      return grease.load.veryHigh;
    default:
      return false;
  }
}

export function checkTemperature(grease: Grease, input: Input): boolean {
  if (grease.id === 'LGGB 2') {
    if (input.temperature.value === ConditionLevel.veryhigh) {
      return false;
    }
  }
  return true;
}
