import { useToast } from '@skf-internal/ui-components-react-legacy';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { OfflineSyncStatus } from '../../services/Notifications/NotificationSyncService';

const SyncStatusToasts = () => {
  const syncStatus = useAppSelector((state) => state.setting.syncStatus);
  const [currentStatus, setCurrentStatus] = useState<OfflineSyncStatus>(OfflineSyncStatus.None);

  const { addToast } = useToast();

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setCurrentStatus(syncStatus);
      if (syncStatus !== currentStatus) {
        if (syncStatus === OfflineSyncStatus.Done) {
          addToast({
            children: 'Syncing done',
            feSeverity: 'success'
          });
        } else if (syncStatus === OfflineSyncStatus.Error) {
          addToast({
            children: 'Error syncing',
            feSeverity: 'error'
          });
        } else if (syncStatus === OfflineSyncStatus.MeSyncing) {
          addToast({
            children: 'Syncing',
            feSeverity: 'warning'
          });
        } else if (syncStatus === OfflineSyncStatus.Syncing) {
          addToast({
            children: 'Syncing in another window',
            feSeverity: 'info'
          });
        } else if (syncStatus === OfflineSyncStatus.NothingToSync) {
          addToast({
            children: 'Nothing to sync',
            feSeverity: 'info'
          });
        }
      }
    }
  }, [syncStatus, addToast, currentStatus]);

  return <></>;
};

export default SyncStatusToasts;
