import { Colors, css, Icon, Icons, Shadows, Spacer, Spacings, styled } from '@skf-internal/ui-components-react-legacy';
import { useCombobox, UseComboboxStateChange } from 'downshift';

import SearchComboboxInput from './SearchComboboxInput';
import DropdownPopper from 'components/layouts/DropdownPopper';
import { useEffect, useState } from 'react';

export type SearchItem<T> = {
  item: T;
  disabled?: boolean;
  label: string;
  value: string;
};

export type SearchComboboxProps<TItem> = {
  items: SearchItem<TItem>[];
  label: string;
  isLoading: boolean;
  isError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  hint?: string;
  noResultMessage?: string;
  onItemSelected: (item: SearchItem<TItem> | null | undefined) => void;
  onSearch: (value?: string) => void;
  onReset: () => void;
  currentItem?: SearchItem<TItem> | null;
  otherInputValue?: string;
};

const SearchCombobox = <T,>({
  items,
  label,
  isLoading,
  isError,
  errorMessage,
  disabled,
  hint,
  noResultMessage,
  onItemSelected,
  onSearch,
  onReset,
  currentItem,
  otherInputValue
}: SearchComboboxProps<T>) => {
  const {
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    selectItem,
    closeMenu,
    reset,
    inputValue
  } = useCombobox<SearchItem<T> | null>({
    onInputValueChange(state: UseComboboxStateChange<SearchItem<T> | null>) {
      if (selectedItem === state.selectedItem) {
        onSearch(state.inputValue);
      }
    },
    items: items,
    itemToString(item) {
      if (item) {
        return item.label;
      } else {
        return '';
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onItemSelected(selectedItem);
    },

    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.FunctionReset:
          return {
            ...changes,
            inputValue: ''
          };
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex + 1 === items.length ? 0 : state.highlightedIndex + 1
          };
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex - 1 === -1 ? items.length - 1 : state.highlightedIndex - 1
          };
      }
      return changes;
    }
  });

  const getInputPropsInternal = (options?: any, otherOptions?: any): any => {
    const props = getInputProps(options, otherOptions);
    if (disabled) {
      if (otherInputValue) {
        return { ...props, value: inputValue ? inputValue : otherInputValue };
      }
    }
    return props;
  };

  useEffect(() => {
    if (currentItem !== undefined) {
      selectItem(currentItem);
      if (currentItem === null) {
        reset();
      }
    }
  }, [currentItem, selectItem, reset]);

  const [inputContainerRef, setInputContainerRef] = useState<HTMLDivElement | null>(null);
  const buttonWidth = inputContainerRef?.getBoundingClientRect().width;

  const getSearchInput = () => {
    return (
      <div ref={setInputContainerRef}>
        <SearchComboboxInput
          label={label}
          getComboboxProps={getComboboxProps}
          getInputProps={getInputPropsInternal}
          getToggleButtonProps={getToggleButtonProps}
          isLoading={isLoading}
          isError={isError}
          errorMessage={errorMessage}
          disabled={disabled}
          hint={hint}
          inputValue={inputValue}
          onResetClick={() => {
            selectItem(null);
            closeMenu();
            onReset();
          }}
        />
      </div>
    );
  };

  const getDisabledDropDownItem = (text: string, index: number = 0) => {
    return (
      <Styled.SelectItem key={index} highlighted={false} disabled={true}>
        <Styled.Spacer feSpacing={Spacings.Md} feHorizontal />
        {text}
      </Styled.SelectItem>
    );
  };

  const getActiveDropdownItem = (item: SearchItem<T>, text: string, index: number, isHighlighted: boolean, isSelected: boolean) => {
    return (
      <Styled.SelectItem key={index} {...getItemProps({ item, index })} highlighted={isHighlighted}>
        {isSelected ? <Styled.IconCheck feIcon={Icons.Check} /> : <Styled.Spacer feSpacing={Spacings.Md} feHorizontal />}
        {text}
      </Styled.SelectItem>
    );
  };

  const getDropdownItem = (item: SearchItem<T>, index: number) => {
    const selectedIndex = items.findIndex((i) => i.value === item.value);
    const isSelected = selectedItem?.value === item.value;
    return item.disabled
      ? getDisabledDropDownItem(item.label, index)
      : getActiveDropdownItem(item, item.label, index, highlightedIndex === selectedIndex, isSelected);
  };

  const getEmptyMessage = ({ isOpen, resultCount }: { isOpen: boolean; resultCount: number }) => {
    if (!isOpen) {
      return null;
    }

    if (!resultCount && noResultMessage) {
      return getDisabledDropDownItem(noResultMessage);
    }

    return null;
  };

  const getDropdown = () => {
    return (
      <Styled.SelectList isOpen={isOpen && !disabled && !isError && (items.length > 0 || noResultMessage)} listWidth={buttonWidth} {...getMenuProps()}>
        {isOpen && !isError && items.length > 0 && items.map(getDropdownItem)}
        {getEmptyMessage({
          isOpen: isOpen && !isError,
          resultCount: items.length
        })}
      </Styled.SelectList>
    );
  };

  return <DropdownPopper popTrigger={getSearchInput()} poppedContent={getDropdown()} offset={isError || hint ? -21 : undefined} />;
};

const Styled = {
  SelectList: styled.ul(
    ({ isOpen, listWidth, listHeight }: { isOpen: boolean; listWidth: string; listHeight?: string }) => css`
      display: flex;
      flex-direction: column;
      background-color: ${Colors.White};
      box-shadow: ${isOpen ? Shadows.Lg : 'none'};
      opacity: ${isOpen ? 1 : 0};
      padding: 0.25rem;
      width: ${listWidth}px;
      ${listHeight &&
      css`
        max-height: ${listHeight};
        overflow-y: auto;
      `}
    `
  ),
  SelectItem: styled.li(
    ({ highlighted, disabled }: { highlighted: boolean; disabled?: boolean }) => css`
      align-items: center;
      background-color: ${highlighted ? Colors.Primary200 : Colors.White};
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      height: 2.5rem;
      white-space: nowrap;
      padding-inline-end: ${Spacings.Xs};
      padding-right: 10rem;
      ${disabled &&
      css`
        color: ${Colors.Gray500};
        cursor: default;
      `}
    `
  ),
  IconCheck: styled(Icon)`
    margin-inline: ${Spacings.Xxs} ${Spacings.Xs};
  `,

  Spacer: styled(Spacer)`
    margin-inline: ${Spacings.Xxs} ${Spacings.Xs};
  `
};

export default SearchCombobox;
