import { Icons } from '@skf-internal/ui-components-react-legacy';
import SizeableIcon from 'components/layouts/SizeableIcon';
import { Notification } from 'domain/Notification';
import { useIntl } from 'react-intl';
import OnClickProps from 'utils/ui/OnClickProps';
import DuplicateAction from '../Actions/DuplicateAction';

const DuplicateButton = ({ notification }: { notification: Notification }) => {
  const intl = useIntl();
  return (
    <DuplicateAction
      notification={notification}
      Icon={({ onClick }: OnClickProps) => (
        <SizeableIcon onClick={onClick} title={intl.formatMessage({ id: 'usercalculations.duplicate.tooltip' })} icon={Icons.Duplicate} />
      )}
    />
  );
};

export default DuplicateButton;
