import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ComponentType, useEffect } from 'react';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { b2cPolicies } from 'services/Authentication/authConfig';
import { login } from 'store/AccountSlice';
import { useAppDispatch } from 'store/hooks';
import useIsOfflineAuthenticated from './useIsOfflineAuthenticated';
import useLoginRedirect from './useLoginRedirect';

export type AnonymousProps = {
  onLogin: () => void;
};

export type AuthenticatedProps = {
  onLogout: () => void;
  onProfile: () => void;
  onNavigate?: () => void;
};

const Authenticator = ({
  Anonymous,
  Authenticated,
  onNavigate
}: {
  Anonymous?: ComponentType<AnonymousProps>;
  Authenticated?: ComponentType<AuthenticatedProps>;
  onNavigate?: () => void;
}) => {
  const { instance, inProgress, accounts } = useMsal();
  const dispatch = useAppDispatch();
  const { logNavigationLogin, logNavigationLogout, logNavigationProfile } = useAnalyticsLog();
  const { isOfflineAuthenticated, isAuthenticated } = useIsOfflineAuthenticated();
  const { redirect } = useLoginRedirect();

  useEffect(() => {
    dispatch(login(accounts.length > 0 ? accounts[0] : null));
  }, [accounts, dispatch]);

  const OnLogin = () => {
    redirect();
    logNavigationLogin();
  };

  const OnLogout = () => {
    logNavigationLogout();
    instance.logoutRedirect();
  };

  const OnSettings = () => {
    if (inProgress === InteractionStatus.None) {
      logNavigationProfile();
      instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile as RedirectRequest);
    }
  };

  return isOfflineAuthenticated !== undefined ? (
    isOfflineAuthenticated ? (
      <>
        {Authenticated && isAuthenticated && <Authenticated onLogout={OnLogout} onProfile={OnSettings} onNavigate={onNavigate} />} :
        {Anonymous && !isAuthenticated && <Anonymous onLogin={OnLogin} />}
      </>
    ) : (
      <>
        <AuthenticatedTemplate>{Authenticated && <Authenticated onLogout={OnLogout} onProfile={OnSettings} onNavigate={onNavigate} />}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>{Anonymous && <Anonymous onLogin={OnLogin} />}</UnauthenticatedTemplate>
      </>
    )
  ) : (
    <></>
  );
};

export default Authenticator;
