import { TableRow } from '@skf-internal/ui-components-react-legacy';
import OneColumnContainer from 'components/layouts/OneColumnContainer';
import ShortDate from 'components/layouts/ShortDate';
import { Notification } from 'domain/Notification';
import { useIntl } from 'react-intl';
import ActionButtons from './ActionButtons';
import CalculationLink from './CalculationLink';
import TableBase, { SortColumn } from './TableBase';
import TimeLeft, { getTotalDaysRemaining } from './TimeLeft';

const DesktopTable = () => {
  const intl = useIntl();

  const getTableRow = (notification: Notification): TableRow => {
    return {
      cells: [
        { children: <CalculationLink className="max-w-tableCellDesktop overflow-hidden" value={notification.name} id={notification.id} /> },
        { children: <ShortDate date={notification.reminder.installationDate} />, scope: 'col' },
        { children: <ShortDate date={notification.reminder.replenishmentDate} />, scope: 'col' },
        {
          children: notification.reminder.hasReminder ? <ShortDate date={notification.reminder.notificationDate} fallBack="None" /> : <p>None</p>,
          scope: 'col'
        },
        {
          children: (
            <TimeLeft
              timeLeft={getTotalDaysRemaining(
                notification.reminder.createdAt,
                notification.reminder.installationDate,
                notification.reminder.replenishmentDate,
                notification.reminder.notificationDate
              )}
            />
          ),
          scope: 'col'
        },
        { children: <ActionButtons notification={notification} /> }
      ]
    };
  };

  const headers: { name: string; sortColumn: SortColumn }[] = [
    { name: intl.formatMessage({ id: 'usercalculations.table.header.name' }), sortColumn: SortColumn.name },
    { name: intl.formatMessage({ id: 'usercalculations.table.header.installationDate' }), sortColumn: SortColumn.installationDate },
    { name: intl.formatMessage({ id: 'usercalculations.table.header.replenishmentDate' }), sortColumn: SortColumn.replenishmentDate },
    { name: intl.formatMessage({ id: 'usercalculations.table.header.reminder' }), sortColumn: SortColumn.notificationDate },
    { name: intl.formatMessage({ id: 'usercalculations.table.header.timeLeft' }), sortColumn: SortColumn.timeLeft }
  ];

  return (
    <OneColumnContainer>
      <TableBase
        usePagination
        displayHeaders={headers}
        showActions
        getTableRow={getTableRow}
        header={intl.formatMessage({ id: 'usercalculations.table.heading' })}
        description={intl.formatMessage({ id: 'usercalculations.table.description' })}
        showInfoBox
      />
    </OneColumnContainer>
  );
};

export default DesktopTable;
