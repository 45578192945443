import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useEffect } from 'react';
import { calculateResult } from 'store/ResultSlice';
import TwoColumnContainer from 'components/layouts/TwoColumnContainer';
import CalculationResult from './Result/CalculationResult';
import OperatingInput from './Operating/OperatingInput';
import Bearing from './Bearing/Bearing';
import Lubrication from './Lubrication/Lubrication';
import { MediaQueries, useMediaQuery } from '@skf-internal/ui-components-react-legacy';
import OneColumnContainer from 'components/layouts/OneColumnContainer';
import useNotificationFromLocation from 'features/Notifications/useNotificationFromLocation';
import CurrentNotification from 'features/Notifications/CurrentNotification/CurrentNotification';
import useLoadNotificationsSilent from 'features/Notifications/useLoadNotificationsSilent';

const DialSetting = () => {
  const isMobile = useMediaQuery(MediaQueries.MobileMax);

  useNotificationFromLocation();
  useLoadNotificationsSilent();

  return (
    <>
      <CalculationTrigger />
      <div className="bg-gray-25 mt-16">
        <OneColumnContainer>
          <CurrentNotification />
        </OneColumnContainer>
        <TwoColumnContainer stickyRight={!isMobile}>
          <div className="pb-7">
            <Bearing />
            <OperatingInput />
            <Lubrication />
          </div>
          <CalculationResult />
        </TwoColumnContainer>
      </div>
    </>
  );
};

const CalculationTrigger = () => {
  const input = useAppSelector((state) => state.input);
  const data = useAppSelector((state) => state.data);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(calculateResult({ input, data }));
  }, [dispatch, input, data]);

  return <></>;
};

export default DialSetting;
