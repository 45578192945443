import { IntlShape } from 'react-intl';

export const formatOptionalMessage = (descriptor: { id: string }, intl: IntlShape): string | undefined => {
  if (descriptor.id) {
    const message = intl.formatMessage(descriptor);
    if (message !== descriptor.id) {
      return message;
    }
  }
};
