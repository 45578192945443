import PrintBearingSelection from 'features/Print/PrintBearingSelection';
import PrintOperating from 'features/Print/PrintOperating';
import CalculationResultPrint from 'features/Print/PrintCalculationResult';
import PrintRow from './layout/PrintRow';
import PrintLubricantAndGreaseSelection from 'features/Print/PrintLubricantAndGreaseSelection';
import PrintSKFLogo from './layout/PrintSKFLogo';

const PrintForm = () => {
  return (
    <div className="print text-xs">
      <PrintSKFLogo />
      <PrintRow>
        <div>
          <PrintBearingSelection />
        </div>
        <div></div>
      </PrintRow>
      <PrintOperating />
      <PrintLubricantAndGreaseSelection />
      <CalculationResultPrint />
    </div>
  );
};

export default PrintForm;
