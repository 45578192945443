import { Notification, NotificationInput } from 'domain/Notification';
import { ComponentType, useState } from 'react';
import { useIntl } from 'react-intl';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { useAppDispatch } from 'store/hooks';
import { updateNotification } from 'store/NotificationSlice';
import useDialogCloser from 'utils/hooks/useDialogCloser';
import OnClickProps from 'utils/ui/OnClickProps';
import NotificationDialog, { DialogData } from './NotificationDialog';

const EditAction = ({ notification, Icon }: { notification: Notification; Icon: ComponentType<OnClickProps> }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogCloser = useDialogCloser();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { logDeviceUpdated } = useAnalyticsLog();

  const onSave = (input: DialogData) => {
    dispatch(updateNotification({ input: { ...input } as NotificationInput, notificationId: notification.id }));
    dialogCloser(setIsDialogOpen);
    logDeviceUpdated();
  };

  return (
    <>
      <Icon onClick={() => setIsDialogOpen(true)} />
      <NotificationDialog
        existingNotification={notification}
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={onSave}
        currentInterval={notification.settingInterval}
        isTLMP={notification.isTLMP}
        header={intl.formatMessage({ id: 'usercalculations.edit.header' })}
        saveButtonText={intl.formatMessage({ id: 'usercalculations.edit.savebutton' })}
        footerText={intl.formatMessage({ id: 'usercalculations.edit.text' })}
      />
    </>
  );
};

export default EditAction;
