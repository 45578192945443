import InputRadio from '@skf-internal/ui-components-react-legacy/dist/esm/common/components/input-radio/InputRadio';

type TriStateRadioProps = {
  label: string;
  isDisabled: boolean;
  isDisabledWithWarning: boolean;
  value: number | string;
  id: string;
  checked: boolean;
  className?: string;
  onChange: (checked: boolean, value: number | string) => void;
};

const TriStateRadioField = ({ label, isDisabled, isDisabledWithWarning, value, id, checked, onChange, className }: TriStateRadioProps) => {
  const getLabelClasses = () => {
    let classes = 'my-auto ml-2 ';
    if (isDisabled) {
      classes += 'text-gray-500';
    } else if (isDisabledWithWarning) {
      classes += 'italic';
    }
    return classes;
  };

  return (
    <div className={'flex flex-row items-center ' + className}>
      <InputRadio
        checked={checked}
        feSize="sm"
        disabled={isDisabled || isDisabledWithWarning}
        id={id}
        value={value}
        onChange={(event: any, check: boolean) => onChange(check, value)}
      />
      <label htmlFor={id} className={getLabelClasses()}>
        {label} {isDisabledWithWarning ? <span className="text-danger-600">*</span> : null}
      </label>
    </div>
  );
};

export default TriStateRadioField;
