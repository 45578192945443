import { Input } from 'domain/Input';
import { NotificationInput, Notification, ConfirmInput } from 'domain/Notification';
import { Result } from 'domain/Result';
import { StoredCalculation } from 'domain/StoredCalculation';
import getNotificationRepository, { getOnlineFirstNotificationRepository } from './RepositoryFactory';

const addNotification = async (
  notification: NotificationInput,
  input: Input,
  result: Result,
  language: string,
  geoLocation: string | undefined,
  connection?: boolean
): Promise<Notification> => {
  return getNotificationRepository(connection).addNotification(notification, input, result, language, geoLocation);
};

const updateNotification = async (notificationId: string, notification: NotificationInput, connection?: boolean): Promise<Notification> => {
  return getNotificationRepository(connection).updateNotification(notificationId, notification);
};

const getNotifications = async (connection?: boolean): Promise<Notification[]> => {
  return getNotificationRepository(connection).getNotifications();
};

const reloadNotifications = async (): Promise<Notification[]> => {
  return getOnlineFirstNotificationRepository().getNotifications();
};

const deleteNotification = async (notificationId: string, connection?: boolean): Promise<void> => {
  return getNotificationRepository(connection).deleteNotification(notificationId);
};

const getCalculation = async (notificationId: string, connection?: boolean): Promise<{ calculation: StoredCalculation; notification: Notification }> => {
  const calculation = await getNotificationRepository(connection).getCalculation(notificationId);
  if (calculation) {
    return calculation;
  } else {
    throw new Error('Calculation cannot be found');
  }
};

const confirmReminder = async (notificationId: string, confirmReminder: ConfirmInput, connection?: boolean): Promise<Notification> => {
  return getNotificationRepository(connection).confirmReminder(notificationId, confirmReminder);
};

const service = { addNotification, getNotifications, getCalculation, updateNotification, deleteNotification, confirmReminder, reloadNotifications };

export default service;
