import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import { formatWarning, filterWarnings } from 'utils/ui/warningFormats';

type WarningsProps = {
  className?: string;
  textClassName?: string;
};

const Warnings = ({ className, textClassName }: WarningsProps) => {
  const warnings = useAppSelector((state) => state.result.result?.warnings);
  const unit = useAppSelector((state) => state.data.conditions.unit);
  const intl = useIntl();

  return (
    <div className={className}>
      {filterWarnings(warnings).map((w, i) => (
        <p className={'text-gray-100 text-sm sm:text-black mb-1 ' + textClassName} key={i}>
          {formatWarning(w, intl, unit)}
        </p>
      )) ?? <></>}
    </div>
  );
};

export default Warnings;
