import convertResult, { ResultWithUnits } from 'features/DialSetting/Result/ResultConversion';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PrintRoundedResult from 'features/Print/PrintRoundedResult';
import { useAppSelector } from 'store/hooks';
import { filterWarnings, formatWarning } from 'utils/ui/warningFormats';

const PrintWarnings = () => {
  const intl = useIntl();
  const result = useAppSelector((state) => state.result.result);
  const unit = useAppSelector((state) => state.data.conditions.unit);

  const [convertedResult, setConvertedResult] = useState<ResultWithUnits | undefined>(undefined);

  useEffect(() => {
    setConvertedResult(convertResult(result, unit === 'imperial', intl));
  }, [result, unit, intl]);
  if (result) {
    return (
      <div className="print ">
        <div className={result.hideResult ? 'font-bold' : ''}>
          {filterWarnings(result.warnings).map((w, i) => (
            <div key={i} className="mb-2">
              {formatWarning(w, intl, unit)}
            </div>
          )) ?? <PrintRoundedResult result={convertedResult} />}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PrintWarnings;
