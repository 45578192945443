import { BearingBase, BearingTypeCollection } from 'domain/BearingType';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { isBearingCollection } from 'services/LocalData/BearingTypeLoader';
import { useAppSelector } from 'store/hooks';
import treeSearch, { TreeItem } from 'utils/data/treeSearch';
import PrintHeader from './layout/PrintHeader';
import PrintKeyValue from './layout/PrintKeyValue';
import PrintBearingDimensions from './PrintBearingDimensions';
interface SelectedBearing {
  name: string;
  heading: string;
}

const PrintBearingSelection = () => {
  const currentBearingType = useAppSelector((state) => state.input.bearingType);
  const bearingDesignation = useAppSelector((state) => state.input.bearingDesignation);

  const bearingTypes = useAppSelector((state) => state.data.bearingTypes);
  const intl = useIntl();

  const [selectedBearingType, setSelectedBearingType] = useState<SelectedBearing[]>();

  const addSelectBearing = useCallback((item: TreeItem<BearingBase> | null, bearings: SelectedBearing[]) => {
    if (item) {
      bearings.push({
        name: item.item.name,
        heading:
          item.parent && isBearingCollection(item.parent.item)
            ? (item.parent.item as BearingTypeCollection).subTypeHeader
            : 'bearingSelection.firstSelect.label'
      } as SelectedBearing);
      addSelectBearing(item.parent, bearings);
    }
  }, []);

  useEffect(() => {
    if (bearingTypes && currentBearingType) {
      const result = treeSearch<BearingBase>(
        bearingTypes,
        (b) => b.id === currentBearingType.id,
        (b) => (isBearingCollection(b) ? (b as BearingTypeCollection).subTypes : [])
      );

      const selectedBearings: SelectedBearing[] = [];
      addSelectBearing(result, selectedBearings);
      setSelectedBearingType([...selectedBearings].reverse());
    }
  }, [bearingTypes, currentBearingType, addSelectBearing]);

  return (
    <div>
      <PrintHeader header="bearingSelection.header" />
      <PrintKeyValue
        header="bearingSelection.search.print.header"
        value={bearingDesignation ? bearingDesignation : intl.formatMessage({ id: 'bearingSelection.search.print.notselected' })}
      />
      {selectedBearingType?.map((bearing, index) => (
        <PrintKeyValue key={index} header={bearing.heading} value={intl.formatMessage({ id: bearing.name })} />
      ))}
      <PrintBearingDimensions />
    </div>
  );
};

export default PrintBearingSelection;
