import { Input } from 'domain/Input';
import { NotificationInput } from 'domain/Notification';
import { getNumberFromDate } from 'utils/data/dateConversion';

export const createNotificationToUpdate = (notification: NotificationInput) => {
  return {
    name: notification.name,
    reminder: getReminderToUpdate(notification),
    settingInterval: notification.settingInterval,
    isTLMP: notification.isTLMP
  };
};
export const createNotificationToAdd = (notification: NotificationInput, input: Input, language: string, geoLocation: string | undefined) => {
  return {
    name: notification.name,
    settingInterval: notification.settingInterval,
    isTLMP: notification.isTLMP,
    duplicatedFrom: notification.duplicatedFrom,
    reminder: getReminderToAdd(notification, input, language, geoLocation)
  };
};
export const getReminderToUpdate = (notification: NotificationInput) => {
  return {
    installationDate: getNumberFromDate(notification.installationDate),
    replenishmentDate: getNumberFromDate(notification.replenishmentDate),
    notificationDate: getNumberFromDate(notification.notificationDate),
    hasReminder: notification.hasReminder
  };
};
export const getReminderToAdd = (notification: NotificationInput, input: Input, language: string, geoLocation: string | undefined) => {
  return {
    installationDate: getNumberFromDate(notification.installationDate),
    replenishmentDate: getNumberFromDate(notification.replenishmentDate),
    notificationDate: getNumberFromDate(notification.notificationDate),
    hasReminder: notification.hasReminder,
    assetName: notification.name,
    lubricatorType: input.lubricator.displayName,
    calculationUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/calculation/`,
    language: language,
    geoLocation: geoLocation
  };
};
