import { BearingDimensions } from 'domain/BearingDimensions';
import { useEffect, useState } from 'react';
import { convertBearingDimensionsToImperial } from 'services/UnitConversion/UnitConversionService';
import { useAppSelector } from 'store/hooks';
import { roundSignificantNumbers } from 'utils/data/round';
import PrintKeyValue from './layout/PrintKeyValue';

const PrintBearingDimensions = () => {
  const currentDimensions = useAppSelector((state) => state.input.dimensions);
  const unit = useAppSelector((state) => state.data.conditions.unit);

  const [dimensions, setLocalDimensions] = useState<BearingDimensions>(currentDimensions);

  useEffect(() => {
    if (currentDimensions) {
      if (unit === 'imperial') {
        setLocalDimensions(roundDimensions(convertBearingDimensionsToImperial(currentDimensions)));
      } else {
        setLocalDimensions(roundDimensions(currentDimensions));
      }
    }
  }, [unit, currentDimensions]);

  const roundDimensions = (dimensionsToRound: BearingDimensions): BearingDimensions => {
    return {
      width: roundSignificantNumbers(dimensionsToRound.width ?? 55, 2),
      innerDiameter: roundSignificantNumbers(dimensionsToRound.innerDiameter ?? 120, 2),
      outerDiameter: roundSignificantNumbers(dimensionsToRound.outerDiameter ?? 260, 2)
    } as BearingDimensions;
  };

  const getMessageForUnit = (key: string): string => {
    if (unit === 'imperial') {
      return key.replace('metric', 'imperial');
    } else {
      return key;
    }
  };

  return (
    <>
      <PrintKeyValue header={getMessageForUnit('bearingDimensions.innerDiameter.metric')} value={dimensions.innerDiameter?.toString()} />
      <PrintKeyValue header={getMessageForUnit('bearingDimensions.outerDiameter.metric')} value={dimensions.outerDiameter?.toString()} />
      <PrintKeyValue header={getMessageForUnit('bearingDimensions.width.metric')} value={dimensions.width?.toString()} />
    </>
  );
};

export default PrintBearingDimensions;
