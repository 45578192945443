import { Icon, IconColors, Icons } from '@skf-internal/ui-components-react-legacy';

type SizeableIconProps = {
  className?: string;
  title?: string;
  icon: Icons;
  onClick?: () => void;
  disabled?: boolean;
};

const SizeableIcon = ({ className, title, icon, disabled = false, onClick }: SizeableIconProps) => {
  return (
    <div
      className={disabled ? className + ' cursor-not-allowed' : className}
      style={{ lineHeight: '0' }}
      onClick={() => (!disabled && onClick ? onClick() : {})}
      title={title}
    >
      <Icon feIcon={icon} feColor={disabled ? IconColors.Gray : IconColors.Brand} style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default SizeableIcon;
