import { compareAsc } from 'date-fns';

import { NotificationRepository, TrackingEvent, TrackingRepository, TrackingType } from './NotificationRepository';
import LocalRepositoryBase from './Repositories/LocalRepositoryBase';

export enum OfflineSyncStatus {
  None = 'None',
  NothingToSync = 'NothingToSync',
  MeSyncing = 'MeSyncing',
  Syncing = 'Syncing',
  Done = 'Done',
  Error = 'Error'
}

class NotificationSyncService extends LocalRepositoryBase {
  private notificationRepository: NotificationRepository;
  private trackingRepository: TrackingRepository;
  private static syncSingleton: Promise<void> | null = null;
  constructor(notificationRepository: NotificationRepository, trackingRepository: TrackingRepository) {
    super();
    this.notificationRepository = notificationRepository;
    this.trackingRepository = trackingRepository;
  }

  async hasEvents(): Promise<boolean> {
    const offlineEvents = await this.trackingRepository.getOfflineEvents();
    return offlineEvents.length > 0;
  }

  async syncAll(): Promise<void> {
    const synInternal = async () => {
      const ids = (await this.trackingRepository.getOfflineEvents()).reduce((ids, te) => {
        if (!ids.includes(te.notificationId)) {
          ids = [...ids, te.notificationId];
        }
        return ids;
      }, [] as string[]);
      console.log('SYNC:syncService', ids);
      for (const id of ids) {
        console.log('SYNC:syncing', id);
        await this.syncNotification(id);
      }
    };

    if (NotificationSyncService.syncSingleton === null) {
      NotificationSyncService.syncSingleton = synInternal();
      await NotificationSyncService.syncSingleton;
      NotificationSyncService.syncSingleton = null;
    } else {
      await NotificationSyncService.syncSingleton;
    }
  }

  async syncNotification(notificationId: string): Promise<void> {
    let events = (await this.trackingRepository.getOfflineEvents())
      .filter((e) => e.notificationId === notificationId)
      .sort((e1, e2) => compareAsc(e1.timestamp, e2.timestamp));

    if (this.isOfflineNotification(notificationId)) {
      events = await this.handleCreatedOffline(events);
    }

    await this.handleAllEvents(events);
  }

  async handleCreatedOffline(events: TrackingEvent[]): Promise<TrackingEvent[]> {
    const addEvent = events.find((e) => e.type === TrackingType.addNotification);
    if (addEvent) {
      const result = await this.notificationRepository.addNotification(
        addEvent.data.notification,
        addEvent.data.input,
        addEvent.data.result,
        addEvent.data.language,
        addEvent.data.geoLocation
      );

      await this.deleteLocalCalculation(addEvent.notificationId);
      await this.trackingRepository.removeTrackingEvent(addEvent);

      return events.filter((e) => e.type !== TrackingType.addNotification).map((e) => ({ ...e, notificationId: result.id }));
    } else {
      throw new Error('No add event for offline notification');
    }
  }

  async handleAllEvents(events: TrackingEvent[]) {
    for (const event of events) {
      await this.handleEvent(event);
    }
  }

  async handleEvent(event: TrackingEvent) {
    if (!this.isOfflineNotification(event.notificationId)) {
      switch (event.type) {
        case TrackingType.confirmReminder:
          await this.notificationRepository.confirmReminder(event.notificationId, event.data.confirmReminder);
          break;
        case TrackingType.deleteNotification:
          await this.notificationRepository.deleteNotification(event.notificationId);
          break;
        case TrackingType.updatNotification:
          await this.notificationRepository.updateNotification(event.notificationId, event.data.notification);
          break;
        default:
          throw new Error('Unknown tracking type');
      }
    }
    await this.trackingRepository.removeTrackingEvent(event);
  }

  isOfflineNotification(notificationId: string): boolean {
    return notificationId.startsWith('offline-');
  }
}

export default NotificationSyncService;
