import { Input } from 'domain/Input';
import { Lubricator } from 'domain/Lubricator';

export default function filterLubricators(input: Input, lubricators: Array<Lubricator>): Array<Lubricator> {
  return lubricators.filter((l) => filterLubricator(input, l));
}

export function filterLubricator(input: Input, lubricator: Lubricator): boolean {
  return checkCompability(input, lubricator) && checkAmbientTemperature(input, lubricator);
}

export function checkCompability(input: Input, lubricator: Lubricator): boolean {
  if (input.grease.id === 'Unspecified') {
    return true;
  }
  return lubricator.compatibleGreases.some((g) => g.id === input.grease.id);
}

export function checkAmbientTemperature(input: Input, lubricator: Lubricator): boolean {
  return input.ambientTemperature.value < lubricator.ambientTemperature.max && input.ambientTemperature.value > lubricator.ambientTemperature.min;
}
