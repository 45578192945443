import localforage from 'localforage';
import { Notification } from 'domain/Notification';
import { StoredCalculation } from 'domain/StoredCalculation';

const notificationsKey = 'notifications';

class LocalRepositoryBase {
  async getLocalNotificationsOrDefault(): Promise<Notification[]> {
    let values = await localforage.getItem<Notification[]>(notificationsKey);
    if (!values) {
      values = [];
    }
    return values;
  }

  async getNotification(notificationId: string): Promise<Notification | undefined> {
    return (await this.getLocalNotificationsOrDefault()).find((n) => n.id === notificationId);
  }

  async storeNotifications(notifications: Notification[]): Promise<void> {
    await localforage.setItem(notificationsKey, notifications);
  }

  async addLocalNotification(notification: Notification): Promise<void> {
    const values = await this.getLocalNotificationsOrDefault();
    await this.storeNotifications([...values, notification]);
  }

  async updateLocalNotification(notificationId: string, notification: Notification): Promise<void> {
    const values = await this.getLocalNotificationsOrDefault();
    await this.storeNotifications([...values.filter((n) => n.id !== notificationId), notification]);
    await this.updateLocalCalculationForNotification(notificationId, notification);
  }

  async updateLocalCalculationForNotification(notificationId: string, notification: Notification): Promise<void> {
    const calculation = await this.getLocalCalculation(notificationId);
    if (calculation) {
      await localforage.setItem(`CALCULATION-${notificationId}`, { ...calculation, notification: notification });
    }
  }
  async storeLocalCalculation(notificationId: string, calculation: { calculation: StoredCalculation; notification: Notification }): Promise<void> {
    await localforage.setItem(`CALCULATION-${notificationId}`, calculation);
  }

  async deleteLocalCalculation(notificationId: string): Promise<void> {
    await localforage.removeItem(`CALCULATION-${notificationId}`);
  }

  async getLocalCalculation(notificationId: string): Promise<{ calculation: StoredCalculation; notification: Notification } | null> {
    return localforage.getItem<{ calculation: StoredCalculation; notification: Notification }>(`CALCULATION-${notificationId}`);
  }
}

export default LocalRepositoryBase;
