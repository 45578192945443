import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import useAuthenticationRequest from './useAuthenticationRequest';
import useIsOfflineAuthenticated from './useIsOfflineAuthenticated';

const AuthenticatedContainer = ({ children }: { children: React.ReactChild | React.ReactChild[] }) => {
  const { isOfflineAuthenticated } = useIsOfflineAuthenticated();
  const request = useAuthenticationRequest(true);

  if (isOfflineAuthenticated !== undefined) {
    return isOfflineAuthenticated ? (
      <>{children}</>
    ) : (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={request}>
        {children}
      </MsalAuthenticationTemplate>
    );
  } else {
    return <></>;
  }
};

export default AuthenticatedContainer;
