import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import CookieService from 'services/Cookies/CookieSettingsService';
import { msalConfig } from '../../services/Authentication/authConfig';

export default function createMsalInstance(): PublicClientApplication {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload &&
      (event.payload as AuthenticationResult).account
    ) {
      msalInstance.setActiveAccount((event.payload as AuthenticationResult).account);
      new CookieService().setHaveBeenAuthenticatedCookie();
    }
  });

  return msalInstance;
}
