import { useMemo } from 'react';
import CookieService from 'services/Cookies/CookieSettingsService';
import releases from './releases.json';

interface Feature {
  header: string;
  info: string;
}

export interface Release {
  id: string;
  header: string;
  intro: string;
  features: Feature[];
}

const cookieService = new CookieService();

const useReleaseInfo = (): [boolean, Release, (release: Release) => void] => {
  const latestRelease = useMemo(() => [...(releases as Release[])].pop(), []);

  const dismissRelease = (release: Release) => {
    cookieService.dismissReleaseVersion(release.id);
  };

  return [
    latestRelease ? !cookieService.isReleaseDismissedCookieSet(latestRelease.id) : false,
    latestRelease ? latestRelease : ({} as Release),
    dismissRelease
  ];
};

export default useReleaseInfo;
