import InteruptiveDialog from 'components/layouts/InteruptiveDialog';
import { Notification } from 'domain/Notification';
import { ComponentType, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { getDefaults } from 'services/Calculation/CalculationService';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDisabled, setInput } from 'store/InputSlice';
import { deleteNotification } from 'store/NotificationSlice';
import useDialogCloser from 'utils/hooks/useDialogCloser';
import useIsAppLocation, { AppLocations } from 'utils/hooks/useIsAppLocation';
import OnClickProps from 'utils/ui/OnClickProps';

const DeleteAction = ({ notification, Icon }: { notification: Notification; Icon: ComponentType<OnClickProps> }) => {
  const intl = useIntl();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dialogCloser = useDialogCloser();
  const dispatch = useAppDispatch();
  const conditions = useAppSelector((state) => state.data.conditions);
  const isCalculation = useIsAppLocation([AppLocations.calculation]);
  const history = useHistory();
  const { logDeviceDeleted } = useAnalyticsLog();

  const onDelete = () => {
    dialogCloser(setIsDialogOpen);
    dispatch(
      deleteNotification({
        notificationId: notification.id,
        callback: () => {
          dispatch(setDisabled(true));
          dispatch(setInput(getDefaults(conditions)));
          logDeviceDeleted();
          if (isCalculation) {
            history.push('/notifications');
          }
        }
      })
    );
  };

  return (
    <>
      <Icon onClick={() => setIsDialogOpen(true)} />
      <InteruptiveDialog
        buttons={{
          primary: { value: intl.formatMessage({ id: 'usercalculations.delete.delete' }), onClick: onDelete },
          secondary: { value: intl.formatMessage({ id: 'usercalculations.cancelbutton' }), onClick: () => setIsDialogOpen(false) }
        }}
        open={isDialogOpen}
        title={intl.formatMessage({ id: 'usercalculations.delete.header' })}
        OnClose={() => setIsDialogOpen(false)}
      >
        <p className="mb-8">{intl.formatMessage({ id: 'usercalculations.delete.description' }, { name: notification.name })}</p>
      </InteruptiveDialog>
    </>
  );
};

export default DeleteAction;
