var existingPromise: Promise<string> | null = null;

const getLocation = async (): Promise<string> => {
  const fetchLocation = async (): Promise<string> => {
    try {
      const response = await fetch('/location', {
        method: 'GET',
        cache: 'no-cache'
      });
      const country = response.headers.get('CloudFront-Viewer-Country');
      return country ?? '';
    } catch (error) {
      return '';
    }
  };

  if (existingPromise) {
    return await existingPromise;
  } else {
    existingPromise = fetchLocation();
    return await existingPromise;
  }
};

export default getLocation;
