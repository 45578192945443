import { Icon, IconColors, Icons, Image } from '@skf-internal/ui-components-react-legacy';
import IosShareSvg from 'components/svgs/IosShareSvg';
import useReleaseInfo from 'features/ReleaseInfo/useReleaseInfo';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import CookieService from 'services/Cookies/CookieSettingsService';

const timeout = 60 * 1000;
const cookieService = new CookieService();
const isInstallDismissed = cookieService.isInstallInfoDismissed();

const InstallInfo = () => {
  const isMobileIPhoneSafari = useIPhoneSafariCheck();
  const [isReleaseDismissed] = useReleaseInfo();

  const [showInstallInfo, setShowInstallInfo] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!isInstallDismissed && !isReleaseDismissed) {
      const timer = setTimeout(() => setShowInstallInfo(isMobileIPhoneSafari), timeout);
      return () => clearTimeout(timer);
    }
  }, [isMobileIPhoneSafari, isReleaseDismissed]);

  const onDismissClick = () => {
    setShowInstallInfo(false);
    cookieService.setInstallInfoDismiss();
  };
  const getIcon = () => {
    return (
      <span className="text-white w-4 h-4 inline-flex">
        <IosShareSvg />
      </span>
    );
  };

  return showInstallInfo ? (
    <div className="fixed bottom-0 z-10 mb-0 w-full bg-primary-800 text-gray-25 p-3 flex flex-row justify-between">
      <div className="flex flex-row justify-between">
        <Image className="my-auto" feHeight="32px" src="/logo192.png" />
        <div className="my-auto ml-3 mr-2">{intl.formatMessage({ id: 'installinfo.message' }, { icon: getIcon() })}</div>
      </div>
      <div onClick={onDismissClick}>
        <Icon feIcon={Icons.Close} feColor={IconColors.White} />
      </div>
    </div>
  ) : (
    <></>
  );
};

const useIPhoneSafariCheck = (defaultResponse?: boolean): boolean => {
  if (defaultResponse !== undefined) {
    return defaultResponse;
  }

  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = (iOS || iPad) && webkit;

  return iOSSafari;
};

export default InstallInfo;
