import { Button, Icon, IconColors, IconInteractive, Icons, useDropdown } from '@skf-internal/ui-components-react-legacy';
import { Notification } from 'domain/Notification';
import { useRef } from 'react';
import BuyOnlineAction from '../Actions/BuyOnlineAction';
import OnClickProps from 'utils/ui/OnClickProps';
import { useIntl } from 'react-intl';
import DuplicateAction from '../Actions/DuplicateAction';
import DeleteAction from '../Actions/DeleteAction';
import ReplenishmentAction from '../Actions/ReplenishmentAction';

const ActionButtons = ({ notification }: { notification: Notification }) => {
  const { addPopover } = useDropdown();
  const dropdownRef = useRef<HTMLButtonElement | null>(null);
  const intl = useIntl();
  const onActionClick = (innerClick: () => void) => {
    // This doesn't work in Ferris for some reasen, below is a workaround
    // if (removePopover) {
    //     removePopover();
    // }
    const element = document.querySelector("[data-comp='portal']:not([data-portals]");
    if (element) {
      console.log('element', element);
      (element as unknown as { style: { display: string } }).style.display = 'none';
    }
    innerClick();
  };

  const openDropDown = () => {
    addPopover(
      {
        content: (
          <div className="w-40">
            <DuplicateAction
              notification={notification}
              Icon={({ onClick }: OnClickProps) => (
                <PopupItem onClick={() => onActionClick(onClick)} icon={Icons.Duplicate} labelId="usercalculations.duplicate.tooltip" />
              )}
            />
            <DeleteAction
              notification={notification}
              Icon={({ onClick }: OnClickProps) => (
                <PopupItem onClick={() => onActionClick(onClick)} icon={Icons.Trash} labelId="usercalculations.delete.tooltip" />
              )}
            />
            <ReplenishmentAction
              notification={notification}
              Child={({ onClick, disabled }: OnClickProps) => (
                <PopupItem onClick={() => onActionClick(onClick)} disabled={disabled} icon={Icons.Sync} labelId="usercalculations.replenishment.tooltip" />
              )}
            />
          </div>
        ),
        feType: 'popup'
      },
      dropdownRef.current
    );
  };

  return (
    <div className="flex w-fill h-full justify-end items-center">
      <BuyOnlineAction
        notification={notification}
        Child={({ onClick }: OnClickProps) => (
          <Button feType="secondary" feSize="sm" onClick={onClick} className="mr-4 cursor-pointer" feIcon={{ feIcon: Icons.OpenInNew, position: 'right' }}>
            {intl.formatMessage({ id: 'lubrication.shoponline' })}
          </Button>
        )}
      />
      <IconInteractive
        className="cursor-pointer"
        aria-label={notification.id}
        onClick={openDropDown}
        as="button"
        feIcon={Icons.Kebab}
        feColor={IconColors.Brand}
        ref={dropdownRef}
      />
    </div>
  );
};

const PopupItem = ({ onClick, labelId, icon, disabled }: { onClick: () => void; labelId: string; icon: Icons; disabled?: boolean }) => {
  const intl = useIntl();

  const containerClasses = 'w-full flex my-2' + (disabled ? 'text-gray-200' : ' text-primary-600 hover:text-primary-800 cursor-pointer');

  return (
    <div onClick={() => (disabled ? 0 : onClick())} className={containerClasses}>
      <Icon feIcon={icon} feColor={disabled ? IconColors.Gray : IconColors.Brand} />
      <span className="mx-2">{intl.formatMessage({ id: labelId })}</span>
    </div>
  );
};

export default ActionButtons;
