import TableBase, { SortColumn } from './TableBase';
import { Notification } from 'domain/Notification';
import { TableRow } from '@skf-internal/ui-components-react-legacy';
import TimeLeft, { getTotalDaysRemaining } from './TimeLeft';
import ShortDateWithText from 'components/layouts/ShortDateWithText';
import CalculationLink from './CalculationLink';
import { useIntl } from 'react-intl';

const MobileTable = () => {
  const intl = useIntl();

  const headers: { name: string; sortColumn: SortColumn; className?: string }[] = [
    { name: intl.formatMessage({ id: 'usercalculations.table.header.name' }), sortColumn: SortColumn.name, className: 'text-xs' },
    { name: intl.formatMessage({ id: 'usercalculations.table.header.timeLeft' }), sortColumn: SortColumn.timeLeft, className: 'text-xs' }
  ];

  const getTableRow = (notification: Notification): TableRow => {
    return {
      cells: [
        { children: <MobileTableInfo notification={notification} /> },
        {
          children: (
            <TimeLeft
              timeLeft={getTotalDaysRemaining(
                notification.reminder.createdAt,
                notification.reminder.installationDate,
                notification.reminder.replenishmentDate,
                notification.reminder.notificationDate
              )}
            />
          ),
          scope: 'col'
        }
      ]
    };
  };

  return (
    <TableBase
      displayHeaders={headers}
      showActions={false}
      getTableRow={getTableRow}
      showInfoBox={false}
      header={intl.formatMessage({ id: 'usercalculations.table.heading' })}
      description={intl.formatMessage({ id: 'usercalculations.table.description' })}
    />
  );
};

const MobileTableInfo = ({ notification }: { notification: Notification }) => {
  return (
    <div className="flex flex-col max-w-tableCell overflow-hidden">
      <CalculationLink className="font-bold text-xs" value={notification.name} id={notification.id} />
      <ShortDateWithText className="text-xs" date={notification.reminder.replenishmentDate} titleid="usercalculations.view.replenishment" />
      {notification.reminder.hasReminder && (
        <ShortDateWithText className="text-xs" date={notification.reminder.notificationDate} titleid="usercalculations.view.notification" />
      )}
    </div>
  );
};

export default MobileTable;
