import { Input } from 'domain/Input';

export default function calculateRelubricationInterval(input: Input): number {
  return calculate(
    input.dimensions.outerDiameter ?? 260,
    input.dimensions.innerDiameter ?? 120,
    input.bearingType.bearingFactor,
    input.rotatingOuterRing.value,
    input.speed ?? 1800
  );
}

export function calculate(outerDiameter: number, innerDiameter: number, bearingFactor: number, outerRingRotation: boolean, speed: number): number {
  const diameter = outerRingRotation ? outerDiameter : (outerDiameter + innerDiameter) / 2;
  return 30000 * Math.exp((-5.62 * (speed * diameter * bearingFactor)) / 1000000);
}
