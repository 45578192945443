import { MediaQueries, PopoverProvider, useMediaQuery } from '@skf-internal/ui-components-react-legacy';
import InfoBox from 'components/layouts/InfoBox';
import AuthenticatedContainer from 'features/Authentication/AuthenticatedContainer';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getNotifications } from 'store/NotificationSlice';
import DesktopTable from './NotificationTable/DesktopTable';
import MobileTable from './NotificationTable/MobileTable';
import Error from './CurrentNotification/Error';

const Notifications = () => {
  const isMobile = useMediaQuery(MediaQueries.MobileMax);
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.notifications.error);

  useEffect(() => {
    dispatch(getNotifications(''));
  }, [dispatch]);

  return (
    <AuthenticatedContainer>
      <PopoverProvider>
        <div className="bg-gray-25 mt-16">
          {error ? (
            <div className="w-full mx-auto sm:max-w-7xl pt-2 relative">
              <InfoBox className="">
                <Error />
              </InfoBox>
            </div>
          ) : isMobile ? (
            <MobileTable />
          ) : (
            <DesktopTable />
          )}
        </div>
      </PopoverProvider>
    </AuthenticatedContainer>
  );
};

export default Notifications;
