import { useIntl } from 'react-intl';

const PrintKeyValue = ({ header, value, headerText }: { header?: string | null; value: string | null | undefined; headerText?: string }) => {
  const intl = useIntl();

  const getHeading = () => {
    if (headerText) {
      return headerText;
    } else if (header) {
      return intl.formatMessage({ id: header });
    } else {
      return '';
    }
  };

  return (
    <div className="flex mb-1 border">
      <span className="font-bold w-1/2 border-r p-1">{getHeading()}</span>
      <span className="w-1/2 p-1">{value}</span>
    </div>
  );
};

export default PrintKeyValue;
