export const appendClass = (classes: string, classToApend: string): string => {
  return [...classes.split(' '), classToApend].join(' ');
};

export const appendClassCondition = (classes: string, classToApendOnTrue: string, classToApendOnFalse: string, condition: boolean | undefined): string => {
  if (condition) {
    return appendClass(classes, classToApendOnTrue);
  } else {
    return appendClass(classes, classToApendOnFalse);
  }
};
