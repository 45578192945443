import { useCallback } from 'react';
import { getFirebaseSyncRepository } from 'services/Analytics/FirebaseRepositoryFactory';
import useDataSync, { SyncEventType } from './useDataSync';

const syncRepository = getFirebaseSyncRepository();

const useFirebaseSync = (connected: boolean) => {
  useDataSync({
    id: 'firebaseEvents',
    shouldInitiate: connected,
    shouldSync: () => syncRepository.hasEvents(),
    eventHandler: (eventType: SyncEventType) => {
      switch (eventType) {
        case SyncEventType.doNotSync:
          console.log('FIREBASE', 'Events synced in other tab');
          break;
        case SyncEventType.nothingToSync:
          console.log('FIREBASE', 'Nothing to sync');
          break;
        case SyncEventType.doSync:
          sync();
          break;
        case SyncEventType.syncDone:
          console.log('FIREBASE', 'Done syning');
          break;
        default:
          break;
      }
    }
  });

  const sync = useCallback(() => {
    console.log('FIREBASE', 'Syncing');
    syncRepository
      .syncEvents()
      .then(() => console.log('FIREBASE', 'I synced'))
      .catch((err) => {
        console.log('FIREBASE', err);
      });
  }, []);
};

export default useFirebaseSync;
