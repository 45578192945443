import FirebaseRepository from '../FirebaseRepository';
import FirebaseOfflineRepository from './FirebaseOfflineRepository';

class FirebaseSyncRepository extends FirebaseOfflineRepository {
  private eventRepository: FirebaseRepository;
  constructor(eventRepository: FirebaseRepository) {
    super();
    this.eventRepository = eventRepository;
  }

  async syncEvents(): Promise<void> {
    const events = await this.getTrackingEventsOrDefault();
    for (const event of events) {
      this.eventRepository.logEvent(event.eventName, event.eventParams);
      await this.removeEvent(event);
    }
  }

  async hasEvents(): Promise<boolean> {
    const events = await this.getTrackingEventsOrDefault();
    return events.length > 0;
  }
}

export default FirebaseSyncRepository;
