import { AdditionalOption } from 'domain/BearingType';
import ReplenishmentOption from './ReplenishmentOption';

type ExistingOption = {
  option: AdditionalOption;
  Component: JSX.Element;
};

const existingOptions: ExistingOption[] = [
  {
    option: AdditionalOption.replenishment,
    Component: <ReplenishmentOption />
  }
];

const BearingAdditionalOptions = ({ options }: { options?: AdditionalOption[] }) => {
  const renderOption = (option: AdditionalOption) => {
    const existingOption = existingOptions.find((o) => o.option === option);
    if (existingOption) {
      return (
        <div className="mt-4" key={option}>
          {existingOption.Component}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderOptions = (options: AdditionalOption[]) => {
    return <>{options.map(renderOption)}</>;
  };

  return options ? renderOptions(options) : <></>;
};

export default BearingAdditionalOptions;
