import { Lubricator, LubricatorSetting, LubricatorSettingFormatter, LubricatorSettingImage } from 'domain/Lubricator';

export default function getLubricationSetting(
  lubricator: Lubricator,
  greaseQuantity: number,
  correctedRelubricationInterval: number,
  feedRate: number,
  ambientTemperature: number
): LubricatorSetting {
  if (IsLAGD(lubricator)) {
    return getLubricatorSettingForLAGD(lubricator, greaseQuantity, correctedRelubricationInterval, ambientTemperature);
  } else if (IsTLMR(lubricator)) {
    return getLubricatorSettingForTLMR(lubricator, feedRate);
  } else if (IsTLSD(lubricator)) {
    return getLubricatorSettingForTLSD(lubricator, feedRate);
  } else if (IsTLMP(lubricator)) {
    return getLubricatorSettingForTLMP(lubricator, feedRate);
  } else {
    throw Error(`Cannot calculate setting for ${lubricator.id}`);
  }
}

export function getLubricatorSettingForLAGD(
  lubricator: Lubricator,
  greaseQuantity: number,
  correctedRelubricationInterval: number,
  ambientTemperature: number
): LubricatorSetting {
  //procedure TfrmMain.calcDS;
  const v = greaseQuantity / correctedRelubricationInterval;
  const resavoarSize = mlToG(IsLAGD60(lubricator) ? 60 : 120);
  let DS = resavoarSize / (v * getAverageHoursPerMonth());

  if (ambientTemperature < -10) {
    DS = DS / 2;
  }

  if (ambientTemperature > 40) {
    DS = DS * 2;
  }

  DS = Math.round(DS);

  let isLowWarning = false;
  let isHighWarning = false;

  if (DS < 1) {
    DS = -1;
    isLowWarning = true;
  }

  if (DS > 11) {
    DS = -1;
    isHighWarning = true;
  }

  return {
    text: getSettingsText(lubricator, DS),
    imageRef: DS > -1 ? `/images/settings/lagd/ds${DS}.png` : '/images/settings/lagd/dsx.png',
    highWarning: isLowWarning, //Yes, this is intentional
    lowWarning: isHighWarning,
    dialSetting: DS,
    intervalDuration: getIntervalMonths(DS)
  } as LubricatorSettingImage;
}

export function getLubricatorSettingForTLMP(lubricator: Lubricator, feedRate: number): LubricatorSetting {
  //TfrmMain.SetDial_TLMP
  const qfMl = feedRate / 0.9;
  const pauseTime = 24 / (qfMl / 0.2);

  const minPa_TLMP = 20.0 / 60.0; //  0 hours, 20 minutes (14.4 ml/day)
  const maxPa_TLMP = 59 + 59.0 / 60.0; // 59 hours, 59 minutes (0.08 ml/day)

  const time = getHoursMinutesFromNumberOfHours(pauseTime);
  const resavoarSize = 900; //900 ml
  const totalDaysUntilEmpty = resavoarSize / qfMl;

  return {
    text: pauseTime > maxPa_TLMP || pauseTime < minPa_TLMP ? 'lubricator.tmpl.result.noresult' : 'lubricator.tmpl.result.format',
    highWarning: pauseTime < minPa_TLMP,
    lowWarning: pauseTime > maxPa_TLMP,
    dialSetting: 0,
    formattingValues: [time.hour, time.minutes],
    intervalDuration: getIntervalDays(totalDaysUntilEmpty)
  } as LubricatorSettingFormatter;
}

export function getLubricatorSettingForTLMR(lubricator: Lubricator, feedRate: number): LubricatorSetting {
  //function TfrmMain.Calc_TLMR(Qf : Double) : Integer;
  const feedRateMl = gToMl(feedRate);
  const factor = isTLMR380(lubricator) ? 380 / 120 : 1;

  const steps = [
    { qfMl: 0.14, n: 99 },
    { qfMl: 0.18, n: 24 },
    { qfMl: 0.24, n: 18 },
    { qfMl: 0.36, n: 12 },
    { qfMl: 0.49, n: 9 },
    { qfMl: 0.86, n: 6 },
    { qfMl: 1.54, n: 3 },
    { qfMl: 2.47, n: 2 },
    { qfMl: 4.94, n: 1 }
  ];

  const result = steps.find((s) => feedRateMl < s.qfMl * factor);

  const lubricatorName = lubricator.id.split(' ').join('').replace('ml', '');

  let text: string = '';
  if (!result) {
    text = `lubricator.${lubricatorName}.settings.offhigh.text`;
  } else if (result.n === 99) {
    text = `lubricator.${lubricatorName}.settings.offlow.text`;
  } else {
    text = `lubricator.${lubricatorName}.settings.${result.n}months.text`;
  }

  let imageRef: string = '';
  if (!result || result.n === 99) {
    imageRef = `/images/settings/${lubricatorName}/${isTLMR380(lubricator) ? 'ds380' : 'ds120'}_off.png`;
  } else {
    imageRef = `/images/settings/${lubricatorName}/${isTLMR380(lubricator) ? 'ds380' : 'ds120'}_${result.n}m.png`;
  }

  return {
    text: text,
    imageRef: imageRef,
    highWarning: !result,
    lowWarning: result ? result.n === 99 : false,
    dialSetting: result?.n,
    intervalDuration: result?.n ? getIntervalMonths(result?.n) : 0
  } as LubricatorSettingImage;
}

export function getLubricatorSettingForTLSD(lubricator: Lubricator, feedRate: number): LubricatorSetting {
  //function TfrmMain.Calc_TLSD(Qf : Double) : Integer;
  const feedRateMl = gToMl(feedRate);
  const factor = isTLSD250(lubricator) ? 2 : 1;

  const steps = [
    { qfMl: 0.32, n: 99 },
    { qfMl: 0.37, n: 12 },
    { qfMl: 0.42, n: 10 },
    { qfMl: 0.48, n: 9 },
    { qfMl: 0.59, n: 8 },
    { qfMl: 0.81, n: 6 },
    { qfMl: 1.16, n: 4 },
    { qfMl: 1.61, n: 3 },
    { qfMl: 2.68, n: 2 },
    { qfMl: 5.14, n: 1 }
  ];

  const result = steps.find((s) => feedRateMl < s.qfMl * factor);

  const lubricatorName = lubricator.id.split(' ').join('');

  let text: string = '';
  if (!result) {
    text = `lubricator.${lubricatorName}.settings.offhigh.text`;
  } else if (result.n === 99) {
    text = `lubricator.${lubricatorName}.settings.offlow.text`;
  } else {
    text = `lubricator.${lubricatorName}.settings.${result.n}months.text`;
  }

  const imageRef = !result || result.n === 99 ? `/images/settings/tlsd/tlmr_off.png` : `/images/settings/tlsd/tlmr_${result.n}.png`;

  return {
    text: text,
    imageRef: imageRef,
    highWarning: !result,
    lowWarning: result ? result.n === 99 : false,
    dialSetting: result?.n,
    intervalDuration: result?.n ? getIntervalMonths(result?.n) : 0
  } as LubricatorSettingImage;
}

export function gToMl(g: number): number {
  // Convert a feed rate expressed in grams per timeunit to ml per timeunit
  // This simple conversion is reasonably valid for all greases used by SKF
  return g / 0.9;
}

export function mlToG(ml: number): number {
  // Convert a feed rate expressed in grams per timeunit to ml per timeunit
  // This simple conversion is reasonably valid for all greases used by SKF
  return ml * 0.9;
}

export function getAverageHoursPerMonth(): number {
  return (365 / 12) * 24;
}

export function isTLSD250(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlsd 250';
}

export function isTLMR380(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlmr 380ml';
}

export function IsLAGD60(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'lagd 60';
}

export function IsLAGD(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'lagd 60' || lubricator.id.toLocaleLowerCase() === 'lagd 125';
}

export function IsTLMR(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlmr 120ml' || isTLMR380(lubricator);
}

export function IsTLSD(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlsd 125' || lubricator.id.toLocaleLowerCase() === 'tlsd 250';
}

export function IsTLMP(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlmp';
}

export function getSettingsText(lubricator: Lubricator, setting: number) {
  const id = lubricator.id.toLocaleLowerCase().split(' ').join('');
  const position = setting > 0 ? `position${setting}` : 'emptyLow';
  return `lubricator.${id}.settings.${position}.text`;
}

export function getHoursMinutesFromNumberOfHours(hours: number): {
  hour: number;
  minutes: number;
} {
  const d = new Date(0, 0);

  let days = 0;
  while (hours > 24) {
    days++;
    hours = hours - 24;
  }

  d.setSeconds(hours * 60 * 60);
  return {
    hour: d.getHours() + days * 24,
    minutes: d.getMinutes()
  };
}

const milliSecondsPerHour = 60 * 60 * 1000;

export function getIntervalMonths(setting: number): number {
  return getAverageHoursPerMonth() * setting * milliSecondsPerHour;
}

export function getIntervalHoursMinutes(hour: number, minutes: number): number {
  return hour * milliSecondsPerHour + minutes * 60 * 1000;
}

export function getIntervalDays(days: number): number {
  return days * 24 * milliSecondsPerHour;
}
