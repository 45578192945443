import { Input } from 'domain/Input';
import { NotificationInput, Notification, ConfirmInput } from 'domain/Notification';
import { Result } from 'domain/Result';
import { StoredCalculation } from 'domain/StoredCalculation';
import { NotificationRepository } from '../NotificationRepository';
import LocalRepositoryBase from './LocalRepositoryBase';

class LocalOnlineRepository extends LocalRepositoryBase implements NotificationRepository {
  private onlineRepository: NotificationRepository;
  private offlineRepository: NotificationRepository;
  private onlineFirst: boolean;
  constructor(onlineRepository: NotificationRepository, offlineRepository: NotificationRepository, onlineFirst: boolean = false) {
    super();
    this.onlineRepository = onlineRepository;
    this.offlineRepository = offlineRepository;
    this.onlineFirst = onlineFirst;
  }

  async addNotification(
    notification: NotificationInput,
    input: Input,
    result: Result,
    language: string,
    geoLocation: string | undefined
  ): Promise<Notification> {
    const res = await this.onlineRepository.addNotification(notification, input, result, language, geoLocation);

    this.addLocalNotification(res);

    return res;
  }

  async updateNotification(notificationId: string, notification: NotificationInput): Promise<Notification> {
    if (this.isOfflineNotification(notificationId)) {
      return this.offlineRepository.updateNotification(notificationId, notification);
    } else {
      const res = await this.onlineRepository.updateNotification(notificationId, notification);
      await this.updateLocalNotification(notificationId, res);
      return res;
    }
  }

  async getNotifications(): Promise<Notification[]> {
    if (this.onlineFirst) {
      const notifications = await this.onlineRepository.getNotifications();
      this.storeNotifications(notifications);
      return notifications;
    } else {
      let notifications = await this.offlineRepository.getNotifications();
      if (notifications.length === 0) {
        notifications = await this.onlineRepository.getNotifications();
        this.storeNotifications(notifications);
      }
      return notifications;
    }
  }

  async deleteNotification(notificationId: string): Promise<void> {
    if (this.isOfflineNotification(notificationId)) {
      await this.offlineRepository.deleteNotification(notificationId);
    } else {
      await this.onlineRepository.deleteNotification(notificationId);
      let values = await this.getLocalNotificationsOrDefault();
      await this.storeNotifications(values.filter((n) => n.id !== notificationId));
    }
    await this.deleteLocalCalculation(notificationId);
  }

  async getCalculation(notificationId: string): Promise<{ calculation: StoredCalculation; notification: Notification } | null> {
    if (this.onlineFirst) {
      const calculation = await this.onlineRepository.getCalculation(notificationId);
      this.storeLocalCalculation(notificationId, calculation!!);
      return calculation;
    } else {
      let calculation = await this.offlineRepository.getCalculation(notificationId);
      if (!calculation) {
        calculation = await this.onlineRepository.getCalculation(notificationId);
        this.storeLocalCalculation(notificationId, calculation!!);
      }
      return calculation;
    }
  }

  async confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<Notification> {
    if (this.isOfflineNotification(notificationId)) {
      return this.offlineRepository.confirmReminder(notificationId, confirmReminder);
    } else {
      const res = await this.onlineRepository.confirmReminder(notificationId, confirmReminder);
      await this.updateLocalNotification(notificationId, res);
      return res;
    }
  }

  isOfflineNotification(notificationId: string): boolean {
    return notificationId.startsWith('offline-');
  }
}

export default LocalOnlineRepository;
