import axios, { AxiosRequestHeaders } from 'axios';
import { Input } from 'domain/Input';
import { NotificationInput, Notification, ConfirmInput } from 'domain/Notification';
import { Result } from 'domain/Result';
import { StoredCalculation } from 'domain/StoredCalculation';
import { getToken } from 'services/Authentication/apiToken';
import { protectedResources } from 'services/Authentication/authConfig';
import createStoredCalculation from 'services/StoredCalculation/createStored';
import { createConfirmReminder } from '../Conversions/createConfirmReminder';
import { createNotification } from '../Conversions/createNotification';
import { createNotificationToAdd, createNotificationToUpdate } from '../Conversions/createNotificationToAdd';
import { NotificationRepository } from '../NotificationRepository';

type ApiConfig = {
  baseUrl: string;
  xApiKey: string;
};

const config = {
  baseUrl: process.env.REACT_APP_NOTIFICATION_BASE_URL,
  xApiKey: process.env.REACT_APP_NOTIFICATION_API_KEY
} as ApiConfig;

class OnlineRepository implements NotificationRepository {
  async addNotification(
    notification: NotificationInput,
    input: Input,
    result: Result,
    language: string,
    geoLocation: string | undefined
  ): Promise<Notification> {
    const response = await axios.put(config.baseUrl + 'notification/', this.createDataToAdd(notification, input, result, language, geoLocation), {
      headers: await this.getHeaders()
    });
    return createNotification(response.data);
  }

  async updateNotification(notificationId: string, notification: NotificationInput): Promise<Notification> {
    const response = await axios.post(config.baseUrl + 'notification/' + encodeURIComponent(notificationId), createNotificationToUpdate(notification), {
      headers: await this.getHeaders()
    });
    return createNotification(response.data);
  }

  async getNotifications(): Promise<Notification[]> {
    const response = await axios.get(config.baseUrl + 'notification/', {
      headers: await this.getHeaders()
    });
    return response.data.map((n: any) => createNotification(n));
  }

  async deleteNotification(notificationId: string): Promise<void> {
    return axios.delete(config.baseUrl + 'notification/' + encodeURIComponent(notificationId), {
      headers: await this.getHeaders()
    });
  }

  async getCalculation(notificationId: string): Promise<{ calculation: StoredCalculation; notification: Notification }> {
    const response = await axios.get(config.baseUrl + 'calculation/' + notificationId, {
      headers: await this.getHeaders()
    });
    return response.data as { calculation: StoredCalculation; notification: Notification };
  }

  async confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<Notification> {
    const response = await axios.post(config.baseUrl + 'reminder/confirm/' + notificationId, createConfirmReminder(confirmReminder), {
      headers: await this.getHeaders()
    });
    return createNotification(response.data);
  }

  async getHeaders(): Promise<AxiosRequestHeaders> {
    const token = await getToken(protectedResources.notification.scopes.readWrite);
    return { 'x-api-key': config.xApiKey, ...token };
  }

  createDataToAdd(notification: NotificationInput, input: Input, result: Result, language: string, geoLocation: string | undefined) {
    return {
      notification: createNotificationToAdd(notification, input, language, geoLocation),
      calculation: createStoredCalculation(input, result)
    };
  }
}

export default OnlineRepository;
