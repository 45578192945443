import { getLocalDateString } from 'services/Notifications/dateCalculations';
import { useAppSelector } from 'store/hooks';
import useGeoLocation from 'utils/hooks/useGeoLocation';

export type ShortDateProps = {
  date: string | undefined;
  fallBack?: string;
};

const ShortDate = ({ date, fallBack }: ShortDateProps) => {
  const locale = useAppSelector((state) => state.setting.intl.locale);
  const geoLocation = useGeoLocation();
  if (date) {
    const output = getLocalDateString(date, locale, geoLocation);
    return <>{output}</>;
  } else if (fallBack) {
    return <>{fallBack}</>;
  } else {
    return <></>;
  }
};

export default ShortDate;
