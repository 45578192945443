import { Input } from 'domain/Input';
import { Result } from 'domain/Result';
import { StoredCalculation, StoredGreaseAvalability, StoredGreaseDisabledReason, StoredInput, StoredResult } from 'domain/StoredCalculation';

const createStoredCalculation = (input: Input, result: Result): StoredCalculation => {
  return {
    input: createInput(input),
    result: createResult(result)
  } as StoredCalculation;
};

export const createInput = (input: Input): StoredInput => {
  return {
    bearingTypeId: input.bearingType.id,
    dimensions: input.dimensions,
    speed: input.speed,
    hours: input.hours,
    temperature: input.temperature.value,
    contamination: input.contamination.value,
    load: input.load.value,
    shockLoad: input.shockLoad.value,
    ambientTemperature: input.ambientTemperature.value,
    shaftOrientation: input.shaftOrientation.value,
    replenishment: input.replenishment.value,
    rotatingOuterRing: input.rotatingOuterRing.value,
    greaseId: input.grease.id,
    lubricatorId: input.lubricator.id,
    manualBearingSelection: input.manualBearingSelection,
    bearingDesignation: input.bearingDesignation
  };
};

export const createResult = (result: Result): StoredResult => {
  return {
    relubricationInterval: result.relubricationInterval,
    correctedRelubricationInterval: result.correctedRelubricationInterval,
    feedRate: result.feedRate,
    greaseQuantity: result.greaseQuantity,
    warnings: result.warnings,
    availableGreases: result.availableGreases.map(
      (gav) =>
        ({
          greaseId: gav.grease.id,
          isDisabled: gav.isDisabled,
          disabledReason: gav.disabledReason.map((r) => r as string as StoredGreaseDisabledReason)
        } as StoredGreaseAvalability)
    ),
    availableLubricatorIds: result.availableLubricators.map((l) => l.id),
    lubricatorSetting: result.lubricatorSetting,
    hideResult: result.hideResult,
    validation: result.validation
  };
};

export default createStoredCalculation;
