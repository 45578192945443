import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

import store, { setDefaults } from 'store/store';

import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import CookieService from 'services/Cookies/CookieSettingsService';
import createMsalInstance from 'features/Authentication/msalInstanceFactory';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const cookieService = new CookieService();

setDefaults(store, cookieService.getLanguageCookie(), cookieService.getUnitCookie());

export const msalInstance = createMsalInstance();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <App instance={msalInstance} />
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (registration.waiting) {
//       registration.waiting.postMessage({ type: 'SKIP_WAITING' })
//     }
//   }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
