import { Icon, IconColors, Icons, IconSizes } from '@skf-internal/ui-components-react-legacy';
import MenuButton from 'components/layouts/MenuButton';
import MenuIcon from 'components/layouts/MenuIcon';
import NavigationButton from 'components/layouts/NavigationButton';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import useOuterClick from 'utils/hooks/useOuterClick';

import Authenticator, { AnonymousProps, AuthenticatedProps } from './Authenticator';

const UserNavigationDesktop = () => {
  return (
    <div className="hidden sm:flex flex-grow h-full justify-end ">
      <Authenticator Anonymous={DesktopAnonymous} Authenticated={DesktopAuthenticated} />
    </div>
  );
};

const DesktopAuthenticated = ({ onLogout, onProfile }: AuthenticatedProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const connected = useAppSelector((state) => state.setting.connected);
  const history = useHistory();
  const ref = useOuterClick(() => {
    setIsMenuOpen(false);
  });
  return (
    <ButtonContainer canExpand isExpanded={isMenuOpen} containerRef={ref}>
      <NavigationButton headerid="authentication.myaccount" onClick={() => setIsMenuOpen(!isMenuOpen)} icon={Icons.User} />
      <div className="h-full flex flex-col justify-center items-center ml-2">
        <Icon feIcon={Icons.ChevronDown} feColor={IconColors.White} feSize={IconSizes.Lg} />{' '}
      </div>
      {isMenuOpen ? (
        <div className="absolute bg-primary-700 min-w-navigation bottom-auto sm:top-full right-0 flex flex-col p-4">
          <MenuButton
            disabled={!connected}
            textid="authentication.profile"
            onClick={() => onProfile()}
            icon={<MenuIcon disabled={!connected} icon={Icons.Settings} />}
          />
          <MenuButton
            textid="authentication.calculations"
            onClick={() => {
              history.push('/notifications');
              setIsMenuOpen(false);
            }}
            icon={<MenuIcon icon={Icons.ListGroup} />}
          />
          <MenuButton
            disabled={!connected}
            textid="authentication.logout"
            onClick={() => onLogout()}
            icon={<MenuIcon disabled={!connected} icon={Icons.LogOut} />}
          />
        </div>
      ) : (
        <></>
      )}
    </ButtonContainer>
  );
};

const DesktopAnonymous = ({ onLogin }: AnonymousProps) => {
  const connected = useAppSelector((state) => state.setting.connected);
  return (
    <ButtonContainer>
      <NavigationButton disabled={!connected} headerid="authentication.login" onClick={onLogin} icon={Icons.User} />
    </ButtonContainer>
  );
};

const ButtonContainer = ({
  isExpanded,
  canExpand,
  containerRef,
  children
}: {
  isExpanded?: boolean;
  canExpand?: boolean;
  containerRef?: React.MutableRefObject<HTMLDivElement | null>;
  children: JSX.Element | JSX.Element[];
}) => {
  let classes = 'flex h-full justify-end pr-4 pl-8 ';
  if (canExpand) {
    classes += 'relative ';
  }
  if (isExpanded) {
    classes += 'bg-primary-700 ';
  }
  return (
    <div className={classes} ref={containerRef}>
      {children}
    </div>
  );
};

export default UserNavigationDesktop;
