import { Colors, Icon, IconColors, Icons } from '@skf-internal/ui-components-react-legacy';
import ColorProgress from 'components/layouts/ColorProgress';
import { differenceInDays, isFuture } from 'date-fns';
import { useIntl } from 'react-intl';
import { getLocalDateString } from 'services/Notifications/dateCalculations';
import { useAppSelector } from 'store/hooks';
import useGeoLocation from 'utils/hooks/useGeoLocation';

export type TimeLeftProps = {
  timeLeft: { totalDays: number; remaining: number; hide?: boolean; inFuture: boolean; notificationDate?: string };
  className?: string;
};

export const getTotalDaysRemaining = (createdDate: string, installationDate: string, replenishmentDate: string, notificationDate?: string) => {
  const installation = new Date(installationDate);
  const replenishment = new Date(replenishmentDate);
  const now = new Date();

  if (isFuture(installation)) {
    return {
      totalDays: 0,
      remaining: 0,
      hide: false,
      inFuture: true
    };
  } else {
    return {
      totalDays: differenceInDays(replenishment.getTime(), installation.getTime()),
      remaining: differenceInDays(replenishment.getTime(), now.getTime()),
      hide: false,
      inFuture: false,
      notificationDate: notificationDate
    };
  }
};

const TimeLeft = ({ timeLeft, className }: TimeLeftProps) => {
  const intl = useIntl();
  const locale = useAppSelector((state) => state.setting.intl.locale);
  const geoLocation = useGeoLocation();

  const Text = ({ text }: { text: string }) => {
    return <div className="text-xs leading-5">{text}</div>;
  };

  const getTitle = (remaining: number, notificationDate?: string): string => {
    if (notificationDate) {
      return intl.formatMessage(
        { id: 'usercalculations.days.tooltip.notification' },
        { remaining: remaining, notificationDate: getLocalDateString(notificationDate, locale, geoLocation) }
      );
    } else {
      return intl.formatMessage({ id: 'usercalculations.days.tooltip.noNotification' }, { remaining: remaining });
    }
  };

  const showTimeLeft = (progress: number, color: Colors) => {
    return (
      <>
        <Text text={intl.formatMessage({ id: 'usercalculations.days' }, { remaining: timeLeft.remaining, total: timeLeft.totalDays })} />
        <ColorProgress title={getTitle(timeLeft.remaining, timeLeft.notificationDate)} foreColor={color} backColor={Colors.Gray300} value={progress} />
      </>
    );
  };

  const showExpired = (color: Colors) => {
    return (
      <>
        <div className="text-danger-600 w-full flex justify-between">
          <Text text={intl.formatMessage({ id: 'usercalculations.daysOverdue' }, { days: Math.abs(timeLeft.remaining) })} />
          <Icon feColor={IconColors.Red} feIcon={Icons.WarningDiamond} />
        </div>
        <ColorProgress foreColor={color} backColor={Colors.White} borderColor={Colors.RedBase} value={0} />
      </>
    );
  };

  const showInFuture = () => {
    return (
      <>
        <div className="w-full flex justify-between">
          <Text text={intl.formatMessage({ id: 'usercalculations.notinstalled' })} />
        </div>
        <ColorProgress foreColor={Colors.Gray400} backColor={Colors.Gray400} borderColor={Colors.Gray300} value={0} />
      </>
    );
  };

  if (!timeLeft.hide) {
    const progress = (timeLeft.remaining / timeLeft.totalDays) * 100;
    const color = timeLeft.remaining < 30 ? (timeLeft.remaining < 8 ? Colors.RedBase : Colors.YellowDark) : Colors.GreenBase;

    return (
      <div className={'min-w-36 flex flex-col justify-around ' + className}>
        {timeLeft.inFuture ? showInFuture() : timeLeft.remaining > 0 ? showTimeLeft(progress, color) : showExpired(color)}
      </div>
    );
  } else {
    return <></>;
  }
};
export default TimeLeft;
