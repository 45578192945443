import BorderBox from 'components/layouts/BorderBox';
import { useIntl } from 'react-intl';
import useAnchor from 'utils/hooks/useAnchor';
import DispenseRates from './DispenseRates';
import GreaseSelection from './GreaseSelection';
import LubricationSelection from './LubricationSelection';
import LubricatorDescription from './LubricatorDescription';

const Lubrication = () => {
  const intl = useIntl();
  const anchor = useAnchor('lubricationselection');

  return (
    <div ref={anchor}>
      <BorderBox header={intl.formatMessage({ id: 'lubrication.header' })}>
        <LubricationSelection />
        <DispenseRates />
        <GreaseSelection />
        <LubricatorDescription />
      </BorderBox>
    </div>
  );
};

export default Lubrication;
