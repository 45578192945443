import { Notification } from 'domain/Notification';
import { Button } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import ReplenishmentAction from '../Actions/ReplenishmentAction';
import OnClickProps from 'utils/ui/OnClickProps';

const ReplenishmentButton = ({ notification }: { notification: Notification }) => {
  const intl = useIntl();

  return (
    <ReplenishmentAction
      notification={notification}
      Child={({ onClick, disabled }: OnClickProps) => (
        <Button
          disabled={disabled}
          className={disabled ? 'mt-3 cursor-not-allowed' : 'mt-3 pointer-cursor'}
          title={disabled ? intl.formatMessage({ id: 'usercalculations.replenishment.tooltip.disabled' }) : ''}
          onClick={onClick}
        >
          {intl.formatMessage({ id: 'usercalculations.replenishment.button' })}
        </Button>
      )}
    />
  );
};

export default ReplenishmentButton;
