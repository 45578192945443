import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { LubricatorSettingImage } from 'domain/Lubricator';
import { Image } from '@skf-internal/ui-components-react-legacy';

type SettingsImageProps = {
  className?: string;
};

const SettingsImage = ({ className }: SettingsImageProps) => {
  const [imageRef, setImageRef] = useState(String);
  const result = useAppSelector((state) => state.result.result);

  useEffect(() => {
    if (result) {
      if ((result?.lubricatorSetting as LubricatorSettingImage).imageRef) {
        setImageRef((result?.lubricatorSetting as LubricatorSettingImage).imageRef);
      } else {
        setImageRef('');
      }
    }
  }, [result]);

  return <>{imageRef ? <Image className={className} src={imageRef.toLocaleLowerCase()} alt="" feObjectFit="scale-down" /> : <></>} </>;
};

export default SettingsImage;
