import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CookieService from 'services/Cookies/CookieSettingsService';
import { getIntl, Intl, LanguageLabels, Locale } from 'services/Localization/LocalizationService';
import { OfflineSyncStatus } from 'services/Notifications/NotificationSyncService';
import { sortAnyPredicate } from 'utils/data/sorter';

export type Language = {
  locale: Locale;
  label: string;
  azureB2C: string;
};

export const sortLanguages = sortAnyPredicate<Language>((lang) => lang.locale);

export type SettingState = {
  intl: Intl;
  availableLanguages: Language[];
  connected?: boolean;
  location: string;
  syncStatus: OfflineSyncStatus;
};

const cookieService = new CookieService();

export const availableLanguages = [
  {
    locale: Locale.english,
    label: LanguageLabels.english,
    azureB2C: 'English'
  },
  {
    locale: Locale.french,
    label: LanguageLabels.french,
    azureB2C: 'French'
  },
  {
    locale: Locale.czech,
    label: LanguageLabels.czech,
    azureB2C: 'Czech'
  },
  {
    locale: Locale.romania,
    label: LanguageLabels.romania,
    azureB2C: 'Romanian'
  },
  {
    locale: Locale.japanese,
    label: LanguageLabels.japanese,
    azureB2C: 'Japanese'
  },
  {
    locale: Locale.hungarian,
    label: LanguageLabels.hungarian,
    azureB2C: 'Hungarian'
  },
  {
    locale: Locale.polish,
    label: LanguageLabels.polish,
    azureB2C: 'Polish'
  },
  {
    locale: Locale.german,
    label: LanguageLabels.german,
    azureB2C: 'German'
  },
  {
    locale: Locale.spanish,
    label: LanguageLabels.spanish,
    azureB2C: 'Spanish'
  },
  {
    locale: Locale.italian,
    label: LanguageLabels.italian,
    azureB2C: 'Italian'
  },
  {
    locale: Locale.portuguese,
    label: LanguageLabels.portuguese,
    azureB2C: 'Portuguese'
  },
  {
    locale: Locale.swedish,
    label: LanguageLabels.swedish,
    azureB2C: 'Swedish'
  },
  {
    locale: Locale.thai,
    label: LanguageLabels.thai,
    azureB2C: 'Thai'
  },
  {
    locale: Locale.chineseSimplified,
    label: LanguageLabels.chineseSimplified,
    azureB2C: 'Chinese Simplified'
  }
] as Language[];

const initialState: SettingState = {
  location: '',
  syncStatus: OfflineSyncStatus.None,
  intl: {
    defaultLocale: '',
    locale: '',
    messages: {},
    fallbackMessages: null
  },
  availableLanguages
};

export const settingSlice = createSlice({
  name: 'setting',
  initialState: initialState,
  reducers: {
    setIntl: (state: SettingState, action: PayloadAction<Intl>) => {
      state.intl = action.payload;
    },
    setLocale: (state: SettingState, action: PayloadAction<Locale>) => {
      const intl = getIntl(action.payload);
      cookieService.setLanguageCookie(action.payload);
      state.intl = intl;
    },
    setConnection: (state: SettingState, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    setLocation: (state: SettingState, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setSyncStatus: (state: SettingState, action: PayloadAction<OfflineSyncStatus>) => {
      state.syncStatus = action.payload;
    }
  }
});

export const { setIntl, setLocale, setConnection, setLocation, setSyncStatus } = settingSlice.actions;
export default settingSlice.reducer;
