import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import PrintHeader from './layout/PrintHeader';
import PrintKeyValue from './layout/PrintKeyValue';
import PrintRow from './layout/PrintRow';

const PrintOperatingConditions = () => {
  const currentValues = useAppSelector((state) => state.input);

  const unit = useAppSelector((state) => state.data.conditions.unit);
  const intl = useIntl();

  const translateValue = (value: string) => {
    if (unit === 'imperial') {
      return intl.formatMessage({ id: value.replace('metric', 'imperial') });
    } else {
      return intl.formatMessage({ id: value });
    }
  };

  return (
    <>
      <PrintHeader header="operating.header"></PrintHeader>
      <PrintRow>
        <div>
          <PrintKeyValue header="operating.speed" value={currentValues.speed?.toString()} />
          <PrintKeyValue header="operating.temperature" value={translateValue(currentValues.temperature.label)} />
          <PrintKeyValue header="operating.load" value={translateValue(currentValues.load.label)} />
          <PrintKeyValue header="operating.ambientTemperature" value={translateValue(currentValues.ambientTemperature.label)} />
          <PrintKeyValue header="operating.replenishment" value={translateValue(currentValues.replenishment.label)} />
        </div>
        <div>
          <PrintKeyValue header="operating.hours" value={currentValues.hours?.toString()} />
          <PrintKeyValue header="operating.contamination" value={translateValue(currentValues.contamination.label)} />
          <PrintKeyValue header="operating.shockLoad" value={translateValue(currentValues.shockLoad.label)} />
          <PrintKeyValue header="operating.shaftOrientation" value={translateValue(currentValues.shaftOrientation.label)} />
          <PrintKeyValue header="operating.rotatingOuterRing" value={translateValue(currentValues.rotatingOuterRing.label)} />
        </div>
      </PrintRow>
    </>
  );
};

export default PrintOperatingConditions;
