import { Unit } from 'domain/Conditions';
import { ConvertibleValue, ResultWarning, WarningType } from 'domain/Result';
import { IntlShape } from 'react-intl';
import { convertValueToImperial } from 'services/UnitConversion/UnitConversionService';
import { roundSignificantNumbers } from 'utils/data/round';
import formatString from 'utils/strings/formatString';

export const filterWarnings = (warningsToFilter: Array<ResultWarning> | undefined) => {
  if (warningsToFilter) {
    if (warningsToFilter.some((w) => w.type !== WarningType.none)) {
      return warningsToFilter.filter((w) => w.type !== WarningType.none);
    } else {
      return warningsToFilter;
    }
  }
  return [];
};

export const formatWarning = (warning: ResultWarning, intl: IntlShape, unit: Unit): string => {
  if (warning.innerWarning) {
    const innerMessage = formatWarning(warning.innerWarning, intl, unit);
    return formatString(intl.formatMessage({ id: warning.key }), getFormattingValues([...warning.formattingValues, innerMessage], intl, unit));
  } else if (warning.formattingValues && warning.formattingValues.length > 0) {
    return formatString(intl.formatMessage({ id: warning.key }), getFormattingValues(warning.formattingValues, intl, unit));
  } else {
    return intl.formatMessage({ id: warning.key });
  }
};

const getFormattingValues = (values: Array<ConvertibleValue | number | string>, intl: IntlShape, unit: Unit): Array<string> => {
  return values.map((v) => {
    if (isConvertibleValue(v)) {
      return getConvertibleValueWithUnit(v as ConvertibleValue, intl, unit);
    } else if (isNaN(v as number)) {
      return v.toString();
    } else {
      return roundSignificantNumbers(v as number, 2).toString();
    }
  });
};

const getConvertibleValueWithUnit = (value: ConvertibleValue, intl: IntlShape, unit: Unit): string => {
  let unitLabel: string = '';
  let displayValue: number = 0;

  if (unit === 'imperial') {
    unitLabel = intl.formatMessage({ id: `unit.${value.type}.imperial` });
    displayValue = roundSignificantNumbers(convertValueToImperial(value), 2);
  } else {
    unitLabel = intl.formatMessage({ id: `unit.${value.type}.metric` });
    displayValue = roundSignificantNumbers(value.metricValue, 2);
  }

  return `${displayValue} ${unitLabel}`;
};

const isConvertibleValue = (value: ConvertibleValue | number | string): boolean => {
  return (value as ConvertibleValue).metricValue && (value as ConvertibleValue).type ? true : false;
};
