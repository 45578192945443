import { Notification } from 'domain/Notification';
import { ComponentType } from 'react';
import { useHistory } from 'react-router-dom';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { useAppDispatch } from 'store/hooks';
import { setDisabled } from 'store/InputSlice';
import { duplicateCalculation } from 'store/NotificationSlice';
import useIsAppLocation, { AppLocations } from 'utils/hooks/useIsAppLocation';
import OnClickProps from 'utils/ui/OnClickProps';

const DuplicateAction = ({ notification, Icon }: { notification: Notification; Icon: ComponentType<OnClickProps> }) => {
  const isCalculation = useIsAppLocation([AppLocations.calculation]);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { logDeviceDuplicate } = useAnalyticsLog();

  const onDuplicate = () => {
    dispatch(
      duplicateCalculation({
        notification,
        useCurrent: isCalculation,
        callback: () => {
          history.replace('/dialsetting');
          logDeviceDuplicate();
          dispatch(setDisabled(false));
        }
      })
    );
  };

  return <Icon onClick={onDuplicate} />;
};

export default DuplicateAction;
