import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import convertResult, { ResultWithUnits } from './ResultConversion';
import ResultDesktop from './ResultDesktop';
import ResultMobile from './ResultMobile';

const CalculationResult = () => {
  const result = useAppSelector((state) => state.result.result);
  const unit = useAppSelector((state) => state.data.conditions.unit);
  const intl = useIntl();
  const [convertedResult, setConvertedResult] = useState<ResultWithUnits | undefined>(undefined);

  useEffect(() => {
    setConvertedResult(convertResult(result, unit === 'imperial', intl));
  }, [result, unit, intl]);

  return (
    <>
      <ResultDesktop result={convertedResult} />
      <ResultMobile result={convertedResult} />
    </>
  );
};

export default CalculationResult;
