import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BearingBase } from 'domain/BearingType';
import { Grease } from 'domain/Grease';
import { Lubricator } from 'domain/Lubricator';
import { Conditions, Unit } from 'domain/Conditions';
import { convertConditionsToImperial, convertConditionsToMetric } from 'services/UnitConversion/UnitConversionService';
import CookieService from 'services/Cookies/CookieSettingsService';

export type DataState = {
  bearingTypes: Array<BearingBase>;
  greases: Array<Grease>;
  lubricators: Array<Lubricator>;
  conditions: Conditions;
};

const initialState: DataState = {
  bearingTypes: [],
  greases: [],
  lubricators: [],
  conditions: {} as Conditions
};

const cookeService = new CookieService();

export const setUnit = createAsyncThunk('data/unit', async (input: Unit, thunkAPI) => {
  cookeService.setUnitCookie(input);
  return input;
});

export const dataSlice = createSlice({
  name: 'data',
  initialState: initialState,
  reducers: {
    setBearingTypes: (state: DataState, action: PayloadAction<Array<BearingBase>>) => {
      state.bearingTypes = action.payload;
    },
    setGreases: (state: DataState, action: PayloadAction<Array<Grease>>) => {
      state.greases = action.payload;
    },
    setLubricators: (state: DataState, action: PayloadAction<Array<Lubricator>>) => {
      state.lubricators = action.payload;
    },
    setConditions: (state: DataState, action: PayloadAction<Conditions>) => {
      state.conditions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setUnit.fulfilled, (state, action: PayloadAction<string>) => {
      state.conditions.unit = action.payload as Unit;
      if (state.conditions.unit === 'imperial') {
        state.conditions = convertConditionsToImperial(state.conditions);
      } else {
        state.conditions = convertConditionsToMetric(state.conditions);
      }
    });
    builder.addCase(setUnit.rejected, (state, action) => {
      throw new Error('Action setUnit failed');
    });
  }
});

export const { setBearingTypes, setGreases, setLubricators, setConditions } = dataSlice.actions;
export default dataSlice.reducer;
