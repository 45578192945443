import { BearingDimensions } from 'domain/BearingDimensions';
import { ConditionChoice, Conditions } from 'domain/Conditions';
import { ConvertibleValue, ValueType } from 'domain/Result';

export function convertBearingDimensionsToImperial(dimensions: BearingDimensions): BearingDimensions {
  return {
    width: convertMillimitersToInches(dimensions.width ?? 55),
    innerDiameter: convertMillimitersToInches(dimensions.innerDiameter ?? 120),
    outerDiameter: convertMillimitersToInches(dimensions.outerDiameter ?? 260)
  } as BearingDimensions;
}

export function convertBearingDimensionsToMetric(dimensions: BearingDimensions): BearingDimensions {
  return {
    width: convertInchesToMilimeters(dimensions.width ?? 55),
    innerDiameter: convertInchesToMilimeters(dimensions.innerDiameter ?? 120),
    outerDiameter: convertInchesToMilimeters(dimensions.outerDiameter ?? 260)
  } as BearingDimensions;
}

export function convertConditionsToImperial(conditions: Conditions): Conditions {
  return {
    ...conditions,
    ambientTemperature: conditions.ambientTemperature.map(convertConditionChoiceToImperial),
    temperatures: conditions.temperatures.map(convertConditionChoiceToImperial)
  };
}

export function convertConditionsToMetric(conditions: Conditions): Conditions {
  return {
    ...conditions,
    ambientTemperature: conditions.ambientTemperature.map(convertConditionChoiceToMetric),
    temperatures: conditions.temperatures.map(convertConditionChoiceToMetric)
  };
}

export function convertConditionChoiceToImperial<T>(choice: ConditionChoice<T>): ConditionChoice<T> {
  return {
    ...choice,
    label: choice.label.replace('metric', 'imperial'),
    description: choice.description.replace('metric', 'imperial')
  };
}

export function convertConditionChoiceToMetric<T>(choice: ConditionChoice<T>): ConditionChoice<T> {
  return {
    ...choice,
    label: choice.label.replace('imperial', 'metric'),
    description: choice.description.replace('imperial', 'metric')
  };
}

export function createConvertible(value: number, type: ValueType): ConvertibleValue {
  return { metricValue: value, type: type } as ConvertibleValue;
}

export function convertValueToImperial(value: ConvertibleValue): number {
  switch (value.type) {
    case ValueType.temperature:
      return celsiusToFarenheit(value.metricValue);
    case ValueType.length:
      return convertMillimitersToInches(value.metricValue);
    case ValueType.weight:
      return gramToOunces(value.metricValue);
    default:
      return value.metricValue;
  }
}

export function convertMillimitersToInches(milimeter: number): number {
  return milimeter / 25.4;
}

export function convertInchesToMilimeters(inches: number): number {
  return inches * 25.4;
}

export function gramToOunces(gram: number): number {
  return gram / 28.34952;
}

export function ounceToGram(ounce: number): number {
  return ounce * 28.34952;
}

export function mlToFlOunces(ml: number): number {
  return ml / 29.5735;
}

export function flOuncesToMl(flOunce: number): number {
  return flOunce * 29.5735;
}

export function celsiusToFarenheit(celsius: number): number {
  return (celsius * 9) / 5 + 32;
}

export function farenheitToCelsius(farenheit: number): number {
  return (farenheit - 32) / 1.8;
}
