import { useAppSelector } from 'store/hooks';

const LubricatorImage = ({ className }: { className: string }) => {
  const selectedLubricator = useAppSelector((state) => state.input.lubricator);
  const selectedGrease = useAppSelector((state) => state.input.grease);

  const getImage = (): string => {
    if (selectedLubricator.greaseImages && selectedLubricator.greaseImages.some((i) => i.name === selectedGrease.id)) {
      return selectedLubricator.greaseImages.find((i) => i.name === selectedGrease.id)?.image!;
    } else {
      return selectedLubricator.defaultImage;
    }
  };

  return <img src={getImage()} alt={selectedLubricator.displayName} className={className} />;
};

export default LubricatorImage;
