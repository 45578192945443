import { NumberField } from '@skf-internal/ui-components-react-legacy';
import ConditionChoiceSelect from 'components/forms/ConditionChoiceSelect';
import BorderBox from 'components/layouts/BorderBox';
import { Col, FormRow } from 'components/layouts/FormGrid';
import { ConditionLevel } from 'domain/Conditions';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setAmbientTemperature,
  setContamination,
  setHours,
  setLoad,
  setRotatingOuterRing,
  setShaftOrientation,
  setShockLoad,
  setSpeed,
  setTemperature
} from 'store/InputSlice';
import useAnchor from 'utils/hooks/useAnchor';

const OperatingInput = () => {
  const currentValues = useAppSelector((state) => state.input);
  const currentLubricator = useAppSelector((state) => state.input.lubricator);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);
  const dispatch = useAppDispatch();
  const anchor = useAnchor('operatingconditions');
  const intl = useIntl();

  const showAmbientTemperature = () => {
    return currentLubricator.id.toLocaleLowerCase() === 'lagd 60' || currentLubricator.id.toLocaleLowerCase() === 'lagd 125';
  };
  return (
    <div ref={anchor}>
      <BorderBox header={intl.formatMessage({ id: 'operating.header' })} className="">
        <FormRow>
          <Col>
            <NumberField
              id="speed"
              onChange={(event: any, value: number) => dispatch(setSpeed(value))}
              min="1"
              value={currentValues.speed ?? undefined}
              placeholder="1800"
              feLabel={intl.formatMessage({ id: 'operating.speed' })}
              disabled={isDisabled}
            />
          </Col>
          <Col>
            <NumberField
              id="hours"
              onChange={(event: any, value: number) => dispatch(setHours(value))}
              min="1"
              max="24"
              value={currentValues.hours ?? undefined}
              placeholder="24"
              feLabel={intl.formatMessage({ id: 'operating.hours' })}
              disabled={isDisabled}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <ConditionChoiceSelect<ConditionLevel>
              header={intl.formatMessage({ id: 'operating.temperature' })}
              conditionsSelector={(conditions) => conditions.temperatures}
              valueSelector={(input) => input.temperature}
              action={(value) => setTemperature(value)}
              disabled={isDisabled}
            />
          </Col>
          <Col>
            <ConditionChoiceSelect<number>
              header={intl.formatMessage({ id: 'operating.contamination' })}
              conditionsSelector={(conditions) => conditions.contamination}
              valueSelector={(input) => input.contamination}
              action={(value) => setContamination(value)}
              disabled={isDisabled}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <ConditionChoiceSelect<ConditionLevel>
              header={intl.formatMessage({ id: 'operating.load' })}
              conditionsSelector={(conditions) => conditions.load}
              valueSelector={(input) => input.load}
              action={(value) => setLoad(value)}
              disabled={isDisabled}
            />
          </Col>
          <Col>
            <ConditionChoiceSelect<boolean>
              header={intl.formatMessage({ id: 'operating.shockLoad' })}
              conditionsSelector={(conditions) => conditions.shockLoad}
              valueSelector={(input) => input.shockLoad}
              action={(value) => setShockLoad(value)}
              disabled={isDisabled}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            {showAmbientTemperature() ? (
              <ConditionChoiceSelect<number>
                header={intl.formatMessage({ id: 'operating.ambientTemperature' })}
                conditionsSelector={(conditions) => conditions.ambientTemperature}
                valueSelector={(input) => input.ambientTemperature}
                action={(value) => setAmbientTemperature(value)}
                disabled={isDisabled}
              />
            ) : (
              <ConditionChoiceSelect<number>
                header={intl.formatMessage({ id: 'operating.shaftOrientation' })}
                conditionsSelector={(conditions) => conditions.shaftOrientation}
                valueSelector={(input) => input.shaftOrientation}
                action={(value) => setShaftOrientation(value)}
                disabled={isDisabled}
              />
            )}
          </Col>
          <Col>
            {showAmbientTemperature() ? (
              <ConditionChoiceSelect<number>
                header={intl.formatMessage({ id: 'operating.shaftOrientation' })}
                conditionsSelector={(conditions) => conditions.shaftOrientation}
                valueSelector={(input) => input.shaftOrientation}
                action={(value) => setShaftOrientation(value)}
                disabled={isDisabled}
              />
            ) : (
              <div></div>
            )}
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <ConditionChoiceSelect<boolean>
              header={intl.formatMessage({ id: 'operating.rotatingOuterRing' })}
              conditionsSelector={(conditions) => conditions.rotatingOuterRing}
              valueSelector={(input) => input.rotatingOuterRing}
              action={(value) => setRotatingOuterRing(value)}
              disabled={isDisabled}
            />
          </Col>
          <Col>
            <></>
          </Col>
        </FormRow>
      </BorderBox>
    </div>
  );
};

export default OperatingInput;
