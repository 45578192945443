import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BearingDimensions } from 'domain/BearingDimensions';
import { setDimension } from 'store/InputSlice';
import { NumberField } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { convertBearingDimensionsToImperial, convertBearingDimensionsToMetric } from 'services/UnitConversion/UnitConversionService';
import { roundSignificantNumbers } from 'utils/data/round';

const BearingDimensionInput = () => {
  const currentDimensions = useAppSelector((state) => state.input.dimensions);
  const validation = useAppSelector((state) => state.result.result?.validation.dimensions);
  const conditions = useAppSelector((state) => state.data.conditions);
  const unit = useAppSelector((state) => state.data.conditions.unit);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);
  const [localUnit, setLocalUnit] = useState<string>(unit);
  const [dimensions, setLocalDimensions] = useState<BearingDimensions>(currentDimensions);

  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (currentDimensions) {
      if (unit === 'imperial') {
        setLocalDimensions(roundDimensions(convertBearingDimensionsToImperial(currentDimensions)));
      } else {
        setLocalDimensions(roundDimensions(currentDimensions));
      }
      setLocalUnit(unit);
    }
  }, [currentDimensions, unit, localUnit]);

  const setBearingDimensionsFromEvent = (value: number, getDimensions: (value: number) => BearingDimensions) => {
    const dimensionsToSet = getDimensions(value);
    setLocalDimensions(dimensionsToSet);
    if (unit === 'imperial') {
      dispatch(setDimension(convertBearingDimensionsToMetric(dimensionsToSet)));
    } else {
      dispatch(setDimension(dimensionsToSet));
    }
  };

  const onOuterDiameterChange = (event: any, value: number) =>
    setBearingDimensionsFromEvent(value, (innerValue: number) => ({ ...dimensions, outerDiameter: innerValue } as BearingDimensions));
  const onInnerDiameterChange = (event: any, value: number) =>
    setBearingDimensionsFromEvent(value, (innerValue: number) => ({ ...dimensions, innerDiameter: innerValue } as BearingDimensions));
  const onWidthChange = (event: any, value: number) =>
    setBearingDimensionsFromEvent(value, (innerValue: number) => ({ ...dimensions, width: innerValue } as BearingDimensions));

  const roundDimensions = (dimensionsToRound: BearingDimensions): BearingDimensions => {
    return {
      width: roundSignificantNumbers(dimensionsToRound.width ?? 55, 2),
      innerDiameter: roundSignificantNumbers(dimensionsToRound.innerDiameter ?? 120, 2),
      outerDiameter: roundSignificantNumbers(dimensionsToRound.outerDiameter ?? 260, 2)
    } as BearingDimensions;
  };

  const getMessageForUnit = (key: string): string => {
    if (unit === 'imperial') {
      return intl.formatMessage({ id: key.replace('metric', 'imperial') });
    } else {
      return intl.formatMessage({ id: key });
    }
  };

  const getInputHint = (): string => {
    if (!validation?.isValid && validation?.message) {
      return intl.formatMessage({ id: validation?.message });
    } else {
      return '';
    }
  };

  return (
    <div>
      <NumberField
        id="innerDiameter"
        min="1"
        onChange={onInnerDiameterChange}
        feSeverity={!validation?.isValid ? 'error' : 'info'}
        feHint={getInputHint()}
        value={dimensions?.innerDiameter ?? undefined}
        placeholder={conditions.bearingInnerDiameter.default.toString()}
        feLabel={getMessageForUnit('bearingDimensions.innerDiameter.metric')}
        disabled={isDisabled}
      />
      <div className="mt-4">
        <NumberField
          id="outerDiameter"
          min="1"
          onChange={onOuterDiameterChange}
          value={dimensions?.outerDiameter ?? undefined}
          placeholder={conditions.bearingOuterDiameter.default.toString()}
          feLabel={getMessageForUnit('bearingDimensions.outerDiameter.metric')}
          disabled={isDisabled}
        />
      </div>
      <div className="mt-4">
        <NumberField
          id="width"
          min="1"
          onChange={onWidthChange}
          value={dimensions?.width ?? 0}
          placeholder={conditions.bearingWidthDiameter.default.toString()}
          feLabel={getMessageForUnit('bearingDimensions.width.metric')}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default BearingDimensionInput;
