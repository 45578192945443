import { Button, MediaQueries, useMediaQuery } from '@skf-internal/ui-components-react-legacy';
import InteruptiveDialog from 'components/layouts/InteruptiveDialog';
import LanguageDialog from 'components/layouts/LanguageDialog';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import useReleaseInfo from './useReleaseInfo';

const link = 'http://www.skf.com/appsupport';

const ReleaseInfo = ({ show, onClose, showLanguageSelector }: { show: boolean; onClose: () => void; showLanguageSelector?: boolean }) => {
  const [, latestRelease] = useReleaseInfo();
  const isMobile = useMediaQuery(MediaQueries.MobileMax);
  const [showLanguages, setShowLanguages] = useState(false);
  const intl = useIntl();
  return (
    <>
      <InteruptiveDialog
        buttons={{
          primary: {
            value: intl.formatMessage({ id: 'release.dialog.close' }),
            onClick: onClose
          }
        }}
        open={show}
        cssWidth={isMobile ? '' : '50vw'}
        cssHeight={isMobile ? '80vh' : 'auto'}
        title={intl.formatMessage({ id: latestRelease.header })}
        OnClose={onClose}
      >
        {showLanguageSelector && (
          <Button onClick={() => setShowLanguages(true)} className="mb-2">
            {intl.formatMessage({ id: 'navigation.languageSelect' })}
          </Button>
        )}
        <Paragraph>{intl.formatMessage({ id: latestRelease.intro })}</Paragraph>
        <Paragraph>{intl.formatMessage({ id: 'release.dialog.mainFeatures' })}</Paragraph>
        <ul className="mb-2 ml-6 list-disc">
          {latestRelease.features.map((f, i) => (
            <li className="mb-2" key={i}>
              <b>{intl.formatMessage({ id: f.header })}</b>: {intl.formatMessage({ id: f.info })};
            </li>
          ))}
        </ul>
        <Paragraph>{intl.formatMessage({ id: 'release.dialog.footer' })}</Paragraph>
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: 'release.dialog.footerLink' }, { link: `<a class="text-primary-600" target="_blank" href="${link}">${link}</a>` })
          }}
        ></p>
      </InteruptiveDialog>
      <LanguageDialog show={showLanguages} onClose={() => setShowLanguages(false)} />
    </>
  );
};

const Paragraph = ({ children }: { children: ReactNode }) => {
  return <p className="mb-2 ">{children}</p>;
};

export default ReleaseInfo;
