import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { setDisabled } from 'store/InputSlice';
import { getNotificationAndCalculation, resetCurrentCalculation } from 'store/NotificationSlice';

const useNotificationFromLocation = () => {
  const dispatch = useAppDispatch();

  const match = useRouteMatch('/calculation/:id');

  const setCurrentCalculation = useCallback(
    (notificationId: string) => {
      dispatch(getNotificationAndCalculation(notificationId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (match) {
      if (match.params && (match.params as Record<string, string>)['id']) {
        setCurrentCalculation((match.params as Record<string, string>)['id']);
        dispatch(setDisabled(true));
      } else {
        dispatch(resetCurrentCalculation());
        dispatch(setDisabled(false));
      }
    } else {
      dispatch(resetCurrentCalculation());
      dispatch(setDisabled(false));
    }
  }, [match, setCurrentCalculation, dispatch]);

  return null;
};

export default useNotificationFromLocation;
