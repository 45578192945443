import { Locales } from '@skf-internal/ui-components-react-legacy';
import { addDays, addMilliseconds, addWeeks, differenceInDays, format } from 'date-fns';

export const getCurrentNotificationDate = (currentInterval: number, installationDate: Date): Date => {
  if (currentInterval) {
    let dateToSet = addMilliseconds(installationDate, currentInterval);
    dateToSet = addWeeks(dateToSet, -1);
    if (dateToSet < new Date()) {
      return addDays(new Date(), 1);
    } else {
      return dateToSet;
    }
  }
  return installationDate;
};

export const getExpirationDate = (currentInterval: number, installationDate: Date): Date => {
  if (currentInterval) {
    let dateToSet = addMilliseconds(installationDate, currentInterval);
    return dateToSet;
  }
  return installationDate;
};

export const getDateFromString = (date: string): Date => {
  return new Date(date);
};

export const daysBefore = (dateLeft: Date, dateRight: Date): number => {
  if (dateLeft && dateRight) {
    return differenceInDays(dateLeft, dateRight);
  }
  return 0;
};

export const getLocalDateString = (date: string, locale: string, geoLocale: string | undefined): string => {
  return format(new Date(date), 'P', {
    locale: geoLocale === 'US' ? Locales['enUS'] : locale === 'en' ? Locales['enGB'] : Locales[locale]
  });
};
