import { useAppSelector } from 'store/hooks';
import useNotificationSync from '../Notifications/useNotificationSync';
import useFirebaseSync from './useFirebaseSync';

const AutoOfflineSync = () => {
  const connected = useAppSelector((state) => state.setting.connected);
  const isAuthenticated = useAppSelector((state) => state.account.isAuthenticated && !state.account.account?.idTokenClaims?.isFake);

  useNotificationSync(connected ? true : false, isAuthenticated ? true : false);
  useFirebaseSync(connected ? true : false);
  return <></>;
};

export default AutoOfflineSync;
