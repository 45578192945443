import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConfirmInput, Notification, NotificationInput } from 'domain/Notification';
import { RootState } from 'store/store';
import service from 'services/Notifications/NotificationService';
import createInputFromStored from 'services/StoredCalculation/createInput';
import { setInput } from 'store/InputSlice';

interface ApiError {
  message: string;
  stack?: string;
  name: string;
}

export type NotificationState = {
  current: Notification | null;
  notifications: Notification[];
  numberOfExpirations: number;
  isAdding: boolean;
  isLoading: boolean;
  error: ApiError | null;
  duplicateFrom: Notification | null;
};

const initialState: NotificationState = {
  current: null,
  notifications: [],
  isAdding: false,
  error: null,
  isLoading: false,
  numberOfExpirations: 0,
  duplicateFrom: null
};

const createError = (error: Error): ApiError => {
  return {
    message: error.message,
    stack: error.stack,
    name: error.name
  };
};

export const addNotification = createAsyncThunk(
  'calculations/add',
  async (
    { input, geoLocation, callback }: { input: NotificationInput; geoLocation: string | undefined; callback: (notification: Notification) => void },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      dispatch(clearError());
      dispatch(setIsAdding(true));
      input.duplicatedFrom = state.notifications.duplicateFrom?.id;
      const result = await service.addNotification(input, state.input, state.result.result!, state.setting.intl.locale, geoLocation, state.setting.connected);
      callback(result);
      return result;
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const updateNotification = createAsyncThunk(
  'calculations/update',
  async ({ notificationId, input }: { notificationId: string; input: NotificationInput }, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      const state = getState() as RootState;
      dispatch(clearError());
      dispatch(setIsLoading(true));
      return await service.updateNotification(notificationId, input, state.setting.connected);
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const getNotifications = createAsyncThunk('calculations/get', async (userId: string, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
  try {
    const state = getState() as RootState;
    dispatch(clearError());
    dispatch(setIsLoading(true));
    return await service.getNotifications(state.setting.connected);
  } catch (error) {
    throw rejectWithValue(createError(error as Error));
  }
});

export const silentLoadNotifications = createAsyncThunk(
  'calculations/getSilent',
  async (userId: string, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      dispatch(clearError());
      const state = getState() as RootState;
      if (state.notifications.notifications.length === 0) {
        return await service.getNotifications(state.setting.connected);
      } else {
        return [];
      }
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const reloadNotifications = createAsyncThunk('calculations/reload', async (callback: () => void, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    if (state.setting.connected) {
      const notifications = await service.reloadNotifications();
      callback();
      return notifications;
    } else {
      throw new Error('Can not reload while offline');
    }
  } catch (error) {
    throw rejectWithValue(error);
  }
});

export const deleteNotification = createAsyncThunk(
  'calculations/delete',
  async ({ notificationId, callback }: { notificationId: string; callback: () => void }, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
      dispatch(setIsAdding(true));
      dispatch(clearError());
      const state = getState() as RootState;
      await service.deleteNotification(notificationId, state.setting.connected);
      callback();
      return notificationId;
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const getNotificationAndCalculation = createAsyncThunk(
  'calculations/getCalculation',
  async (notificationId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(clearError());
      dispatch(setIsLoading(true));
      const state = getState() as RootState;
      const calculation = await service.getCalculation(notificationId, state.setting.connected);

      const input = createInputFromStored(
        calculation.calculation.input,
        state.data.bearingTypes,
        state.data.conditions,
        state.data.greases,
        state.data.lubricators
      );
      dispatch(setInput(input));
      return calculation.notification;
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const duplicateCalculation = createAsyncThunk(
  'calculations/duplicate',
  async (
    { notification, callback, useCurrent }: { notification: Notification; useCurrent: boolean; callback: () => void },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      if (!useCurrent) {
        dispatch(clearError());
        dispatch(setIsLoading(true));
        const state = getState() as RootState;
        const calculation = await service.getCalculation(notification.id, state.setting.connected);
        const input = createInputFromStored(
          calculation.calculation.input,
          state.data.bearingTypes,
          state.data.conditions,
          state.data.greases,
          state.data.lubricators
        );
        dispatch(setInput(input));
      }
      callback();
      return notification;
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const confirmReminder = createAsyncThunk(
  'reminder/confirm',
  async (
    { notificationId, confirmReminder }: { notificationId: string; confirmReminder: ConfirmInput },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      dispatch(clearError());
      dispatch(setIsAdding(true));
      return await service.confirmReminder(notificationId, confirmReminder, state.setting.connected);
    } catch (error) {
      throw rejectWithValue(createError(error as Error));
    }
  }
);

export const notificationSlice = createSlice({
  name: 'calculations',
  initialState: initialState,
  reducers: {
    setIsAdding: (state: NotificationState, action: PayloadAction<boolean>) => {
      state.isAdding = action.payload;
    },
    setIsLoading: (state: NotificationState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetCurrentCalculation: (state: NotificationState, action: PayloadAction) => {
      state.current = null;
    },
    clearError: (state: NotificationState, action: PayloadAction) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addNotification.fulfilled, (state, action: PayloadAction<Notification>) => {
      state.notifications = [...state.notifications, action.payload];
      state.current = action.payload;
      state.duplicateFrom = null;
      state.isAdding = false;
    });
    builder.addCase(addNotification.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
      state.isAdding = false;
    });
    builder.addCase(getNotifications.fulfilled, (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
      state.isLoading = false;
    });
    builder.addCase(silentLoadNotifications.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.notifications = action.payload;
      }
    });
    builder.addCase(silentLoadNotifications.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
    });
    builder.addCase(getNotificationAndCalculation.fulfilled, (state, action: PayloadAction<Notification>) => {
      state.current = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getNotificationAndCalculation.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
      state.isLoading = false;
    });
    builder.addCase(updateNotification.fulfilled, (state, action: PayloadAction<Notification>) => {
      state.current = action.payload;
      state.duplicateFrom = null;
      state.notifications = state.notifications.map((n) => (n.id === action.payload.id ? action.payload : n));
      state.isLoading = false;
    });
    builder.addCase(updateNotification.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
      state.isLoading = false;
    });
    builder.addCase(deleteNotification.fulfilled, (state, action: PayloadAction<string>) => {
      state.current = null;
      state.isAdding = false;
      state.notifications = state.notifications.filter((n) => n.id !== action.payload);
    });
    builder.addCase(deleteNotification.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
    });
    builder.addCase(duplicateCalculation.fulfilled, (state, action: PayloadAction<Notification>) => {
      state.current = null;
      state.duplicateFrom = action.payload;
      state.isLoading = false;
    });
    builder.addCase(duplicateCalculation.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
    });
    builder.addCase(confirmReminder.fulfilled, (state, action: PayloadAction<Notification>) => {
      state.current = action.payload;
      state.notifications = state.notifications.map((n) => (n.id === action.payload.id ? action.payload : n));
      state.isAdding = false;
    });
    builder.addCase(confirmReminder.rejected, (state, action) => {
      console.log(action.error);
      state.error = action.payload as ApiError;
    });
  }
});

export const { setIsAdding, setIsLoading, resetCurrentCalculation, clearError } = notificationSlice.actions;
export default notificationSlice.reducer;
