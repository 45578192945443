import TriStateRadioField from 'components/forms/TriStateRadioField';
import { Grease } from 'domain/Grease';
import { GreaseAvalability, GreaseDisabledReason } from 'domain/Result';
import { useIntl } from 'react-intl';

type GreaseRadioGroupProps = {
  greases: Array<GreaseAvalability>;
  selectedGrease: string;
  disabled?: boolean;
  onChangeGrease: (checked: boolean, value: number | string) => void;
};

const GreaseRadioGroup = ({ greases, selectedGrease, disabled, onChangeGrease }: GreaseRadioGroupProps) => {
  const intl = useIntl();

  const getGreaseLabel = (grease: Grease): string => {
    if (grease.name) {
      return intl.formatMessage({ id: grease.name });
    } else {
      return grease.id;
    }
  };

  const isGreaseDisabled = (grease: GreaseAvalability): boolean => {
    if (grease.grease.id === 'Unspecified') {
      return false;
    }
    return grease.isDisabled && grease.disabledReason.some((r) => r === GreaseDisabledReason.lubricator);
  };

  const isGreaseDisabledWithWarning = (grease: GreaseAvalability): boolean => {
    if (grease.grease.id === 'Unspecified') {
      return false;
    }
    return grease.isDisabled && !grease.disabledReason.some((r) => r === GreaseDisabledReason.lubricator);
  };

  const getGreaseClassName = (classes: string, index: number): string => {
    if (index === 0) {
      return classes + ' w-full';
    } else {
      return classes + ' w-1/2 sm:w-1/3';
    }
  };

  return (
    <>
      {greases?.map((g: GreaseAvalability, i: number) => (
        <TriStateRadioField
          key={i}
          className={getGreaseClassName('mt-2', i)}
          isDisabled={isGreaseDisabled(g) || disabled ? true : false}
          isDisabledWithWarning={isGreaseDisabledWithWarning(g)}
          value={g.grease.id}
          checked={selectedGrease === g.grease.id}
          onChange={onChangeGrease}
          id={g.grease.id}
          label={getGreaseLabel(g.grease)}
        />
      ))}
    </>
  );
};

export default GreaseRadioGroup;
