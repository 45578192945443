export type TreeItem<T> = { item: T; parent: TreeItem<T> | null };

export default function treeSearch<T>(items: T[], predicate: (i: T) => boolean, childSelector: (i: T) => T[]): TreeItem<T> | null {
  for (let i = 0; i < items.length; i++) {
    const result = treeSearchInner(items[i], predicate, childSelector);
    if (result != null) {
      return result;
    }
  }
  return null;
}

export function getTreeAsArray<T>(item: TreeItem<T>): T[] {
  const items = getTreeAsArrayInternal(item);
  return items.reverse();
}

function getTreeAsArrayInternal<T>(item: TreeItem<T>): T[] {
  let items: T[] = [item.item];
  if (item.parent) {
    items = [...items, ...getTreeAsArrayInternal(item.parent)];
  }
  return items;
}

export function treeSearchInner<T>(item: T, predicate: (i: T) => boolean, childSelector: (i: T) => T[]): TreeItem<T> | null {
  const stack: TreeItem<T>[] = [];
  stack.push({ item } as TreeItem<T>);

  while (stack.length > 0) {
    const node = stack.pop();

    if (node) {
      if (node && predicate(node.item)) {
        return node;
      } else {
        childSelector(node.item).forEach((n) => stack.push({ item: n, parent: node ?? null }));
      }
    }
  }
  return null;
}
