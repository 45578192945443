import { ConditionLevel } from 'domain/Conditions';
import { Input } from 'domain/Input';

export default function calculateCorrectedRelubricationInterval(relubricationInterval: number, input: Input): number {
  return calculate(
    relubricationInterval,
    input.bearingType.crbHalfFactor,
    getLoadFactor(input.load.value, input.shockLoad.value),
    getTempFactor(input.temperature.value, input.bearingType.thrustFullCompliment),
    input.grease.greaseFactor,
    input.hours ?? 24,
    input.contamination.value,
    input.shaftOrientation.value
  );
}

export function calculate(
  relubricationInterval: number,
  crbHalfFactor: boolean,
  loadFactor: number,
  tempFactor: number,
  greaseFactor: number,
  hours: number,
  contamination: number,
  shaftOrientation: number
): number {
  return limitValue(
    relubricationInterval * shaftOrientation * tempFactor * loadFactor * (24 / hours) * contamination * greaseFactor * (crbHalfFactor ? 0.5 : 1),
    30000
  );
}

export function limitValue(value: number, max: number): number {
  return value > max ? max : value;
}

export function getTempFactor(temperature: ConditionLevel, isFullComplimentOrThrustBearing: boolean): number {
  //Original equation to get tempfactor where temp is 55 for low, 70 for moderate, 85 for high and 100 for veryhigh:
  //Math.pow(2, (70 - temp) / 15);
  //This seems easier given that temperature has fixed values
  switch (temperature) {
    case ConditionLevel.low:
      return isFullComplimentOrThrustBearing ? 1 : 2;
    case ConditionLevel.moderate:
      return 1;
    case ConditionLevel.high:
      return 0.5;
    case ConditionLevel.veryhigh:
      return 0.25;
    default:
      return 2;
  }
}

export function getLoadFactor(load: ConditionLevel, shockLoad: boolean): number {
  switch (load) {
    case ConditionLevel.low:
      return shockLoad ? 0.7 : 1;
    case ConditionLevel.moderate:
      return shockLoad ? 0.35 : 0.7;
    case ConditionLevel.high:
      return shockLoad ? 0.15 : 0.35;
    default:
      return shockLoad ? 0.1 : 0.15;
  }
}
