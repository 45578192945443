import React from 'react';

type ChevronUpSvgSvgProps = {
  className?: string;
  svgWidth?: number;
  svgHeight?: number;
};

const ChevronUpSvg = ({ className, svgWidth = 24, svgHeight = 16 }: ChevronUpSvgSvgProps) => (
  <svg
    width={svgWidth + 'px'}
    height={svgHeight + 'px'}
    viewBox={`0 0 ${svgWidth} ${svgHeight}`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={className}
  >
    <path
      d="M13.4625,13.2925 L9.5825,9.4125 L5.7025,13.2925 C5.3125,13.6825 4.6825,13.6825 4.2925,13.2925 C3.9025,12.9025 3.9025,12.2725 4.2925,11.8825 L8.8825,7.2925 C9.2725,6.9025 9.9025,6.9025 10.2925,7.2925 L14.8825,11.8825 C15.2725,12.2725 15.2725,12.9025 14.8825,13.2925 C14.4925,13.6725 13.8525,13.6825 13.4625,13.2925 Z"
      fill="#FEFEFE"
    />
  </svg>
);

export default ChevronUpSvg;
