import { BearingBase, BearingTypeCollection, BearingType } from 'domain/BearingType';
import bearingTypesData from 'assets/data/bearingTypes.json';

export function getBearingTypes(): Array<BearingBase> {
  return getBearingTypesFromJson(bearingTypesData);
}

export function getBearingTypesFromJson(bearingTypes: Array<any>): Array<BearingBase> {
  return bearingTypes.map(getBearingType);
}

export function getBearingType(jsonBearingType: any): BearingBase {
  if (jsonBearingType['subTypes']) {
    return getBearingTypeCollection(jsonBearingType);
  } else {
    return getBearingTypeObject(jsonBearingType);
  }
}

export function getBearingTypeCollection(jsonBearingType: any): BearingTypeCollection {
  return {
    name: jsonBearingType.name,
    id: jsonBearingType.id,
    subTypeHeader: jsonBearingType.subTypeHeader,
    subTypeDescription: jsonBearingType.subTypeDescription,
    subTypes: getBearingTypesFromJson(jsonBearingType.subTypes),
    additionalOptions: jsonBearingType.additionalOptions
  } as unknown as BearingTypeCollection;
}

export function getBearingTypeObject(jsonBearingType: any): BearingType {
  return jsonBearingType as unknown as BearingType;
}

export function getBearingTypeById(bearingTypes: Array<BearingBase>, id: string): BearingType | undefined {
  const bearingType = bearingTypes.find((b) => b.id === id);
  if (!bearingType) {
    const potentialBearingTypes = bearingTypes.filter((b) => id.includes(b.id) && isBearingCollection(b)).map((b) => b as BearingTypeCollection);
    if (potentialBearingTypes.length > 0) {
      return getBearingTypeById(
        potentialBearingTypes.flatMap((bt) => bt.subTypes),
        id
      );
    } else {
      return undefined;
    }
  } else {
    return bearingType as BearingType;
  }
}

export function isBearingCollection(bearingType?: BearingBase): boolean {
  return bearingType && (bearingType as BearingTypeCollection).subTypes ? true : false;
}

export function getDefaultBearingType(bearingCollection: BearingTypeCollection) {
  if (bearingCollection.subTypes) {
    const firstL1 = bearingCollection.subTypes[0];
    if (isBearingCollection(firstL1)) {
      const firstL1Collection = firstL1 as BearingTypeCollection;
      if (firstL1Collection.subTypes) {
        const firstL2 = firstL1Collection.subTypes[0];
        return firstL2 as BearingType;
      }
    } else {
      return firstL1 as BearingType;
    }
  }
  return null;
}
