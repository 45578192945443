import { useCallback, useEffect } from 'react';

export enum SyncEventType {
  doSync = 'doSync',
  nothingToSync = 'nothingToSync',
  syncDone = 'syncDone',
  doNotSync = 'doNotSync'
}

interface SyncSettings {
  id: string;
  shouldInitiate: boolean;
  shouldSync: () => Promise<boolean>;
  eventHandler: (eventType: SyncEventType) => void;
}

const useDataSync = ({ id, shouldInitiate, shouldSync, eventHandler }: SyncSettings) => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const listener = (event: MessageEvent<any>) => {
        if (event.data) {
          if (event.data.id === id) {
            if (event.data.type === 'START_SYNC') {
              eventHandler(SyncEventType.doSync);
            }
            if (event.data.type === 'SYNC_DONE') {
              eventHandler(SyncEventType.syncDone);
            }
            if (event.data.type === 'DONOT_SYNC') {
              eventHandler(SyncEventType.doNotSync);
            }
          }
        }
      };

      navigator.serviceWorker.addEventListener('message', listener);
      return () => {
        navigator.serviceWorker.removeEventListener('message', listener);
      };
    }
  });

  const initiateSync = useCallback(() => {
    if ('serviceWorker' in navigator) {
      shouldSync().then((hasEvents) => {
        if (hasEvents) {
          if (process.env.NODE_ENV === 'development') {
            console.log('SYNC:isDevelopment');
            eventHandler(SyncEventType.doSync);
          } else {
            console.log('SYNC:isProduction');
            navigator.serviceWorker.controller?.postMessage({ type: 'REQUEST_SYNC', id });
          }
        } else {
          eventHandler(SyncEventType.nothingToSync);
        }
      });
    }
  }, [eventHandler, shouldSync, id]);

  useEffect(() => {
    if (shouldInitiate) {
      initiateSync();
    }
  }, [shouldInitiate, initiateSync]);
};

export default useDataSync;
