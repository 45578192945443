import { useAppSelector } from 'store/hooks';
import { Loader } from '@skf-internal/ui-components-react-legacy';
import InfoBox from 'components/layouts/InfoBox';
import Error from './Error';
import Info from './Info';
import Actions from './Actions';
import Replenishment from './Replenishment';
const CurrentNotification = () => {
  const currentNotification = useAppSelector((state) => state.notifications.current);
  const isAdding = useAppSelector((state) => state.notifications.isAdding || state.notifications.isLoading);
  const error = useAppSelector((state) => state.notifications.error);

  const showCurrentCalculation = () => {
    return isAdding || currentNotification || error;
  };

  const renderCurrentCalculation = () => {
    if (isAdding) {
      return <Loader className="mx-auto" />;
    } else if (error) {
      return <Error />;
    } else if (currentNotification) {
      return (
        <div className="w-full flex relative flex-col sm:flex-row sm:min-h-10 px-2 py-0 sm:p-0">
          <Info notification={currentNotification} />
          <Replenishment notification={currentNotification} />
          <Actions />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return showCurrentCalculation() ? (
    <div className="w-full relative">
      <InfoBox className="w-full">{renderCurrentCalculation()}</InfoBox>
    </div>
  ) : (
    <></>
  );
};

export default CurrentNotification;
