import FirebaseOfflineRepository from './Repositories/FirebaseOfflineRepository';
import FirebaseOnlineRepository from './Repositories/FirebaseOnlineRepository';
import FirebaseSyncRepository from './Repositories/FirebaseSyncRepository';

export const getFirebaseRepositoryFactory = (connected?: boolean) => {
  if (connected) {
    return new FirebaseOnlineRepository();
  } else {
    return new FirebaseOfflineRepository();
  }
};

export const getFirebaseSyncRepository = () => {
  return new FirebaseSyncRepository(new FirebaseOnlineRepository());
};
