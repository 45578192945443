import localforage from 'localforage';
import { v4 } from 'uuid';
import FirebaseRepository from '../FirebaseRepository';

const eventTrackingKey = 'firebaseEventsTracking';

interface FirebaseTrackingEvent {
  eventName: string;
  eventParams?: { [key: string]: any } | undefined;
  id: string;
}

class FirebaseOfflineRepository implements FirebaseRepository {
  logEvent(eventName: string, eventParams?: { [key: string]: any } | undefined): void {
    this.getTrackingEventsOrDefault()
      .then((events) => localforage.setItem(eventTrackingKey, [...events, { eventName, eventParams, id: v4() } as FirebaseTrackingEvent]))
      .then((res) => console.log('Added offline event'))
      .catch((err) => console.log(err));
  }

  async removeEvent(event: FirebaseTrackingEvent): Promise<void> {
    const events = await this.getTrackingEventsOrDefault();
    await localforage.setItem(
      eventTrackingKey,
      events.filter((e) => e.id !== event.id)
    );
  }

  async getTrackingEventsOrDefault(): Promise<FirebaseTrackingEvent[]> {
    let values = await localforage.getItem<FirebaseTrackingEvent[]>(eventTrackingKey);
    if (!values) {
      values = [];
    }
    return values;
  }
}

export default FirebaseOfflineRepository;
