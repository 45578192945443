import { Input } from 'domain/Input';
import { NotificationInput, Notification, ConfirmInput } from 'domain/Notification';
import { Result } from 'domain/Result';
import { StoredCalculation } from 'domain/StoredCalculation';
import { NotificationRepository, TrackingRepository } from '../NotificationRepository';
import LocalRepositoryBase from './LocalRepositoryBase';
import { v4 } from 'uuid';
import createStoredCalculation from 'services/StoredCalculation/createStored';

class LocalOfflineRepository extends LocalRepositoryBase implements NotificationRepository {
  private trackingRepository: TrackingRepository;
  constructor(trackingRepository: TrackingRepository) {
    super();
    this.trackingRepository = trackingRepository;
  }

  async addNotification(
    notification: NotificationInput,
    input: Input,
    result: Result,
    language: string,
    geoLocation: string | undefined
  ): Promise<Notification> {
    const notificationId = `offline-${v4()}`;

    await this.trackingRepository.addNotification(notificationId, notification, input, result, language, geoLocation);

    const notificationToAdd = {
      id: notificationId,
      name: notification.name,
      settingInterval: notification.settingInterval,
      isTLMP: notification.isTLMP,
      reminder: {
        id: v4(),
        notificationId: notificationId,
        installationDate: notification.installationDate.toISOString(),
        replenishmentDate: notification.replenishmentDate.toISOString(),
        notificationDate: notification.notificationDate?.toISOString(),
        hasReminder: notification.hasReminder
      }
    } as Notification;

    await this.addLocalNotification(notificationToAdd);

    await this.storeLocalCalculation(notificationId, { calculation: createStoredCalculation(input, result), notification: notificationToAdd });

    return notificationToAdd;
  }

  async updateNotification(notificationId: string, notification: NotificationInput): Promise<Notification> {
    await this.trackingRepository.updatNotification(notificationId, notification);

    const notificationToUpdate = await this.getNotification(notificationId);
    if (notificationToUpdate) {
      notificationToUpdate.name = notification.name;
      notificationToUpdate.reminder.hasReminder = notification.hasReminder;
      notificationToUpdate.reminder.installationDate = notification.installationDate.toISOString();
      notificationToUpdate.reminder.notificationDate = notification.notificationDate?.toISOString();
      notificationToUpdate.reminder.replenishmentDate = notification.replenishmentDate.toISOString();

      this.updateLocalNotification(notificationId, notificationToUpdate);

      return notificationToUpdate;
    } else {
      throw Error('Notification not found in local storage');
    }
  }

  async getNotifications(): Promise<Notification[]> {
    return this.getLocalNotificationsOrDefault();
  }

  async deleteNotification(notificationId: string): Promise<void> {
    await this.trackingRepository.deleteNotification(notificationId);
    let values = await this.getLocalNotificationsOrDefault();
    await this.storeNotifications(values.filter((n) => n.id !== notificationId));
  }

  async getCalculation(notificationId: string): Promise<{ calculation: StoredCalculation; notification: Notification } | null> {
    const result = await this.getLocalCalculation(notificationId);
    if (result) {
      return result;
    } else {
      return null;
    }
  }

  async confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<Notification> {
    await this.trackingRepository.confirmReminder(notificationId, confirmReminder);
    const notificationToUpdate = await this.getNotification(notificationId);
    if (notificationToUpdate) {
      notificationToUpdate.reminder.hasReminder = confirmReminder.hasReminder;
      notificationToUpdate.reminder.installationDate = confirmReminder.installationDate.toISOString();
      notificationToUpdate.reminder.notificationDate = confirmReminder.notificationDate?.toISOString();
      notificationToUpdate.reminder.replenishmentDate = confirmReminder.replenishmentDate.toISOString();

      this.updateLocalNotification(notificationId, notificationToUpdate);

      return notificationToUpdate;
    } else {
      throw Error('Notification not found in local storage');
    }
  }
}

export default LocalOfflineRepository;
