import { MediaQueries, useMediaQuery } from '@skf-internal/ui-components-react-legacy';
import BorderBox from 'components/layouts/BorderBox';
import { useIntl } from 'react-intl';

import useAnchor from 'utils/hooks/useAnchor';
import BearingDimensionInput from './BearingDimensionts';
import BearingSearch from './BearingSearch';
import BearingSelection from './BearingSelection';
import BearingTypeImage from './BearingTypeImage';

const Bearing = () => {
  const intl = useIntl();
  const anchor = useAnchor('bearingselection');
  const isMobile = useMediaQuery(MediaQueries.MobileMax);

  const renderForMobile = () => {
    return (
      <div className="flex flex-col">
        <div className="w-full mt-4">
          <BearingSelection />
        </div>
        <div className="w-full flex flex-row mt-4">
          <div className="w-1/2">
            <BearingDimensionInput />
          </div>
          <div className="w-1/2 my-auto">
            <BearingTypeImage />
          </div>
        </div>
      </div>
    );
  };

  const renderForDesktop = () => {
    return (
      <div className="flex flex-row">
        <div className="flex flex-col w-1/2 mt-4">
          <BearingSelection />
          <div className="mt-4">
            <BearingDimensionInput />
          </div>
        </div>
        <div className="w-1/2 my-auto">
          <BearingTypeImage />
        </div>
      </div>
    );
  };

  return (
    <div ref={anchor}>
      <BorderBox header={intl.formatMessage({ id: 'bearingSelection.header' })}>
        <BearingSearch />
        {isMobile ? renderForMobile() : renderForDesktop()}
      </BorderBox>
    </div>
  );
};

export default Bearing;
