import { css, MotionDurations, Spacings, styled } from '@skf-internal/ui-components-react-legacy';
import { ProgressHTMLAttributes } from 'react';

interface ColorProgressProps extends ProgressHTMLAttributes<HTMLProgressElement> {
  foreColor: string;
  backColor: string;
  borderColor?: string;
  title?: string;
}

const ColorProgress = ({ 'aria-label': ariaLabel = 'Loading...', children, max = 100, value, title, ...rest }: ColorProgressProps) => {
  return (
    <StyledProgress title={title} {...rest} aria-label={ariaLabel} data-comp="progress" max={max} value={value}>
      {children || `${value}%`}
    </StyledProgress>
  );
};

const StyledProgress = styled.progress(
  ({ value, foreColor, backColor, borderColor }: Pick<ColorProgressProps, 'foreColor' | 'backColor' | 'borderColor' | 'value'>) => {
    const stValue = 100 - Number(value);

    const progressValueStyle = (tp: string) => css`
      background: ${foreColor};
      border-radius: inherit;

      transition: ${tp} ${MotionDurations.Slow}ms cubic-bezier(0.4, 0.93, 0.71, 0.89);
    `;

    return css`
      background: ${backColor};
      border: ${borderColor ? '1px solid ' + borderColor : '0'};
      border-radius: ${Spacings.Xxs};
      height: 0.6rem;
      overflow: hidden;
      position: relative;
      width: 100%;
      /* Webkit */
      @supports selector(::-webkit-progress-bar) {
        &::after {
          ${progressValueStyle('right')};
          content: '';
          height: inherit;
          left: 0;
          position: absolute;
          right: ${stValue}%;
          top: 0;
        }
        &::-webkit-progress-inner-element {
          display: none;
        }
      }
      /* Firefox */
      &::-moz-progress-bar {
        ${progressValueStyle('width')}
      }
      @keyframes progress-animation {
        to {
          background-position: 50px 50px;
        }
      }
    `;
  }
);

export default ColorProgress;
