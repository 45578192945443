export enum Direction {
  asc = 'asc',
  desc = 'desc'
}

export const sortAnyPredicate =
  <T extends object>(keySelector: (item: T) => string | number, direction?: Direction) =>
  (item1: T, item2: T): number => {
    const key1 = keySelector(item1);
    const key2 = keySelector(item2);

    const label1 = typeof key1 === 'string' ? key1.toLocaleUpperCase() : key1;
    const label2 = typeof key2 === 'string' ? key2.toLocaleUpperCase() : key2;

    if (label1 < label2) {
      if (direction && direction === Direction.desc) return 1;
      else return -1;
    }

    if (label1 > label2) {
      if (direction && direction === Direction.desc) return -1;
      else return 1;
    }

    return 0;
  };
