import { Colors, Icon, Icons, Text } from '@skf-internal/ui-components-react-legacy';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearError } from 'store/NotificationSlice';

const Error = () => {
  const error = useAppSelector((state) => state.notifications.error);
  const dispatch = useAppDispatch();
  const closeError = () => {
    dispatch(clearError());
  };

  return (
    <Text feColor={Colors.RedBase}>
      {error?.message}
      <div className="absolute right-5 top-6 cursor-pointer" onClick={closeError}>
        <Icon feIcon={Icons.Close} />
      </div>
    </Text>
  );
};

export default Error;
