import { Input } from 'domain/Input';
import { ConfirmInput, Notification, NotificationInput } from 'domain/Notification';
import { Result } from 'domain/Result';
import { StoredCalculation } from 'domain/StoredCalculation';

export interface NotificationRepository {
  addNotification(notification: NotificationInput, input: Input, result: Result, language: string, geoLocation: string | undefined): Promise<Notification>;
  updateNotification(notificationId: string, notification: NotificationInput): Promise<Notification>;
  getNotifications(): Promise<Notification[]>;
  deleteNotification(notificationId: string): Promise<void>;
  getCalculation(notificationId: string): Promise<{ calculation: StoredCalculation; notification: Notification } | null>;
  confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<Notification>;
}

export interface TrackingEvent {
  trackingId: string;
  notificationId: string;
  type: TrackingType;
  data: any;
  timestamp: Date;
}

export enum TrackingType {
  addNotification = 'addNotification',
  updatNotification = 'updatNotification',
  deleteNotification = 'deleteNotification',
  confirmReminder = 'confirmReminder'
}

export interface TrackingRepository {
  addNotification(
    offlineId: string,
    notification: NotificationInput,
    input: Input,
    result: Result,
    language: string,
    geoLocation: string | undefined
  ): Promise<void>;
  updatNotification(notificationId: string, notification: NotificationInput): Promise<void>;
  deleteNotification(notificationId: string): Promise<void>;
  confirmReminder(notificationId: string, confirmReminder: ConfirmInput): Promise<void>;
  getOfflineEvents(): Promise<TrackingEvent[]>;
  removeTrackingEvent(event: TrackingEvent): Promise<void>;
}
