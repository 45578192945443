import { Portal } from '@skf-internal/ui-components-react-legacy';
import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';

const DropdownPopper = ({ popTrigger, poppedContent, offset }: { popTrigger: ReactNode; poppedContent: ReactNode; offset?: number }) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, offset ? offset : 2]
        }
      }
    ]
  });

  return (
    <>
      <div ref={setReferenceElement}>{popTrigger}</div>
      <Portal data-portals="select">
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          {poppedContent}
        </div>
      </Portal>
    </>
  );
};

export default DropdownPopper;
