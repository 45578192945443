import { Grid, GridItem } from '@skf-internal/ui-components-react-legacy';

interface ColProps {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
}

interface FormRowProps {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
}

export const FormRow = ({ children, className }: FormRowProps) => {
  if (children) {
    if (children as React.ReactChild[]) {
      const allchildren = children as React.ReactChild[];
      if (allchildren.length === 2) {
        return (
          <Grid className="mt-4">
            <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>{allchildren[0]}</GridItem>
            <GridItem feColspan={{ desktop: 6, tablet: 12, mobile: 12 }}>{allchildren[1]}</GridItem>
          </Grid>
        );
      } else {
        return (
          <Grid>
            <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}> {allchildren} </GridItem>
          </Grid>
        );
      }
    } else {
      return (
        <Grid>
          <GridItem feColspan={{ desktop: 12, tablet: 12, mobile: 12 }}> {children} </GridItem>
        </Grid>
      );
    }
  } else {
    return <></>;
  }
};

export const Col = ({ children, className }: ColProps) => <div className={className}>{children}</div>;
