import InfoBox from 'components/layouts/InfoBox';
import { useIntl } from 'react-intl';

const GreaseWarning = () => {
  const intl = useIntl();
  const url = 'http://webtools3.skf.com/LubeSelectForSkfGreases/';
  const link = `<a class="text-primary-600 inline" href="${url}" target="_blank">${intl.formatMessage({
    id: 'lubrication.greaseSelect.info.text.link'
  })}</a>`;
  return (
    <InfoBox header={intl.formatMessage({ id: 'lubrication.greaseSelect.warning.header' })} className="flex flex-col sm:flow-root resetTabLeft">
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: 'lubrication.greaseSelect.info.text' }, { link })
          }}
        ></p>
      </div>
    </InfoBox>
  );
};

export default GreaseWarning;
