import { Select } from '@skf-internal/ui-components-react-legacy';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLubricator } from 'store/InputSlice';

const LubricationSelection = () => {
  const [lubricatorOptions, setLubricatorOptions] = useState<Array<{ label: string; value: string }> | undefined>([]);
  const [selectedLubricator, setSelectedLubricator] = useState<string | null>(null);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);
  const allLubricators = useAppSelector((state) => state.data.lubricators);

  const currentLubricator = useAppSelector((state) => state.input.lubricator);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  useEffect(() => {
    setLubricatorOptions(
      allLubricators?.map((l) => ({
        label: l.displayName,
        value: l.id
      }))
    );
  }, [allLubricators]);

  useEffect(() => {
    setSelectedLubricator(currentLubricator.id);
  }, [currentLubricator]);

  const lubricatorChanged = (value: any) => {
    if (value) {
      setSelectedLubricator(value);
      const lubricator = allLubricators?.find((l) => l.id === value);
      if (lubricator) {
        dispatch(setLubricator(lubricator));
      }
    }
  };

  return lubricatorOptions ? (
    <Select
      id="lubricator1 "
      feLabel={intl.formatMessage({ id: 'lubrication.lubricatorSelect.label' })}
      onChange={lubricatorChanged}
      value={selectedLubricator ?? ''}
      feItems={lubricatorOptions}
      multiple={false}
      disabled={isDisabled}
    />
  ) : (
    <></>
  );
};

export default LubricationSelection;
