import { Link } from '@skf-internal/ui-components-react-legacy';
import { useHistory } from 'react-router-dom';

const CalculationLink = ({ value, id, className }: { value: string; id: string; className?: string }) => {
  const history = useHistory();
  return (
    <Link className={className} href={`/calculation/${id}`} onClick={() => history.push(`/calculation/${id}`)} as="a">
      {value}
    </Link>
  );
};

export default CalculationLink;
