import { useMsal } from '@azure/msal-react';
import { Icon, IconColors, Icons, IconSizes } from '@skf-internal/ui-components-react-legacy';
import ContentSaveSvg from 'components/svgs/ContentSaveSvg';
import Authenticator, { AnonymousProps } from 'features/Authentication/Authenticator';
import DeleteAction from 'features/Notifications/Actions/DeleteAction';
import DuplicateAction from 'features/Notifications/Actions/DuplicateAction';
import EditAction from 'features/Notifications/Actions/EditAction';
import SaveNotificationAction from 'features/Notifications/Actions/SaveNotificationAction';
import { useIntl } from 'react-intl';
import { Route, useHistory } from 'react-router-dom';
import { getDefaults } from 'services/Calculation/CalculationService';
import { getConditions } from 'services/LocalData/ConditionLoader';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setInput } from 'store/InputSlice';
import useIsAppLocation, { AppLocations } from 'utils/hooks/useIsAppLocation';
import { appendClassCondition } from 'utils/ui/appendClass';
import OnClickProps from 'utils/ui/OnClickProps';

const Shortcuts = () => {
  const intl = useIntl();
  const { accounts } = useMsal();
  const isLoggedIn = accounts.length > 0;
  const isCalculation = useIsAppLocation([AppLocations.calculation]);
  const connected = useAppSelector((state) => state.setting.connected);
  return (
    <Route
      path={['/dialsetting', '/calculation/:id']}
      render={() => {
        return (
          <div className="w-full fixed bottom-0 h-11 bg-primary-600 text-gray-25 sm:hidden">
            <div className="flex flex-grow h-full justify-center items-center">
              {!isLoggedIn && (
                <LoginShortcut
                  disabled={!connected}
                  text={'Login'}
                  icon={<Icon feIcon={Icons.User} feColor={connected ? IconColors.White : IconColors.Gray} feSize={IconSizes.Lg} />}
                />
              )}
              {isLoggedIn && (
                <NewShortcut
                  text={intl.formatMessage({ id: 'header.new.desktop' })}
                  to="/dialsetting"
                  icon={<Icon feIcon={Icons.Plus} feColor={IconColors.White} feSize={IconSizes.Lg} />}
                />
              )}
              {!isCalculation ? (
                <SaveShortcut disabled={!connected} text={'Save'} icon={<ContentSaveSvg className="h-5 w-5" />} />
              ) : (
                <>
                  <EditShortcut
                    text={intl.formatMessage({ id: 'usercalculations.edit.button' })}
                    icon={<Icon feIcon={Icons.Edit} feColor={IconColors.White} feSize={IconSizes.Lg} />}
                  />
                  <DuplicateShortcut
                    text={intl.formatMessage({ id: 'usercalculations.duplicate.button' })}
                    icon={<Icon feIcon={Icons.Duplicate} feColor={IconColors.White} feSize={IconSizes.Lg} />}
                  />
                  <DeleteShortcut
                    text={intl.formatMessage({ id: 'usercalculations.delete.button' })}
                    icon={<Icon feIcon={Icons.Trash} feColor={IconColors.White} feSize={IconSizes.Lg} />}
                  />
                </>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

const LoginShortcut = ({ text, icon, disabled }: { text: string; icon: JSX.Element; disabled: boolean }) => {
  return <Authenticator Anonymous={({ onLogin }: AnonymousProps) => <Shortcut disabled={disabled} text={text} icon={icon} onClick={onLogin} />} />;
};

const NewShortcut = ({ text, icon, to }: { text: string; icon: JSX.Element; to: string }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const onClick = () => {
    dispatch(setInput(getDefaults(getConditions())));
    history.push(to);
  };
  return <Shortcut text={text} icon={icon} onClick={onClick} />;
};

const SaveShortcut = ({ text, icon, disabled }: { text: string; icon: JSX.Element; disabled: boolean }) => {
  return <SaveNotificationAction Icon={({ onClick }: OnClickProps) => <Shortcut disabled={disabled} text={text} icon={icon} onClick={onClick} />} />;
};

const DeleteShortcut = ({ text, icon }: { text: string; icon: JSX.Element }) => {
  const currentNotification = useAppSelector((state) => state.notifications.current);
  if (currentNotification) {
    return <DeleteAction notification={currentNotification} Icon={({ onClick }: OnClickProps) => <Shortcut text={text} icon={icon} onClick={onClick} />} />;
  } else {
    return <></>;
  }
};

const EditShortcut = ({ text, icon }: { text: string; icon: JSX.Element }) => {
  const currentNotification = useAppSelector((state) => state.notifications.current);
  if (currentNotification) {
    return <EditAction notification={currentNotification} Icon={({ onClick }: OnClickProps) => <Shortcut text={text} icon={icon} onClick={onClick} />} />;
  } else {
    return <></>;
  }
};

const DuplicateShortcut = ({ text, icon }: { text: string; icon: JSX.Element }) => {
  const currentNotification = useAppSelector((state) => state.notifications.current);
  if (currentNotification) {
    return <DuplicateAction notification={currentNotification} Icon={({ onClick }: OnClickProps) => <Shortcut text={text} icon={icon} onClick={onClick} />} />;
  } else {
    return <></>;
  }
};

type ShortcutProps = {
  text: string;
  onClick?: () => void;
  to?: string;
  icon: JSX.Element;
  disabled?: boolean;
};

const Shortcut = ({ text, onClick, icon, disabled }: ShortcutProps) => {
  const localOnClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div
      onClick={localOnClick}
      className={appendClassCondition(
        'flex flex-col items-center ml-2.5 flex-grow sm:flex-grow-0 sm:flex-row sm:ml-9 sm:hidden',
        'text-gray-600',
        'text-white',
        disabled
      )}
    >
      {icon}
      <span className={appendClassCondition('text-xs sm:text-base pt-1 sm:ml-3 sm:mb-1.5', 'text-gray-600', 'text-gray-25', disabled)}>{text}</span>
    </div>
  );
};

export default Shortcuts;
