import { Dialog } from '@skf-internal/ui-components-react-legacy';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useAnalyticsLog from 'services/Analytics/useAnalyticsLog';
import { Locale } from 'services/Localization/LocalizationService';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLocale, sortLanguages } from 'store/SettingSlice';

const LanguageDialog = ({ show, onClose }: { show: boolean; onClose: () => void }) => {
  const languages = useAppSelector((state) => state.setting.availableLanguages);
  const currentLanguage = useAppSelector((state) => state.setting.intl.locale);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { logSelectLanguage } = useAnalyticsLog();

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const setLanguage = (locale: Locale) => {
    dispatch(setLocale(locale));
    logSelectLanguage(locale);
    onClose();
  };

  return (
    <Dialog
      feNoPadding
      open={isOpen}
      feTitle={intl.formatMessage({ id: 'navigation.languageSelect' })}
      feOnClose={() => onClose()}
      cssHeight="30rem"
      cssWidth="24rem"
    >
      {[...languages].sort(sortLanguages).map((language, index) => (
        <div key={index}>
          <div
            onClick={() => setLanguage(language.locale)}
            className={currentLanguage === language.locale ? 'border-b cursor-pointer p-4 bg-gray-100' : 'border-b cursor-pointer p-4'}
          >
            <p className="ml-px-60">{language.label} </p>
          </div>
        </div>
      ))}
    </Dialog>
  );
};

export default LanguageDialog;
