import { useEffect, useState } from 'react';

const useIsAppInstalled = () => {
  const [isInstalled, setIsInstalled] = useState<boolean>();

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    } else {
      setIsInstalled(false);
    }
  }, []);

  return isInstalled;
};

export default useIsAppInstalled;
