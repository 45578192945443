import { Icons, Link, Text } from '@skf-internal/ui-components-react-legacy';
import { Notification } from 'domain/Notification';
import { useIntl } from 'react-intl';
import BuyOnlineAction, { BuyOnlineClickProps } from '../Actions/BuyOnlineAction';
import Panel from './Panel';
import ReplenishmentButton from './ReplenishmentButton';

const Replenishment = ({ notification }: { notification: Notification }) => {
  const intl = useIntl();

  return (
    <Panel header={intl.formatMessage({ id: 'usercalculations.replenishment.header' })} icon={Icons.Sync} widen>
      <BuyOnlineAction
        notification={notification}
        Child={({ onClick, shopUrl }: BuyOnlineClickProps) => (
          <>
            <Text>
              {intl.formatMessage({ id: 'usercalculations.replenishment.buyonline.text' }, { device: notification.reminder.reminderMetaData.lubricatorType })}
            </Text>
            <Link
              className="font-normal"
              as="a"
              onClick={(_event, _route) => onClick()}
              target="_blank"
              feIcon={{ feIcon: Icons.OpenInNew, position: 'left' }}
              href={shopUrl}
            >
              {intl.formatMessage({ id: 'lubrication.shoponline' })}
            </Link>
          </>
        )}
      />
      <ReplenishmentButton notification={notification} />
    </Panel>
  );
};

export default Replenishment;
