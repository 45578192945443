import { LubricatorSetting, LubricatorSettingFormatter } from 'domain/Lubricator';
import { ResultWithUnits } from 'features/DialSetting/Result/ResultConversion';
import ResultNumbers from 'features/DialSetting/Result/ResultNumbers';
import SettingsImage from 'features/DialSetting/Result/SettingsImage';
import { useIntl } from 'react-intl';
import formatString from 'utils/strings/formatString';
import PrintHeader from './layout/PrintHeader';
import PrintKeyValue from './layout/PrintKeyValue';
import PrintRow from './layout/PrintRow';

const PrintResultNumber = ({ value, header, unit }: { value: number | string; header: string; unit: string }) => {
  return (
    <>
      <PrintKeyValue headerText={header} value={`${value} ${unit}`} />
    </>
  );
};

const PrintRoundedResult = ({ result }: { result: ResultWithUnits | undefined }) => {
  const intl = useIntl();

  const formatSettingMessage = (setting: LubricatorSetting | undefined): string => {
    if (setting && (setting as LubricatorSettingFormatter).formattingValues) {
      return formatString(
        intl.formatMessage({ id: setting.text }),
        (setting as LubricatorSettingFormatter).formattingValues.map((n) => n.toString())
      );
    } else if (setting && setting.text) {
      return intl.formatMessage({ id: setting.text });
    } else {
      return '-';
    }
  };

  return (
    <div>
      <PrintHeader header="result.header" />
      <PrintRow>
        <div>
          <ResultNumbers result={result} NumberComponent={PrintResultNumber} langKey="desktop" />
          <PrintKeyValue header="Lubricator.setting" value={formatSettingMessage(result?.lubricatorSetting)} />
        </div>
        <SettingsImage className="h-32" />
      </PrintRow>
    </div>
  );
};

export default PrintRoundedResult;
