import { useState } from 'react';
import ReleaseInfo from './ReleaseInfo';
import useReleaseInfo from './useReleaseInfo';

const AutoReleaseInfo = () => {
  const [hasInfoForUser, latestRelease, dismissRelease] = useReleaseInfo();
  const [isDialogOpen, setIsDialogOpen] = useState(hasInfoForUser);

  const onClose = () => {
    dismissRelease(latestRelease);
    setIsDialogOpen(false);
  };

  return <ReleaseInfo showLanguageSelector show={isDialogOpen} onClose={onClose} />;
};

export default AutoReleaseInfo;
