import { Conditions } from 'domain/Conditions';
import { Input } from 'domain/Input';
import { Validation, ValidationError } from 'domain/Result';

export default function validate(input: Input, conditions: Conditions): Validation {
  const dimensionsValidation = validateDimensions(input, conditions);

  return { isValid: dimensionsValidation.isValid, dimensions: dimensionsValidation };
}

export function validateDimensions(input: Input, conditions: Conditions): ValidationError {
  return {
    isValid: input.dimensions.outerDiameter && input.dimensions.innerDiameter ? input.dimensions.outerDiameter > input.dimensions.innerDiameter : true,
    message: conditions.validations.dimensions.message,
    warningMessage: conditions.validations.dimensions.warningMessage
  };
}
