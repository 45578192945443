import { Reminder } from 'domain/Notification';
import { createDateFromMilliseconds, createDateStringFromEpoch } from 'utils/data/dateConversion';

export const createReminder = (reminderData: any): Reminder | null => {
  return {
    ...reminderData,
    installationDate: createDateFromMilliseconds(reminderData.installationDate),
    replenishmentDate: createDateFromMilliseconds(reminderData.replenishmentDate),
    notificationDate: createDateFromMilliseconds(reminderData.notificationDate),
    createdAt: createDateStringFromEpoch(reminderData.createdAt)
  };
};
