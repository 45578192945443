import { Input } from 'domain/Input';
import { ConvertibleValue, ResultWarning, Validation, ValueType, WarningType } from 'domain/Result';
import { Lubricator, LubricatorSetting } from 'domain/Lubricator';
import { ConditionLevel, Warnings } from 'domain/Conditions';
import { createConvertible } from 'services/UnitConversion/UnitConversionService';
import { Grease } from 'domain/Grease';

export default function getWarnings(
  relubricationInterval: number,
  correctedRelubricationInterval: number,
  dialSetting: LubricatorSetting,
  input: Input,
  warnings: Warnings
): Array<ResultWarning> {
  //TfrmMain.DoNotes

  const speedFactor = calculateSpeedFactor(
    input.rotatingOuterRing.value,
    input.speed ?? 1800,
    input.dimensions.outerDiameter ?? 260,
    input.dimensions.innerDiameter ?? 120
  );
  const speedLimit = calculateSpeedLimit(
    input.load.value,
    input.bearingType.loadLimit.low,
    input.bearingType.loadLimit.moderate,
    input.bearingType.loadLimit.high
  );

  let resultWarnings = new Array<ResultWarning>();

  appendGreaseDiscontinuedWarning(resultWarnings, input.grease, input.lubricator, warnings);
  appendAmbientTemperatureWarning(resultWarnings, input, warnings);

  if (!appendBadRelubricationIntervalWarning(resultWarnings, relubricationInterval, warnings) && !isLubricatorTLMP(input.lubricator)) {
    if (!appendCentralizedLubricationWarning(resultWarnings, correctedRelubricationInterval, warnings)) {
      if (!appendHighRecomendedFeedRateWarning(resultWarnings, dialSetting, input.lubricator, warnings)) {
        appendLowRecomendedFeedRateWarning(resultWarnings, dialSetting, input.lubricator, warnings);
      }
    }
  }

  appendCageBearingWarning(resultWarnings, input, speedLimit, warnings);
  appendLubeIntervalWarning(resultWarnings, speedFactor, speedLimit, input, warnings);
  appendRotatingOuterRingWarning(resultWarnings, input, warnings);
  appendShaftOrientationWarning(resultWarnings, input, warnings);
  appendContaminationWarning(resultWarnings, input, warnings);
  appendOilLubeWarning(resultWarnings, input, speedFactor, speedLimit, warnings);

  appendLowSpeedWarning(resultWarnings, input, speedFactor, warnings);

  if (!appendHighFeedrateTLMP(resultWarnings, input, dialSetting, warnings) && !appendLowFeedrateTLMP(resultWarnings, input, dialSetting, warnings)) {
    appendMachineSteeringWarning(resultWarnings, input, warnings);
  }

  appendGreaseUnspecifiedWarning(resultWarnings, input.grease, warnings);

  return resultWarnings;
}

export function getWarningsForValidation(validation: Validation): ResultWarning[] {
  let warnings: ResultWarning[] = [];
  if (!validation.dimensions?.isValid) {
    warnings = [...warnings, createWarning(validation.dimensions.warningMessage, WarningType.validationError)];
  }
  return warnings;
}

export function appendGreaseUnspecifiedWarning(result: ResultWarning[], grease: Grease, warnings: Warnings) {
  if (grease.id === 'Unspecified') {
    appendWarning(result, warnings.greaseUnspecifiedWarning);
  }
}

export function appendGreaseDiscontinuedWarning(result: ResultWarning[], grease: Grease, lubricator: Lubricator, warnings: Warnings) {
  if (grease.id === 'LGWM 2' && isLubricatorTLSD(lubricator)) {
    appendWarning(result, warnings.tlsdLgwm2DicontinuedWarning);
  }
}

export function appendLowFeedrateTLMP(result: Array<ResultWarning>, input: Input, dialSetting: LubricatorSetting, warnings: Warnings): boolean {
  if (isLubricatorTLMP(input.lubricator)) {
    if (dialSetting.lowWarning) {
      appendWarningWithValues(result, warnings.lowFeedrateTLMP, [createConvertible(0.08, ValueType.weight)]);
      return true;
    }
  }
  return false;
}

export function appendHighFeedrateTLMP(result: Array<ResultWarning>, input: Input, dialSetting: LubricatorSetting, warnings: Warnings): boolean {
  if (isLubricatorTLMP(input.lubricator)) {
    if (dialSetting.highWarning) {
      appendWarningWithValues(result, warnings.highFeedrateTLMP, [createConvertible(14.4, ValueType.weight)]);
      return true;
    }
  }
  return false;
}

export function appendMachineSteeringWarning(result: Array<ResultWarning>, input: Input, warnings: Warnings) {
  if (isLubricatorTLMP(input.lubricator)) {
    appendWarning(result, warnings.machineSteering);
  }
}

export function appendAmbientTemperatureWarning(result: Array<ResultWarning>, input: Input, warnings: Warnings): boolean {
  if (isLubricatorLAGD60Or125(input.lubricator)) {
    let hadWarnings = false;
    if (input.ambientTemperature.value < input.lubricator.ambientTemperature.min || input.ambientTemperature.value > input.lubricator.ambientTemperature.max) {
      appendWarningWithValues(result, warnings.ambientTemperature, [
        createConvertible(input.lubricator.ambientTemperature.min, ValueType.temperature),
        createConvertible(input.lubricator.ambientTemperature.max, ValueType.temperature)
      ]); //strNoteAmbientTemp
      hadWarnings = true;
    }

    if (input.ambientTemperature.value < -10) {
      appendWarning(result, warnings.lowAmbientTemperature);
      hadWarnings = true;
    }

    if (input.ambientTemperature.value > 40) {
      appendWarning(result, warnings.highAmbientTemperature);
      hadWarnings = true;
    }

    return hadWarnings;
  }
  return false;
}

export function appendLowSpeedWarning(result: Array<ResultWarning>, input: Input, speedFactor: number, warnings: Warnings): boolean {
  const abf = speedFactor * input.bearingType.bearingFactor;
  if (abf < 20000) {
    let dM = 0;
    if (input.rotatingOuterRing.value) {
      dM = input.dimensions.outerDiameter ?? 260;
    } else {
      dM = ((input.dimensions.outerDiameter ?? 260) + (input.dimensions.innerDiameter ?? 120)) / 2;
    }

    const speed = Math.round(20000 / (input.bearingType.bearingFactor * dM + 1));
    appendWarningWithValues(result, warnings.tooLowSpeed, [speed]); //strTOO_LOW_SPEED_WARN
    appendWarning(result, warnings.lowSpeed); //strCAGE_BEARING_WARN
    return true;
  }
  return false;
}

export function appendCageBearingWarning(result: Array<ResultWarning>, input: Input, speedLimit: number, warnings: Warnings): boolean {
  if (speedLimit === 0 && input.bearingType.fullCompNoCage) {
    appendWarning(result, warnings.cageBearing, WarningType.withoutCage); //strCAGE_BEARING_WARN
    return true;
  }
  return false;
}

export function appendOilLubeWarning(result: Array<ResultWarning>, input: Input, speedFactor: number, speedLimit: number, warnings: Warnings): boolean {
  if (input.bearingType.oilLubeWarning && input.load.value !== ConditionLevel.low) {
    if (speedFactor > speedLimit) {
      appendWarning(result, warnings.oilLube); //strOIL_LUB_WARN
      return true;
    }
  }
  return false;
}

export function appendContaminationWarning(result: Array<ResultWarning>, input: Input, warnings: Warnings): boolean {
  if (input.contamination.value < 1) {
    //Above low
    appendWarning(result, warnings.contamination);
    return true;
  }
  return false;
}

export function appendShaftOrientationWarning(result: Array<ResultWarning>, input: Input, warnings: Warnings): boolean {
  if (input.shaftOrientation.value === 0.5) {
    //Is vertical
    appendWarning(result, warnings.vertShaft);
    return true;
  }
  return false;
}

export function appendRotatingOuterRingWarning(result: Array<ResultWarning>, input: Input, warnings: Warnings): boolean {
  if (input.rotatingOuterRing.value) {
    appendWarning(result, warnings.outerRingRotation);
    return true;
  }
  return false;
}

export function appendLubeIntervalWarning(result: Array<ResultWarning>, speedFactor: number, speedLimit: number, input: Input, warnings: Warnings): boolean {
  if (speedFactor > speedLimit && speedLimit > 0) {
    let vMaxN = 0;
    if (input.rotatingOuterRing.value) {
      vMaxN = speedLimit / (input.dimensions.outerDiameter ?? 260);
    } else {
      vMaxN = speedLimit / (((input.dimensions.outerDiameter ?? 260) + (input.dimensions.innerDiameter ?? 120)) / 2);
    }
    appendWarningWithValues(result, warnings.lubeInterval, [speedFactor, speedLimit, vMaxN], WarningType.lubeInterval);
    return true;
  }
  return false;
}

export function appendLowRecomendedFeedRateWarning(
  result: Array<ResultWarning>,
  dialSetting: LubricatorSetting,
  lubricator: Lubricator,
  warnings: Warnings
): boolean {
  if (dialSetting.lowWarning) {
    let innerWarning: ResultWarning;
    if (isLubricatorLAGD125(lubricator)) {
      innerWarning = createWarningWithValues(warnings.lowFeedRateRecommendation, ['LAGD 60 ml']);
    } else if (isLubricatorTLSD250(lubricator)) {
      innerWarning = createWarningWithValues(warnings.lowFeedRateRecommendation, ['TLSD 125 ml']);
    } else if (isLubricatorTLMR380(lubricator)) {
      innerWarning = createWarningWithValues(warnings.lowFeedRateRecommendation, ['TLMR 120 ml']);
    } else {
      if (isLubricatorTLMR120(lubricator)) {
        innerWarning = createWarningWithValues(warnings.lowFeedRateOtherRecommendation, [lubricator.displayName, '24']);
      } else {
        innerWarning = createWarningWithValues(warnings.lowFeedRateOtherRecommendation, [lubricator.displayName, '12']);
      }
    }
    appendWarningWithInner(result, warnings.lowFeedRateMessage, [getLowFeedRateWeight(lubricator)], innerWarning, WarningType.lowFeedRate); //noteLowReqFeed
    return true;
  }
  return false;
}

function getLowFeedRateWeight(lubricator: Lubricator): ConvertibleValue {
  let weight = 0;
  if (isLubricatorLAGD125(lubricator)) {
    weight = 0.3;
  } else if (isLubricatorTLSD125(lubricator)) {
    weight = 0.33;
  } else if (isLubricatorTLMR120(lubricator)) {
    weight = 0.16;
  } else if (isLubricatorLAGD60(lubricator)) {
    weight = 0.3 / 2;
  } else if (isLubricatorTLSD250(lubricator)) {
    weight = 0.3 * 2;
  } else if (isLubricatorTLMR380(lubricator)) {
    weight = 0.5; // 0.16*380/120
  }
  return createConvertible(weight, ValueType.weight);
}

export function appendHighRecomendedFeedRateWarning(
  result: Array<ResultWarning>,
  dialSetting: LubricatorSetting,
  lubricator: Lubricator,
  warnings: Warnings
): boolean {
  if (dialSetting.highWarning) {
    let innerWarning: ResultWarning;
    if (isLubricatorLAGD60(lubricator)) {
      innerWarning = createWarningWithValues(warnings.highFeedRateRecommendation, ['LAGD 125']);
    } else if (isLubricatorTLSD125(lubricator)) {
      innerWarning = createWarningWithValues(warnings.highFeedRateRecommendation, ['TLSD 250']);
    } else if (isLubricatorTLMR120(lubricator)) {
      innerWarning = createWarningWithValues(warnings.highFeedRateRecommendation, ['TLMR 380ml']);
    } else {
      innerWarning = createWarning(warnings.highFeedRateOtherRecommendation);
    }
    appendWarningWithInner(result, warnings.highFeedRateMessage, [getHighFeedRateWeight(lubricator)], innerWarning, WarningType.highFeedRate); //noteHighReqFeed
    return true;
  }
  return false;
}

function getHighFeedRateWeight(lubricator: Lubricator): ConvertibleValue {
  let weight = 3.88;
  if (isLubricatorLAGD60(lubricator)) {
    weight = weight / 2;
  } else if (isLubricatorTLSD250(lubricator)) {
    weight = weight * 2;
  } else if (isLubricatorTLMR380(lubricator)) {
    weight = 12.06;
  }
  return createConvertible(weight, ValueType.weight);
}

export function appendBadRelubricationIntervalWarning(result: Array<ResultWarning>, relubricationInterval: number, warnings: Warnings): boolean {
  if (relubricationInterval < 0) {
    appendWarning(result, warnings.badRelubricationInterval); //noteBadRelubInterval
    return true;
  }
  return false;
}

export function appendCentralizedLubricationWarning(result: Array<ResultWarning>, correctedRelubricationInterval: number, warnings: Warnings): boolean {
  if (correctedRelubricationInterval <= 100) {
    appendWarning(result, warnings.centralizedLubrication); //noteCentralizedLub
    return true;
  }
  return false;
}

export function calculateSpeedFactor(rotatingOuterRing: boolean, speed: number, outerDiameter: number, innerDiameter: number): number {
  if (rotatingOuterRing) {
    return speed * outerDiameter;
  } else {
    return ((outerDiameter + innerDiameter) * speed) / 2;
  }
}

export function calculateSpeedLimit(load: ConditionLevel, limitLow: number, limitModerate: number, limitHigh: number): number {
  switch (load) {
    case ConditionLevel.low:
      return limitLow;
    case ConditionLevel.moderate:
      return limitModerate;
    case ConditionLevel.high:
      return limitHigh;
    default:
      return 0;
  }
}

export function isLubricatorLAGD60Or125(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'lagd 60' || lubricator.id.toLocaleLowerCase() === 'lagd 125';
}

export function isLubricatorLAGD60(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'lagd 60';
}

export function isLubricatorLAGD125(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'lagd 125';
}

export function isLubricatorTLSD(lubricator: Lubricator) {
  return isLubricatorTLSD125(lubricator) || isLubricatorTLSD250(lubricator);
}
export function isLubricatorTLSD125(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlsd 125';
}

export function isLubricatorTLSD250(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlsd 250';
}

export function isLubricatorTLMR120(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlmr 120ml';
}

export function isLubricatorTLMR380(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlmr 380ml';
}

export function isLubricatorTLMP(lubricator: Lubricator): boolean {
  return lubricator.id.toLocaleLowerCase() === 'tlmp';
}

export function appendWarning(result: Array<ResultWarning>, key: string, type: WarningType = WarningType.none) {
  result.push(createWarning(key, type));
}

export function createWarning(key: string, type: WarningType = WarningType.none): ResultWarning {
  return {
    key: key,
    type: type
  } as ResultWarning;
}

type FormattingValues = Array<ConvertibleValue | number | string>;

export function appendWarningWithValues(result: Array<ResultWarning>, key: string, formattingValues: FormattingValues, type: WarningType = WarningType.none) {
  result.push(createWarningWithValues(key, formattingValues, type));
}

export function createWarningWithValues(key: string, formattingValues: FormattingValues, type: WarningType = WarningType.none): ResultWarning {
  return {
    key: key,
    type: type,
    formattingValues: formattingValues
  } as ResultWarning;
}

export function appendWarningWithInner(
  result: Array<ResultWarning>,
  key: string,
  formattingValues: FormattingValues,
  innerWarning: ResultWarning,
  type: WarningType = WarningType.none
) {
  result.push({
    key: key,
    type: type,
    formattingValues: formattingValues,
    innerWarning: innerWarning
  } as ResultWarning);
}
