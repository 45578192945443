import { Conditions, Unit } from 'domain/Conditions';
import conditions from 'assets/data/conditions.json';
import { getBearingTypes } from './BearingTypeLoader';
import { BearingType } from 'domain/BearingType';

import { getLubricators } from './LubricatorLoader';
import { getGreases } from './GreaseLoader';

export function getConditions(): Conditions {
  const loadedConditions = { ...conditions } as unknown as Conditions;
  loadedConditions.defaultBearing = getBearingTypes()[0] as BearingType;
  loadedConditions.defaultLubricator = getLubricators()[0];
  loadedConditions.defaultGrease = getGreases()[0];
  loadedConditions.unit = Unit.metric;
  return loadedConditions;
}
