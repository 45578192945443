import { Tabs, TabsItem } from '@skf-internal/ui-components-react-legacy';
import { ReactNode } from 'react';

type BorderBoxProps = {
  header?: string;
  children?: ReactNode;
  items?: TabsItem[];
  style?: React.CSSProperties;
  className?: string;
};

type BorderBoxChildrenContainerProps = {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

const BorderBoxChildrenContainer = ({ children, style, className }: BorderBoxChildrenContainerProps) => {
  return (
    <div className={'p-2 sm:p-4' + (className ? className : '')} style={style}>
      {children}
    </div>
  );
};

const BorderBox = ({ header, children, style, className, items }: BorderBoxProps) => {
  //The styles tabNoPadding and tabLeft can be found in index.css
  let tabsClassname = 'tabNoPadding m-4 sm:m-2 sm:mb-0';
  if (!items) {
    tabsClassname = 'tabLeft ' + tabsClassname;
    items = [
      {
        children: (
          <BorderBoxChildrenContainer className={className} style={style}>
            {children!}
          </BorderBoxChildrenContainer>
        ),
        id: 'bearingSelection',
        label: header!
      }
    ];
  } else {
    items = items.map((item) => ({ ...item, children: <BorderBoxChildrenContainer>{item.children}</BorderBoxChildrenContainer> }));
  }

  return <Tabs feType="expanded" className={tabsClassname} feItems={items} />;
};

export default BorderBox;
