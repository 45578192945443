import { configureStore, Store } from '@reduxjs/toolkit';
import { Unit } from 'domain/Conditions';
import thunk from 'redux-thunk';
import { getDefaults } from 'services/Calculation/CalculationService';
import { getBearingTypes } from 'services/LocalData/BearingTypeLoader';
import { getConditions } from 'services/LocalData/ConditionLoader';
import { getGreases } from 'services/LocalData/GreaseLoader';
import { getLubricators } from 'services/LocalData/LubricatorLoader';
import { getIntl, Locale } from 'services/Localization/LocalizationService';
import dataReducer, { setBearingTypes, setConditions, setGreases, setLubricators, setUnit } from 'store/DataSlice';
import inputReducer, { setInput } from 'store/InputSlice';
import resultSlice from 'store/ResultSlice';
import settingSlice, { setIntl } from 'store/SettingSlice';
import accountSlice from 'store/AccountSlice';
import notificationSlice from 'store/NotificationSlice';
import offlineMiddleware from './NotificationSlice/loadCalculationMiddleware';
import accountLanguageMiddleware from './SettingSlice/accountLanguageMiddleware';
const store = configureStore({
  reducer: {
    data: dataReducer,
    input: inputReducer,
    result: resultSlice,
    setting: settingSlice,
    account: accountSlice,
    notifications: notificationSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(thunk).prepend(offlineMiddleware.middleware).prepend(accountLanguageMiddleware.middleware)
});

export const setDefaults = (storeToUpdate: Store, locale: Locale, unit: Unit) => {
  const intl = getIntl(locale);

  store.dispatch(setUnit(unit));
  storeToUpdate.dispatch(setBearingTypes(getBearingTypes()));
  storeToUpdate.dispatch(setGreases(getGreases()));
  storeToUpdate.dispatch(setLubricators(getLubricators()));
  storeToUpdate.dispatch(setConditions(getConditions()));
  storeToUpdate.dispatch(setIntl(intl));
  storeToUpdate.dispatch(setInput(getDefaults(getConditions())));
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
