import { Icons } from '@skf-internal/ui-components-react-legacy';
import SizeableIcon from 'components/layouts/SizeableIcon';
import { Notification } from 'domain/Notification';
import { useIntl } from 'react-intl';
import OnClickProps from 'utils/ui/OnClickProps';
import EditAction from '../Actions/EditAction';

const EditButton = ({ notification }: { notification: Notification }) => {
  const intl = useIntl();
  return (
    <EditAction
      notification={notification}
      Icon={({ onClick }: OnClickProps) => (
        <SizeableIcon onClick={onClick} title={intl.formatMessage({ id: 'usercalculations.edit.tooltip' })} icon={Icons.Edit} />
      )}
    />
  );
};

export default EditButton;
