import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useAnchor = (id: string) => {
  const callbackRef = useRef<HTMLDivElement | null>(null); // initialize mutable callback ref
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const hashId = hash.replace('#', '');
        if (hashId === id) {
          const element = callbackRef.current;
          if (element) {
            const yOffset = -144;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
            //element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      }, 0);
    }
  }, [pathname, hash, key, callbackRef, id]);

  return callbackRef;
};

export default useAnchor;
