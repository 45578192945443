import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { OfflineSyncStatus } from 'services/Notifications/NotificationSyncService';
import { getSyncService } from 'services/Notifications/RepositoryFactory';
import { useAppDispatch } from 'store/hooks';
import { reloadNotifications } from 'store/NotificationSlice';
import { setSyncStatus } from 'store/SettingSlice';
import useDataSync, { SyncEventType } from 'features/OfflineSync/useDataSync';

const service = getSyncService();

const useNotificationSync = (connected: boolean, isAuthenticated: boolean) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  useDataSync({
    id: 'notifications',
    shouldInitiate: connected && isAuthenticated,
    shouldSync: () => service.hasEvents(),
    eventHandler: (eventType: SyncEventType) => {
      switch (eventType) {
        case SyncEventType.doNotSync:
          dispatch(setSyncStatus(OfflineSyncStatus.Syncing));
          break;
        case SyncEventType.nothingToSync:
          dispatch(setSyncStatus(OfflineSyncStatus.NothingToSync));
          break;
        case SyncEventType.doSync:
          sync();
          break;
        case SyncEventType.syncDone:
          syncDone();
          break;
        default:
          break;
      }
    }
  });

  const syncDone = useCallback(() => {
    if (window.location.pathname.includes('/offline-')) {
      history.push('/dialsetting');
    }
    if (window.location.pathname.includes('notifications')) {
      window.location.reload();
    }
  }, [history]);

  const meSyncDone = useCallback(() => {
    dispatch(
      reloadNotifications(() => {
        dispatch(setSyncStatus(OfflineSyncStatus.Done));
        if (process.env.NODE_ENV === 'development') {
          syncDone();
        } else {
          navigator.serviceWorker.controller?.postMessage({ type: 'SYNC_DONE' });
          syncDone();
        }
      })
    );
  }, [dispatch, syncDone]);

  const sync = useCallback(() => {
    dispatch(setSyncStatus(OfflineSyncStatus.MeSyncing));
    service
      .syncAll()
      .then(() => {
        meSyncDone();
      })
      .catch((err) => {
        console.log(err);
        dispatch(setSyncStatus(OfflineSyncStatus.Error));
      });
  }, [meSyncDone, dispatch]);
};

export default useNotificationSync;
