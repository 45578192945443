import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { BearingBase, BearingType, BearingTypeCollection } from 'domain/BearingType';
import { setBearingType, setManualBearingSelection, setReplenishment } from 'store/InputSlice';
import treeSearch, { getTreeAsArray } from 'utils/data/treeSearch';
import { Select } from '@skf-internal/ui-components-react-legacy';
import { useIntl } from 'react-intl';
import { getDefaultBearingType, isBearingCollection } from 'services/LocalData/BearingTypeLoader';
import BearingCollection from './BearingCollection';
import BearingAdditionalOptions from './BearingAdditionalOptions';

const BearingSelection = () => {
  const [bearingTypeOptions, setBearingTypeOptions] = useState<Array<{ label: string; value: string }>>([]);
  const [selectedBearingType, setSelectedBearingType] = useState<BearingBase | null>(null);
  const isDisabled = useAppSelector((state) => state.input.isDisabled);

  const intl = useIntl();

  const bearingTypes = useAppSelector((state) => state.data.bearingTypes);
  const currentBearingType = useAppSelector((state) => state.input.bearingType);
  const dispatch = useAppDispatch();

  const defaultReplenishmentValue = {
    value: 0.005,
    label: 'conditions.replenishment.side.label',
    description: 'conditions.replenishment.side.description'
  };

  useEffect(() => {
    setBearingTypeOptions(
      bearingTypes.map((bt) => ({
        label: intl.formatMessage({ id: bt.name }),
        value: bt.id
      }))
    );
  }, [bearingTypes, intl]);

  useEffect(() => {
    if (currentBearingType) {
      const result = treeSearch<BearingBase>(
        bearingTypes,
        (b) => b.id === currentBearingType.id,
        (b) => (isBearingCollection(b) ? (b as BearingTypeCollection).subTypes : [])
      );
      if (result !== null) {
        const items = getTreeAsArray<BearingBase>(result);
        let first = items[0];
        setSelectedBearingType(first);
        return;
      }
    }
    setSelectedBearingType(null);
  }, [currentBearingType, bearingTypes]);

  const bearingTypeChanged = (value: any) => {
    const selectedType = bearingTypes.find((bt) => bt.id === value);
    if (selectedType) {
      setSelectedBearingType(selectedType);
      setInput(selectedType);
      dispatch(setManualBearingSelection(true));
      dispatch(setReplenishment(defaultReplenishmentValue));
    }
  };

  const setInput = (bearingType: BearingBase) => {
    if (!isBearingCollection(bearingType)) {
      dispatch(setBearingType(bearingType as BearingType));
    } else {
      const defaultBearingType = getDefaultBearingType(bearingType as BearingTypeCollection);
      if (defaultBearingType) {
        dispatch(setBearingType(defaultBearingType));
      }
    }
  };

  return selectedBearingType !== null ? (
    <div>
      <Select
        id="bearingSelectionFirst"
        feLabel={intl.formatMessage({ id: 'bearingSelection.firstSelect.label' })}
        onChange={bearingTypeChanged}
        value={selectedBearingType?.id ?? ''}
        feItems={bearingTypeOptions}
        multiple={false}
        disabled={isDisabled}
      />
      {isBearingCollection(selectedBearingType) ? (
        <BearingCollection
          disabled={isDisabled}
          currentBearingType={currentBearingType}
          collection={selectedBearingType as BearingTypeCollection}
          setInput={setInput}
        />
      ) : null}
      <BearingAdditionalOptions options={selectedBearingType.additionalOptions} />
    </div>
  ) : (
    <></>
  );
};

export default BearingSelection;
