import { Icons, Link } from '@skf-internal/ui-components-react-legacy';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store/hooks';
import { useIntl } from 'react-intl';
import useBuyOnline from 'utils/hooks/useBuyOnline';

const LubricatorShop = () => {
  const lubricatorId = useAppSelector((state) => state.input.lubricator.id, shallowEqual);
  const intl = useIntl();
  const [shopUrl, onShopClick] = useBuyOnline(lubricatorId);

  return shopUrl ? (
    <div className="flex w-full justify-center">
      <Link as="a" onClick={(_event, _route) => onShopClick()} target="_blank" feIcon={{ feIcon: Icons.OpenInNew, position: 'left' }} href={shopUrl}>
        {intl.formatMessage({ id: 'lubrication.shoponline' })}
      </Link>
    </div>
  ) : null;
};

export default LubricatorShop;
