import { GreaseImage, Lubricator } from 'domain/Lubricator';
import { Grease } from 'domain/Grease';
import lubricators from 'assets/data/lubricators.json';
import { getGreases } from './GreaseLoader';

export function getLubricators(): Array<Lubricator> {
  return getLubricatorsFromJson(lubricators, getGreases());
}

export function getLubricatorsFromJson(lubricators: Array<any>, greases: Array<Grease>): Array<Lubricator> {
  return lubricators.map((l) => getLubricatorObject(l, greases));
}

export function getLubricatorObject(lubricator: any, greases: Array<Grease>): Lubricator {
  return {
    ...lubricator,
    compatibleGreases: getAvailableGreases(lubricator.compatibleGreases, greases),
    greaseImages: getGreaseImages(lubricator.greaseImages)
  } as unknown as Lubricator;
}
export function getAvailableGreases(compatibleGreases: Array<string>, greases: Array<Grease>): Array<Grease> {
  return compatibleGreases.map((g) => {
    const foundGrease = greases.find((grease) => grease.id === g);
    if (foundGrease) {
      return foundGrease;
    } else {
      throw new Error(`Grease ${g} doesn't exist in greases.json file`);
    }
  });
}
export function getGreaseImages(greaseImages: any): Array<GreaseImage> {
  if (greaseImages) {
    return Object.keys(greaseImages).map((e) => ({ name: e, image: greaseImages[e] } as GreaseImage));
  } else {
    return [] as Array<GreaseImage>;
  }
}
