import { Button } from '@skf-internal/ui-components-react-legacy';
import ContentSaveSvg from 'components/svgs/ContentSaveSvg';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store/hooks';
import OnClickProps from 'utils/ui/OnClickProps';
import SaveNotificationAction from './SaveNotificationAction';

const SaveNotificationButton = ({ isMobile }: { isMobile?: boolean }) => {
  const isValidResult = useAppSelector((state) => !state.result.result?.hideResult);
  const intl = useIntl();

  const renderDesktop = (onClick: () => void) => {
    return (
      <div className="flex justify-end mt-4">
        <Button
          disabled={!isValidResult}
          onClick={onClick}
          className={!isValidResult ? 'cursor-not-allowed' : ''}
          title={intl.formatMessage({ id: 'usercalculations.save.buttontitle' })}
        >
          {intl.formatMessage({ id: 'usercalculations.save.button' })}
        </Button>
      </div>
    );
  };

  const renderMobile = (onClick: () => void) => {
    return (
      <div
        onClick={() => (isValidResult ? onClick() : null)}
        className={'m-2 ml-0 rounded bg-white text-sm flex ' + (!isValidResult ? 'text-primary-300 cursor-not-allowed' : 'text-primary-600 cursor-pointer')}
      >
        <ContentSaveSvg className="w-6 mx-2 mr-1" />
        <p className="mr-2 my-auto">{intl.formatMessage({ id: 'usercalculations.add.savebutton' })}</p>
      </div>
    );
  };

  return <SaveNotificationAction Icon={({ onClick }: OnClickProps) => (isMobile ? renderMobile(onClick) : renderDesktop(onClick))} />;
};

export default SaveNotificationButton;
