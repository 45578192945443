import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loginRequest } from 'services/Authentication/authConfig';

const useAuthenticationRequest = (fromContainer?: boolean) => {
  const { instance } = useMsal();
  const history = useHistory();
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload &&
        (event.payload as AuthenticationResult).account
      ) {
        if ((event.payload as Record<string, string>)['state']) {
          history.push((event.payload as Record<string, string>)['state']);
        }
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [history, instance]);

  return {
    ...loginRequest,
    redirectUri: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`,
    state: fromContainer ? window.location.pathname : ''
  };
};

export default useAuthenticationRequest;
