import { ComponentType } from 'react';
import OnClickProps from 'utils/ui/OnClickProps';
import { Notification } from 'domain/Notification';
import useBuyOnline from 'utils/hooks/useBuyOnline';
import { getDateFromString } from 'services/Notifications/dateCalculations';
import { differenceInDays } from 'date-fns';

export type BuyOnlineClickProps = {
  shopUrl: string;
} & OnClickProps;

const shouldShowBuyOnline = (notification: Notification): boolean => {
  //when the notification was sent out. If the user does not have any notifications added add the button 7 days before.
  const now = new Date();
  if (notification.reminder.hasReminder && notification.reminder.notificationDate) {
    const notificationDate = getDateFromString(notification.reminder.notificationDate);
    return differenceInDays(notificationDate.getTime(), now.getTime()) <= 0;
  } else {
    const replenishment = getDateFromString(notification.reminder.replenishmentDate);
    return differenceInDays(replenishment.getTime(), now.getTime()) < 8;
  }
};

const BuyOnlineAction = ({ notification, Child }: { notification: Notification; Child: ComponentType<BuyOnlineClickProps> }) => {
  const shouldShow = shouldShowBuyOnline(notification);
  const [shopUrl, shopClick] = useBuyOnline(notification.reminder.reminderMetaData.lubricatorType);

  return shouldShow && shopUrl ? <Child shopUrl={shopUrl} onClick={() => shopClick()} /> : <></>;
};

export default BuyOnlineAction;
